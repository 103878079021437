import {
  Work,
  WorkAction as Action,
  WorkActionTypes as ActionTypes,
  WorkHistory,
  WorksItemData,
  WorksList,
  WorksListInfo,
  WorkInfo,
  WorkHistoryDisplayParams,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const refresh2 = (): Action => ({ type: ActionTypes.REFRESH2 });

export const refresh3 = (): Action => ({ type: ActionTypes.REFRESH3 });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsLoading2 = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING2,
  payload: isLoading,
});

export const setIsLoading3 = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING3,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setWorks = (works: WorksList[]): Action => ({
  type: ActionTypes.SET_WORKS,
  payload: works,
});

export const setWorksListInfo = (info: WorksListInfo | null): Action => ({
  type: ActionTypes.SET_WORKS_LIST_INFO,
  payload: info,
});

export const setWorksItemData = (data: WorksItemData | null): Action => ({
  type: ActionTypes.SET_WORKS_ITEM_DATA,
  payload: data,
});

export const setWork = (work: Work | null): Action => ({
  type: ActionTypes.SET_WORK,
  payload: work,
});

export const setWorkHistory = (workHistory: WorkHistory[]): Action => ({
  type: ActionTypes.SET_WORK_HISTORY,
  payload: workHistory,
});

/**
 * @summary ページャ情報を保持
 * @description 案件 - 対応履歴で使用する
 * @param {WorkHistoryDisplayParams} workHistoryDisplayParams
 * @returns {Action}
 */
export const setWorkHistoryDisplayParams = (
  workHistoryDisplayParams: WorkHistoryDisplayParams,
): Action => ({
  type: ActionTypes.SET_WORK_HISTORY_DISPLAY_PARAMS,
  payload: workHistoryDisplayParams,
});

/** 不具合連絡事項取得 */
export const setWorkInfo = (workInfo: WorkInfo | null): Action => ({
  type: ActionTypes.SET_WORK_INFO,
  payload: workInfo,
});
