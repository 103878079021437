import axios from '@providers/axiosProvider';
import axios_ from 'axios';
import * as actions from './actions';

import {
  APIGetInspectionsResponse,
  APIGetInspectionItemResponse,
  APIStartInspectionResponse,
  APISaveInspectionRequest,
  InspectionsList,
  InspectionResultData,
  Photo,
  APIExportInspectionReportResponse,
  APIGetInspectionsRequestParams,
  InspectionsListInfo,
  FileList,
  APIGetInspectionGuestRequest,
  APIGetInspectionChecklistsResponse,
  InspectionBasic,
  APIGetInspectionBasicResponse,
  APIGetInspectionInfoResponse,
  InspectionInfoData,
  APIInspectionsCommentsResponse,
  InspectionCommentsData,
  InspectionReportSubmitData,
  APICreateInspectionReport,
  APIApproveInspectionRequest,
  InspectionResponseItem,
  APIInspectionCompleteRequest,
  APIInspectionItem,
  APIInspectionBasic,
} from './types';

import { InspectionChecklistsList } from '../inspectionChecklist/types';

import { LIST_SIZE_PER_PAGE, LIST_SIZE_PER_PAGE_LARGE } from '@utils/constants';

/**
 * @description 以下の機能で利用
 * pages/inspection/list
 */
export const getInspectionsAsync =
  (propertyId: number | null, params: APIGetInspectionsRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetInspectionsRequestParams = {
      ...params,
      property_id: propertyId,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetInspectionsResponse>(`/inspections`, {
        params: reqParams,
      })
      .then((response) => {
        //  inspection_result プロパティの内容を新しい変数 resInspections に代入
        const { inspection_result: resInspections, display_params: info } = response.data.result;
        // 定期業務一覧（旧点検結果一覧）画面のテーブル部に表示
        // resInspections 配列内の各オブジェクトを
        // 新しい形式（InspectionsList 型）のオブジェクトinspectionsに変換し、それらを新しい配列 inspections に格納
        const inspections: InspectionsList[] = resInspections.map(
          // InspectionsList 型のオブジェクトを返すことを意味
          (inspection): InspectionsList => ({
            propertyId: inspection.property_id,
            propertyName: inspection.property_name,
            inspectionResultId: inspection.inspection_result_id,
            inspectionChecklistId: inspection.inspection_checklist_id,
            inspectionChecklistTitle: inspection.inspection_title,
            inspectionTypeName: inspection.inspection_type_name,
            inspectionStatusName: inspection.inspection_status_name,
            checkUserId: inspection.check_user_id,
            checkUserName: inspection.check_user_name,
            checkDate: inspection.check_date,
            scheduledDate: inspection.scheduled_date,
            // 以下追加
            // 計画開始日時
            startDatetime: inspection.start_datetime,
            // 最終更新日
            updateDatetime: inspection.update_datetime,
            // 計画名
            contractTitle: inspection.contract_title,
            // コメント
            latestComment: inspection.latest_comment,
            // 担当者(監督者） 複数列ある
            supervisors: inspection.supervisors,
            // 作業者 複数列ある
            workers: inspection.workers,
            // 承認者
            approvedUserName: inspection.approval_user_name,
          }),
        );

        const inspectionsListInfo: InspectionsListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setInspections(inspections));
        dispatch(actions.setInspectionsListInfo(inspectionsListInfo));
      })
      .catch(() => {
        dispatch(actions.setInspections([]));
        dispatch(actions.setInspectionsListInfo(null));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 *
 * @summary 承認一覧画面 property/{propertyId}/inspection/approve
 *          はv1.4で査収一覧画面に類似機能を用意するので、当画面は定期業務からは呼ばれなくなり、廃止となった。
 */
export const getInspectionsApproveAsync = (propertyId: number | null) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  const reqParams: APIGetInspectionsRequestParams = {
    property_id: propertyId,
    page: 1,
    sort: 3,
    status: 3,
    size_per_page: LIST_SIZE_PER_PAGE_LARGE,
    // こちらのAPIは型情報を 定期業務一覧取得(全体）と同じものを利用しているので、一旦固定 とする
    show_completed: false,
  };

  await axios
    .get<APIGetInspectionsResponse>(`/inspections`, {
      params: reqParams,
    })
    .then((response) => {
      const { inspection_result: resInspections } = response.data.result;

      const inspections: InspectionsList[] = resInspections.map(
        (inspection): InspectionsList => ({
          propertyId: inspection.property_id,
          propertyName: inspection.property_name,
          inspectionResultId: inspection.inspection_result_id,
          inspectionChecklistId: inspection.inspection_checklist_id,
          inspectionChecklistTitle: inspection.inspection_title,
          inspectionTypeName: inspection.inspection_type_name,
          inspectionStatusName: inspection.inspection_status_name,
          checkUserId: inspection.check_user_id,
          checkUserName: inspection.check_user_name,
          checkDate: inspection.check_date,
          scheduledDate: inspection.scheduled_date,

          // 定期業務一覧と同じ型を利用しているので、設定追加 undefined値
          // 計画開始日時
          startDatetime: inspection.start_datetime,
          // 最終更新日
          updateDatetime: inspection.update_datetime,
          // 計画名
          contractTitle: inspection.contract_title,
          // コメント
          latestComment: inspection.latest_comment,
          // 担当者(監督者） 複数列ある
          supervisors: inspection.supervisors,
          // 作業者 複数列ある
          workers: inspection.workers,
          // 承認者
          approvedUserName: inspection.approval_user_name,
        }),
      );

      dispatch(actions.setInspections(inspections));
    })
    .catch(() => {
      dispatch(actions.setInspections([]));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary 名前はgetInspectionだが、中身はinspectionズを読んでいる
 * @param inspectionId
 * @returns 定期業務の基本情報を返す。
 */
export const getInspectionBasicAsync = (inspectionId: number) => async (dispatch: any) => {
  console.log('export const getInspectionBasicAsync', '');
  dispatch(actions.setIsLoadingTab(true));

  await axios
    // .get<APIGetInspectionResponse>(`/inspections/${inspectionId}`)
    // TODO：Inspectionの型を修正する
    .get<APIGetInspectionBasicResponse>(`/inspections/${inspectionId}`)
    .then(async (response) => {
      const v = response.data.result;
      // const data: Inspection = {
      const data: InspectionBasic = {
        statusId: v.status_id,
        statusName: v.status_name,
        inspectionResultId: v.inspection_result_id,
        propertyId: v.property_id,
        propertyName: v.property_name,
        contractId: v.contract_id,
        contractTitle: v.contract_title,
        contractTypeId: v.contract_type_id,
        contractTypeName: v.contract_type_name,
        inspectionTypeId: v.inspection_type_id,
        inspectionTypeName: v.inspection_type_name,
        inspectionDivisionId: v.inspection_division_id,
        inspectionDivisionName: v.inspection_division_name,
        startDate: v.schedule_start_date,
        startTime: v.schedule_start_time,
        endDate: v.schedule_end_date,
        endTime: v.schedule_end_time,
        supervisors: v.supervisors,
        workers: v.workers,
        companies: v.companies,
        adjustmentTargetsBasic: v.adjustment_targets,
        inspectionChecklistId: v.inspection_checklist_id,
        inspectionChecklistTitle: v.inspection_checklist_title,
        hasFormat: v.has_format,
        message: v.message,
      };
      // dispatchで登録して、画面側selectorで取得する
      dispatch(actions.setInspectionBasic(data));
    })
    .catch(() => {
      dispatch(actions.setTargetInspection(null));
      dispatch(actions.setPrevInspection(null));
    });

  dispatch(actions.setIsLoadingTab(false));
};

/**
 * @summary 作業結果を返す。名前はgetInspectionだが、中身はinspectionズを呼んでいる
 * @param inspectionId
 * @returns 作業結果タブデータ
 */
export const getInspectionAsync = (inspectionId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoadingTab(true));
  await axios
    // .get<APIGetInspectionResponse>(`/inspections/${inspectionId}`) ←もともとのAPI名
    .get<APIGetInspectionItemResponse>(`/inspections/${inspectionId}/results`)
    .then(async (response) => {
      const v = response.data.result;

      const data: InspectionResponseItem = {
        inspectionResultId: v.inspection_result_id,
        inspectionChecklistTitle: v.inspection_title,
        inspectionChecklistId: v.inspection_checklist_id,
        description: v.description,
        scheduledDate: v.schedule_start_date,
        inspectionType: v.inspection_type,
        inspectionTypeName: v.inspection_type_name,
        status: v.status,
        inspectionStatusName: v.status_name,
        checkDate: v.check_date,
        propertyId: v.property_id,
        propertyName: v.property_name,
        checkUserId: v.check_user_id,
        checkUserName: v.check_user_name,
        approvalDate: v.approval_datetime,
        approvedUserId: v.approval_user_id,
        // 承認者
        approvedUserName: v.approval_user_name,
        updateUserId: v.update_user_id,
        updateUserName: v.update_user_name,
        // 最終更新日
        updateDatetime: v.update_datetime,
        isSubmitted: v.is_submitted,
        remarks: v.remarks,
        layout: v.layout,
        data: v.data,
        inspection_result_list: v.inspection_result_list,
        reports: v.reports.map((r) => ({
          fileName: r.file_name,
          fileUrl: r.file_url ?? '',
          fileSize: r.file_size ?? 0,
        })),
      };

      dispatch(actions.setTargetInspection(data));

      if (v.inspection_result_list.length > 0) {
        const first = v.inspection_result_list[0];
        await axios
          .get<APIGetInspectionItemResponse>(`/inspections/${first.inspection_result_id}/results`)
          .then((response) => {
            const w = response.data.result;
            const data: InspectionResponseItem = {
              inspectionResultId: first.inspection_result_id,
              inspectionChecklistTitle: w.inspection_title,
              inspectionChecklistId: w.inspection_checklist_id,
              description: w.description,
              scheduledDate: w.schedule_start_date,
              inspectionType: w.inspection_type,
              inspectionTypeName: w.inspection_type_name,
              status: w.status,
              inspectionStatusName: w.status_name,
              checkDate: w.check_date,
              propertyId: w.property_id,
              propertyName: w.property_name,
              checkUserId: w.check_user_id,
              checkUserName: w.check_user_name,
              approvalDate: w.approval_datetime,
              approvedUserId: w.approval_user_id,
              approvedUserName: w.approval_user_name,
              updateUserId: w.update_user_id,
              updateUserName: w.update_user_name,
              updateDatetime: w.update_datetime,
              isSubmitted: w.is_submitted,
              remarks: w.remarks,
              layout: w.layout,
              data: w.data,
              inspection_result_list: [],
              reports: [],
            };
            dispatch(actions.setPrevInspection(data));
          })
          .catch(() => {
            dispatch(actions.setPrevInspection(null));
          });
      } else {
        dispatch(actions.setPrevInspection(null));
      }
    })
    .catch(() => {
      dispatch(actions.setTargetInspection(null));
      dispatch(actions.setPrevInspection(null));
    });

  dispatch(actions.setIsLoadingTab(false));
};

export const getPrevInspectionAsync = (inspectionId: number) => async (dispatch: any) => {
  await axios
    // .get<APIGetInspectionResponse>(`/inspections/${inspectionId}`) ←もともとのAPI名
    .get<APIGetInspectionItemResponse>(`/inspections/${inspectionId}/results`)
    .then((response) => {
      const w = response.data.result;
      const data: InspectionResponseItem = {
        inspectionResultId: inspectionId,
        inspectionChecklistTitle: w.inspection_title,
        inspectionChecklistId: w.inspection_checklist_id,
        description: w.description,
        scheduledDate: w.schedule_start_date,
        inspectionType: w.inspection_type,
        inspectionTypeName: w.inspection_type_name,
        status: w.status,
        inspectionStatusName: w.status_name,
        checkDate: w.check_date,
        propertyId: w.property_id,
        propertyName: w.property_name,
        checkUserId: w.check_user_id,
        checkUserName: w.check_user_name,
        approvalDate: w.approval_datetime,
        approvedUserId: w.approval_user_id,
        approvedUserName: w.approval_user_name,
        updateUserId: w.update_user_id,
        updateUserName: w.update_user_name,
        updateDatetime: w.update_datetime,
        isSubmitted: w.is_submitted,
        remarks: w.remarks,
        layout: w.layout,
        data: w.data,
        inspection_result_list: [],
        reports: [],
      };
      dispatch(actions.setPrevInspection(data));
    })
    .catch(() => {
      dispatch(actions.setPrevInspection(null));
    });
};

/**
 * @summary 点検結果承認・差し戻し
 * @param inspectionId  点検結果ID
 * @param approveRequest  {承認/差し戻し, コメント}
 * @returns
 */
export const approveInspectionAsync =
  (inspectionId: number, approveRequest: APIApproveInspectionRequest) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`/inspections/${inspectionId}/approve`, approveRequest)
      .then(() => {
        dispatch(actions.refreshWorkResultTab());
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @description 以下のコンポーネントで利用。
 * pages/inspection/edit
 * pages/plan/annual/editPlanItemModal
 *
 */
export const getInspectionChecklistsAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  const params = {
    property_id: propertyId,
  };

  await axios
    .get<APIGetInspectionChecklistsResponse>(`/inspections/items`, {
      params: params,
    })
    .then((response) => {
      const result = response.data.result;

      const inspectionChecklists: InspectionChecklistsList[] = result.map(
        (inspection): InspectionChecklistsList => ({
          propertyId: inspection.property_id,
          propertyName: inspection.property_name,
          inspectionChecklistId: inspection.inspection_checklist_id,
          title: inspection.title,
          description: inspection.description,
          inspectionTypeName: inspection.inspection_type_name,
          publishStatus: inspection.publish_status,
          publishStatusName: inspection.publish_status_name,
          createUserName: inspection.create_user_name,
          inspectionCount: inspection.inspection_count,
          hasFormat: inspection.has_format,
          updateDatetime: inspection.update_datetime,
        }),
      );
      dispatch(actions.setInspectionChecklists(inspectionChecklists));
    })
    .catch(() => {
      dispatch(actions.setInspectionChecklists([]));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @description 以下のコンポーネントで利用。
 * pages/inspection/edit/startModal
 *
 */
export const startUnscheduledInspectionAsync =
  (propertyId: number, checklistId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post<APIStartInspectionResponse>(
        `/properties/${propertyId}/inspection/${checklistId}/unscheduled/start`,
      )
      .then((response) => {
        const resultId = response.data.result.inspection_result_id;
        dispatch(actions.setIsNavigating2(true));
        dispatch(actions.setIsNavigatingTo(resultId));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 定期業務-作業結果タブ－「点検開始」ボタンから呼ばれる
 */
export const startScheduledInspectionAsync = (inspectionId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios.patch(`/inspections/${inspectionId}/start`);
  await axios
    // .get<APIGetInspectionResponse>(`/inspections/${inspectionId}`) ←もともとのAPI名
    .get<APIGetInspectionItemResponse>(`/inspections/${inspectionId}/results`)
    .then(async (response) => {
      const v = response.data.result;

      const data: InspectionResponseItem = {
        inspectionResultId: v.inspection_result_id,
        inspectionChecklistTitle: v.inspection_title,
        inspectionChecklistId: v.inspection_checklist_id,
        description: v.description,
        scheduledDate: v.schedule_start_date,
        inspectionType: v.inspection_type,
        inspectionTypeName: v.inspection_type_name,
        status: v.status,
        inspectionStatusName: v.status_name,
        checkDate: v.check_date,
        propertyId: v.property_id,
        propertyName: v.property_name,
        checkUserId: v.check_user_id,
        checkUserName: v.check_user_name,
        approvalDate: v.approval_datetime,
        approvedUserId: v.approval_user_id,
        approvedUserName: v.approval_user_name,
        updateUserId: v.update_user_id,
        updateUserName: v.update_user_name,
        updateDatetime: v.update_datetime,
        isSubmitted: v.is_submitted,
        remarks: v.remarks,
        layout: v.layout,
        data: v.data,
        inspection_result_list: v.inspection_result_list,
        reports: v.reports.map((r) => ({
          fileName: r.file_name,
          fileUrl: r.file_url ?? '',
          fileSize: r.file_size ?? 0,
        })),
      };

      const photos: Photo[] = v.data
        .filter((p) => p.photos.length > 0)
        .map((q) => {
          const photo = q.photos[0];
          return {
            id: q.id,
            name: photo.file_name,
            url: photo.file_url,
            content: '',
            memo: photo.memo,
            isUploaded: true,
            isDeleteFile: false,
            commit: false,
          };
        });

      dispatch(actions.setTargetInspection(data));
      dispatch(actions.setPhotos(photos));

      if (v.inspection_result_list.length > 0) {
        const first = v.inspection_result_list[0];
        await axios
          .get<APIGetInspectionItemResponse>(`/inspections/${first.inspection_result_id}/results`)
          .then((response) => {
            const w = response.data.result;
            const data: InspectionResponseItem = {
              inspectionResultId: first.inspection_result_id,
              inspectionChecklistTitle: w.inspection_title,
              inspectionChecklistId: w.inspection_checklist_id,
              description: w.description,
              scheduledDate: w.schedule_start_date,
              inspectionType: w.inspection_type,
              inspectionTypeName: w.inspection_type_name,
              status: w.status,
              inspectionStatusName: w.status_name,
              checkDate: w.check_date,
              propertyId: w.property_id,
              propertyName: w.property_name,
              checkUserId: w.check_user_id,
              checkUserName: w.check_user_name,
              approvalDate: w.approval_datetime,
              approvedUserId: w.approval_user_id,
              approvedUserName: w.approval_user_name,
              updateUserId: w.update_user_id,
              updateUserName: w.update_user_name,
              updateDatetime: w.update_datetime,
              isSubmitted: w.is_submitted,
              remarks: w.remarks,
              layout: w.layout,
              data: w.data,
              inspection_result_list: [],
              reports: [],
            };
            dispatch(actions.setPrevInspection(data));
          })
          .catch(() => {
            dispatch(actions.setPrevInspection(null));
          });
      } else {
        dispatch(actions.setPrevInspection(null));
      }
    })
    .catch(() => {
      dispatch(actions.setTargetInspection(null));
      dispatch(actions.setPrevInspection(null));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary ゲストモード以外での下書き保存を実行する
 * @param {number} inspectionId 定期業務ID
 * @param {number} checklistId 点検表ID
 * @param {InspectionResultData[]} data 点検結果
 * @param {FileList[]} reports 点検表の添付ファイル
 * @returns {Promise<boolean>} 下書き保存の成否
 */
export const saveInspectionAsync =
  (inspectionId: number, checklistId: number, data: InspectionResultData[], reports: FileList[]) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APISaveInspectionRequest = {
      inspection_checklist_id: checklistId,
      remarks: '',
      is_skip: false,
      data: data,
      reports: reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
    };

    return await axios
      .patch(`/inspections/${inspectionId}`, requestData, {
        params: { is_overwrite: true },
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(actions.setIsSubmitting(false));
      });
  };

/**
 * @description 以下の機能で利用
 * pages/inspection/edit
 */
export const completeInspectionAsync =
  (
    inspectionId: number,
    checklistId: number,
    modalComment: string,
    data: InspectionResultData[],
    reports: FileList[],
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));
    const requestData: APISaveInspectionRequest = {
      inspection_checklist_id: checklistId,
      remarks: '',
      is_skip: false,
      data: data,
      reports: reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
    };

    const commentData: APIInspectionCompleteRequest = {
      comment: modalComment,
    };

    await axios
      .patch(`/inspections/${inspectionId}`, requestData, {
        params: { is_overwrite: true },
      })
      .then(async () => {
        await axios
          .patch(`/inspections/${inspectionId}/complete`, commentData)
          .then(() => {
            console.log('APIInspectionCompleteRequest', commentData);
            dispatch(actions.setIsNavigating(true));
          })
          .catch(() => {});
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @description 以下の機能で利用
 * pages/inspection/edit/exportReportModal
 */
export const exportInspectionReportAsync = async (inspectionId: number, needArchive: boolean) => {
  const result = await axios
    .get<APIExportInspectionReportResponse>(`/inspections/${inspectionId}/export`, {
      params: { need_archive: needArchive },
    })
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return null;
    });

  return result;
};

/**
 * @description 以下の機能で利用
 * pages/inspection/edit
 */
export const getInspectionGuestAsync =
  (inspectionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoadingTab(true));
    const reqParams: APIGetInspectionGuestRequest = { token };

    await axios_
      .get<APIGetInspectionItemResponse>(`/api/guest/inspections/${inspectionId}`, {
        params: reqParams,
      })
      .then(async (response) => {
        console.log('Get Response');
        console.log(response as any);
        const v = response.data.result;

        const data: InspectionResponseItem = {
          inspectionResultId: v.inspection_result_id,
          inspectionChecklistTitle: v.inspection_title,
          inspectionChecklistId: v.inspection_checklist_id,
          description: v.description,
          scheduledDate: v.schedule_start_date,
          inspectionType: v.inspection_type,
          inspectionTypeName: v.inspection_type_name,
          status: v.status,
          inspectionStatusName: v.status_name,
          checkDate: v.check_date,
          propertyId: v.property_id,
          propertyName: v.property_name,
          checkUserId: v.check_user_id,
          checkUserName: v.check_user_name,
          approvalDate: v.approval_datetime,
          approvedUserId: v.approval_user_id,
          approvedUserName: v.approval_user_name,
          updateUserId: v.update_user_id,
          updateUserName: v.update_user_name,
          updateDatetime: v.update_datetime,
          isSubmitted: v.is_submitted,
          remarks: v.remarks,
          layout: v.layout,
          data: v.data,
          inspection_result_list: v.inspection_result_list,
          reports: v.reports.map((r) => ({
            fileName: r.file_name,
            fileUrl: r.file_url ?? '',
            fileSize: r.file_size ?? 0,
          })),
        };
        dispatch(actions.setTargetInspection(data));
        dispatch(actions.setPrevInspection(null));
      })
      .catch((error) => {
        console.log('Catch Error');
        alert(error.response?.data.message ?? '');
        dispatch(actions.setTargetInspection(null));
        dispatch(actions.setPrevInspection(null));
        dispatch(actions.setIsInvalidToken(true));
      });

    dispatch(actions.setIsLoadingTab(false));
  };

/**
 * @summary 定期業務の基本情報(ゲスト用)を取得する（getInspectionBasicAsyncをコピーし、guest用のURLに変更した。
 *          パラメータにはtokenを設定）
 * @param inspectionId
 * @param token ゲスト問い合わせようアクセストークン
 * @returns 定期業務の基本情報(ゲスト用)を返す。
 */
export const getInspectionBasicGuestAsync =
  (inspectionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoadingTab(true));
    const reqParams: APIGetInspectionGuestRequest = { token };
    await axios_
      .get<APIGetInspectionItemResponse | APIGetInspectionBasicResponse>(
        `/api/guest/inspections/${inspectionId}`,
        {
          params: reqParams,
        },
      )
      .then(async (response) => {
        const v = response.data.result as APIInspectionItem;

        const data: InspectionResponseItem = {
          inspectionResultId: v.inspection_result_id,
          inspectionChecklistTitle: v.inspection_title,
          inspectionChecklistId: v.inspection_checklist_id,
          description: v.description,
          scheduledDate: v.schedule_start_date,
          inspectionType: v.inspection_type,
          inspectionTypeName: v.inspection_type_name,
          status: v.status,
          inspectionStatusName: v.status_name,
          checkDate: v.check_date,
          propertyId: v.property_id,
          propertyName: v.property_name,
          checkUserId: v.check_user_id,
          checkUserName: v.check_user_name,
          approvalDate: v.approval_datetime,
          approvedUserId: v.approval_user_id,
          approvedUserName: v.approval_user_name,
          updateUserId: v.update_user_id,
          updateUserName: v.update_user_name,
          updateDatetime: v.update_datetime,
          isSubmitted: v.is_submitted,
          remarks: v.remarks,
          layout: v.layout,
          data: v.data,
          inspection_result_list: v.inspection_result_list,
          reports: v.reports.map((r) => ({
            fileName: r.file_name,
            fileUrl: r.file_url ?? '',
            fileSize: r.file_size ?? 0,
          })),
        };

        const photos: Photo[] = v.data
          .filter((p) => p.photos.length > 0)
          .map((q) => {
            const photo = q.photos[0];
            return {
              id: q.id,
              name: photo.file_name,
              url: photo.file_url,
              content: '',
              memo: photo.memo,
              isUploaded: true,
              isDeleteFile: false,
              commit: false,
            };
          });

        dispatch(actions.setTargetInspection(data));
        dispatch(actions.setPhotos(photos));
        dispatch(actions.setPrevInspection(null));

        const w = response.data.result as APIInspectionBasic;
        dispatch(
          actions.setInspectionBasic({
            statusId: v.status,
            statusName: v.status_name,
            inspectionResultId: v.inspection_result_id,
            propertyId: v.property_id,
            propertyName: v.property_name,
            contractId: 0,
            contractTitle: w.contract_title,
            contractTypeId: 0,
            contractTypeName: '',
            inspectionTypeId: 0,
            inspectionTypeName: v.inspection_type_name,
            inspectionDivisionId: 0,
            inspectionDivisionName: '',
            startDate: v.schedule_start_date,
            startTime: '',
            endDate: '',
            endTime: '',
            supervisors: [],
            workers: [],
            companies: [],
            adjustmentTargetsBasic: [],
            inspectionChecklistId: v.inspection_checklist_id,
            inspectionChecklistTitle: '',
            hasFormat: false,
            message: 0,
          }),
        );
      })
      .catch((error) => {
        console.log(error as any);
        alert(error.response?.data.message ?? '');
        dispatch(actions.setTargetInspection(null));
        dispatch(actions.setPrevInspection(null));
      });

    dispatch(actions.setIsLoadingTab(false));
  };
/**
 * @description 以下の機能で利用
 * pages/inspection/edit
 */
export const startScheduledInspectionGuestAsync =
  (inspectionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));
    const reqParams: APIGetInspectionGuestRequest = { token };

    await axios_
      .get<APIGetInspectionItemResponse>(`/api/guest/inspections/${inspectionId}`, {
        params: reqParams,
      })
      .then(async (response) => {
        const v = response.data.result;

        const data: InspectionResponseItem = {
          inspectionResultId: v.inspection_result_id,
          inspectionChecklistTitle: v.inspection_title,
          inspectionChecklistId: v.inspection_checklist_id,
          description: v.description,
          scheduledDate: v.schedule_start_date,
          inspectionType: v.inspection_type,
          inspectionTypeName: v.inspection_type_name,
          status: v.status,
          inspectionStatusName: v.status_name,
          checkDate: v.check_date,
          propertyId: v.property_id,
          propertyName: v.property_name,
          checkUserId: v.check_user_id,
          checkUserName: v.check_user_name,
          approvalDate: v.approval_datetime,
          approvedUserId: v.approval_user_id,
          approvedUserName: v.approval_user_name,
          updateUserId: v.update_user_id,
          updateUserName: v.update_user_name,
          updateDatetime: v.update_datetime,
          isSubmitted: v.is_submitted,
          remarks: v.remarks,
          layout: v.layout,
          data: v.data,
          inspection_result_list: v.inspection_result_list,
          reports: v.reports.map((r) => ({
            fileName: r.file_name,
            fileUrl: r.file_url ?? '',
            fileSize: r.file_size ?? 0,
          })),
        };

        const photos: Photo[] = v.data
          .filter((p) => p.photos.length > 0)
          .map((q) => {
            const photo = q.photos[0];
            return {
              id: q.id,
              name: photo.file_name,
              url: photo.file_url,
              content: '',
              memo: photo.memo,
              isUploaded: true,
              isDeleteFile: false,
              commit: false,
            };
          });

        dispatch(actions.setTargetInspection(data));
        dispatch(actions.setPhotos(photos));
        dispatch(actions.setPrevInspection(null));

        await axios_
          .patch(`/api/guest/inspections/${inspectionId}/start`, undefined, {
            params: reqParams,
          })
          .then(() => {})
          .catch((error) => {
            alert(error.response?.data.message ?? '');
            dispatch(actions.setTargetInspection(null));
            dispatch(actions.setPrevInspection(null));
          });
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
        dispatch(actions.setTargetInspection(null));
        dispatch(actions.setPrevInspection(null));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary ゲストモードでの下書き保存を実行する
 * @param {number} inspectionId 定期業務ID
 * @param {number} checklistId 点検表ID
 * @param {InspectionResultData[]} data 点検結果
 * @param {FileList[]} reports 点検表の添付ファイル
 * @param {string} token ゲストモードでAPI実行するために必要なトークン
 * @returns {Promise<boolean>} 下書き保存の成否
 */
export const saveInspectionGuestAsync =
  (
    inspectionId: number,
    checklistId: number,
    data: InspectionResultData[],
    reports: FileList[],
    token: string,
  ) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APISaveInspectionRequest = {
      inspection_checklist_id: checklistId,
      remarks: '',
      is_skip: false,
      data: data,
      reports: reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
    };

    return await axios_
      .patch(`/api/guest/inspections/${inspectionId}`, requestData, {
        params: { is_overwrite: true, token },
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
        return false;
      })
      .finally(() => {
        dispatch(actions.setIsSubmitting(false));
      });
  };

/**
 * @description 以下の機能で利用
 * pages/inspection/edit
 */
export const completeInspectionGuestAsync =
  (
    inspectionId: number,
    checklistId: number,
    modalComment: string,
    data: InspectionResultData[],
    reports: FileList[],
    token: string,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APISaveInspectionRequest = {
      inspection_checklist_id: checklistId,
      remarks: '',
      is_skip: false,
      data: data,
      reports: reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
    };
    const commentData: APIInspectionCompleteRequest = {
      comment: modalComment,
    };

    await axios_
      .patch(`/api/guest/inspections/${inspectionId}`, requestData, {
        params: { is_overwrite: true, token },
      })
      .then(async () => {
        await axios_
          .patch(`/api/guest/inspections/${inspectionId}/complete`, commentData, {
            params: { token },
          })
          .then(() => {
            dispatch(actions.setIsNavigating(true));
          })
          .catch((error) => {
            alert(error.response?.data.message ?? '');
          });
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
      });

    dispatch(actions.setIsSubmitting(false));
  };

/**
 *
 * @param inspectionId
 * @param token
 * @param data
 * @returns
 */
export const createInspectionReportGuestAsync =
  (inspectionId: number, token: string, data: InspectionReportSubmitData) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APIGetInspectionGuestRequest = { token };

    const submitData: APICreateInspectionReport = {
      description: data.description,
      reports: data.reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
      files: [],
    };

    const requestData: APICreateInspectionReport = submitData;

    await axios_
      .patch(`/api/guest/inspections/${inspectionId}`, requestData, { params: reqParams })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
      });

    dispatch(actions.setIsSubmitting(true));
  };

/**
 *
 * @param inspectionId
 * @param token
 * @returns
 */
export const submitInspectionGuestAsync =
  (inspectionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APIGetInspectionGuestRequest = { token };

    await axios_
      .patch(`/api/guest/inspections/${inspectionId}/submit`, undefined, {
        params: reqParams,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
      });

    dispatch(actions.setIsSubmitting(false));
  };

/** @summary 定期業務-連絡事項情報取得 */
export const setInspectionsInformationAsync = (inspectionId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoadingTab(true));

  await axios
    .get<APIGetInspectionInfoResponse>(`/inspections/${inspectionId}/information`)
    .then((response) => {
      const resultData = response.data.result;

      const inspectionInfoData: InspectionInfoData = {
        inspectionResultId: resultData.inspection_result_id,
        contractId: resultData.contract_id,
        contractTitle: resultData.contract_title,
        propertyId: resultData.property_id,
        propertyName: resultData.property_name,
        message: resultData.message,
        // nullの場合は空配列を設定。要素0でも「.length」プロパティにアクセス可能とさせたいため
        files: (resultData.file_list ?? []).map((f) => ({
          fileId: f.file_id,
          fileName: f.file_name,
          fileUrl: f.file_url,
          fileSize: f.file_size,
        })),
      };
      dispatch(actions.setInspectionInfo(inspectionInfoData));
    })
    .catch(() => {
      dispatch(actions.setInspectionInfo(null));
    });
  dispatch(actions.setIsLoadingTab(false));
};

/** @summary 定期業務-連絡事項情報取得 */
export const setInspectionsInformationGuestAsync =
  (inspectionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoadingTab(true));
    const reqParams: APIGetInspectionGuestRequest = { token };

    await axios_
      .get<APIGetInspectionInfoResponse>(`/api/guest/inspections/${inspectionId}/information`, {
        params: reqParams,
      })
      .then((response) => {
        const resultData = response.data.result;

        const inspectionInfoData: InspectionInfoData = {
          inspectionResultId: resultData.inspection_result_id,
          contractId: resultData.contract_id,
          contractTitle: resultData.contract_title,
          propertyId: resultData.property_id,
          propertyName: resultData.property_name,
          message: resultData.message,
          // nullの場合は空配列を設定。要素0でも「.length」プロパティにアクセス可能とさせたいため
          files: (resultData.file_list ?? []).map((f) => ({
            fileId: f.file_id,
            fileName: f.file_name,
            fileUrl: f.file_url,
            fileSize: f.file_size,
          })),
        };
        dispatch(actions.setInspectionInfo(inspectionInfoData));
      })
      .catch(() => {
        dispatch(actions.setInspectionInfo(null));
      });
    dispatch(actions.setIsLoadingTab(false));
  };

/** @summary 定期業務－ コメント一覧取得 */
export const getInspectionsCommentsAsync = (inspectionId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoadingTab(true));

  await axios
    .get<APIInspectionsCommentsResponse>(`/inspections/${inspectionId}/comments`)
    .then((response) => {
      const resultData = response.data.result;
      const inspectionsCommentsData: InspectionCommentsData = {
        commentList: resultData.comment_list.map((c) => ({
          commentId: c.comment_id,
          typeId: c.type_id,
          typeName: c.type_name,
          createUserId: c.create_user_id,
          createUserName: c.create_user_name,
          createDatetime: c.create_datetime,
          updateDatetime: c.update_datetime,
          beforeStatusId: c.before_status_id,
          beforeStatusName: c.before_status_name,
          afterStatusId: c.after_status_id,
          afterStatusName: c.after_status_name,
          comment: c.comment,
          imageList: c.image_list.map((i) => ({
            fileId: i.file_id,
            fileName: i.file_name,
            fileUrl: i.file_url,
            thumbnailFileUrl: i.thumbnail_file_url,
            fileSize: i.file_size,
            memo: i.memo,
          })),
          fileList: c.file_list.map((f) => ({
            fileId: f.file_id,
            fileName: f.file_name,
            fileUrl: f.file_url,
            fileSize: f.file_size,
          })),
        })),
      };
      dispatch(actions.setInspectionComments(inspectionsCommentsData));
    })
    .catch(() => {
      dispatch(actions.setInspectionComments(null));
      return;
    });
  dispatch(actions.setIsLoadingTab(false));
};
