import { createSelector } from '@reduxjs/toolkit';
import { AppState as State } from '@ducks/index';
import { OptionItem } from '@uiParts/selectBox';

export const getRefreshId = (state: State) => state.work.refresh;
export const getRefreshId2 = (state: State) => state.work.refresh2;
export const getRefreshId3 = (state: State) => state.work.refresh3;

export const checkIsLoading = (state: State) => state.work.isLoading;
export const checkIsLoading2 = (state: State) => state.work.isLoading2;
export const checkIsLoading3 = (state: State) => state.work.isLoading3;

export const checkIsSubmitting = (state: State) => state.work.isSubmitting;
export const checkIsNavigating = (state: State) => state.work.isNavigating;
export const checkIsNavigatingTo = (state: State) => state.work.isNavigatingTo;

export const getWorks = (state: State) => state.work.works;
export const getWorksListInfo = (state: State) => state.work.worksListInfo;

export const getWork = (state: State) => state.work.work;
export const getWorkHistory = (state: State) => state.work.workHistory;

/**
 * @summary ページャ情報を返却する
 * @description 案件 - 対応履歴で使用する
 * @param {State} state
 * @returns {WorkHistoryDisplayParams}
 */
export const getWorkHistoryDisplayParams = (state: State) => state.work.workHistoryDisplayParams;

/** 不具合連絡事項取得 */
export const getWorkInfo = (state: State) => state.work.workInfo;
export const getWorkHistoryById = (historyId: number | null) =>
  createSelector([(state: State) => state.work.workHistory], (workHistory) =>
    workHistory.find((v) => v.troubleShootingId === historyId),
  );

export const getOptionItemUser = (state: State): OptionItem[] =>
  state.work.worksItemData?.userList.map((v) => ({
    value: v.userId,
    title: v.userName,
  })) ?? [];
export const getOptionItemDiagram = (state: State): OptionItem[] =>
  state.work.worksItemData?.diagramList.map((v) => ({
    value: v.diagramId,
    title: v.diagramName,
  })) ?? [];
export const getOptionItemEquipment = (state: State): OptionItem[] =>
  state.work.worksItemData?.equipmentList.map((v) => ({
    value: v.equipmentId,
    title: v.equipmentName,
  })) ?? [];

// 1.3で使用していたが、1.4で使用しなくなったため削除
// 設備選択のプルダウン
// export const getOptionItemEquipmentOnDiagram = (diagramId: number | null) =>
//   createSelector(
//     [(state: State) => state.work.worksItemData?.equipmentList ?? []],
//     (equipmentList): OptionItem[] =>
//       equipmentList
//         .filter((v) => (diagramId != null ? v.diagramId === diagramId : true))
//         .map((v) => ({
//           value: v.equipmentId,
//           title: v.equipmentName,
//         })) ?? [],
//   );
export const getOptionItemProperty = (state: State): OptionItem[] =>
  state.common.propertyListFilteredByTagIds.map((property) => ({
    value: property.id,
    title: property.name,
    is_favorite: property.is_favorite,
  })) ?? [];
export const getOptionItemFloor = (state: State): OptionItem[] =>
  state.work.worksItemData?.floorList.map((v) => ({
    value: v.floorId,
    title: v.floorName,
  })) ?? [];
export const getOptionItemRoomOnFloor = (floorId: number | null) =>
  createSelector(
    [(state: State) => state.work.worksItemData?.floorList ?? []],
    (floorList): OptionItem[] =>
      floorList
        .find((v) => v.floorId === floorId)
        ?.roomList.map((v) => ({
          value: v.roomId,
          title: v.roomName,
        })) ?? [],
  );
// 1.3で使用していたが、1.4で使用しなくなったため削除
// 選択している設備に関連する系統
// export const getDiagramIdOnSelectedEquipment = (equipmentId: number | null) =>
//   createSelector(
//     [(state: State) => state.work.worksItemData?.equipmentList],
//     (equipmentList) => equipmentList?.find((v) => v.equipmentId === equipmentId)?.diagramId ?? null,
//   );
