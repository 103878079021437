const rscInfo = {
  APP_NAME: 'DK-CONNECT BM',
  APP_VERSION: 'v1.4.2',
  COPYRIGHT: 'Copyright © 2022 DAIKIN INDUSTRIES, LTD. All Rights Reserved.',
  COPYRIGHT_WRAP: 'Copyright © 2022\nDAIKIN INDUSTRIES, LTD.',
  EMAIL_SUPPORT: 'dkc-bm-support@daikin.co.jp',
};

const rscCommon = {
  'common.layout.menu.0001': '建物ブックマーク',
  'common.layout.menu.0002': '全ての建物',
  'common.layout.menu.0003': '全ての建物',
  'common.layout.menu.tagSelection.0001': 'タグ選択',
  'common.layout.menu.tagSelection.hints.0001':
    '情報を表示する建物をタグで\n絞り込むことができます。',
  'common.layout.menu.tagSelection.0002': '表示する建物一覧',
  'common.layout.menu.tagSelection.0003': 'タグで絞り込み',
  'common.layout.menu.tagSelection.0004': '建物名で検索する',
  'common.layout.menu.tagSelection.0005': 'タグ名で検索する',
  'common.layout.menu.tagSelection.0006': 'タグ未選択',
  'common.layout.menu.tagSelection.0007': 'タグ選択中',
  'common.layout.menu.tagSelection.0008': 'タグ選択をクリアする',
  'common.layout.menu.tagSelection.0009': '選択',
  'common.layout.menu.1001': 'ホーム',
  'common.layout.menu.2001': '物件',
  'common.layout.menu.2101': '建物',
  'common.layout.menu.2102': '設備',
  'common.layout.menu.2103': '図面',
  'common.layout.menu.3001': '業務',
  'common.layout.menu.3101': '査収',
  'common.layout.menu.3102': '定期業務',
  'common.layout.menu.3103': '工事',
  'common.layout.menu.3104': '案件',
  'common.layout.menu.4001': '計画',
  'common.layout.menu.4101': '年次計画',
  'common.layout.menu.4102': '月次計画',
  'common.layout.menu.4103': '日次計画',
  'common.layout.menu.5001': '書庫',
  'common.layout.menu.5101': 'ドキュメント',
  'common.layout.menu.5102': '点検表',
  'common.layout.menu.5103': '連絡先',
  'common.layout.menu.6001': 'システム',
  'common.layout.menu.6102': 'ユーザー',
  'common.layout.menu.6103': 'グループ',
  'common.layout.menu.6105': 'ライセンス',
  'common.layout.menu.hints.0001': '情報を表示する建物を\n選択することができます。',
  'common.notFound.0001': 'ページが見つかりませんでした',
  'common.form.0001': '必須',
  'common.form.0002': '入力必須項目です',
  'common.form.0003': '文字数が多すぎます',
  'common.form.0004': '有効なメールアドレスを入力してください',
  'common.form.0005': '電話番号が正しくありません\n入力例） 00-0000-0000',
  'common.form.0006': '郵便番号が正しくありません\n入力例） 000-0000',
  'common.form.0007': '全角カタカナで入力してください',
  'common.form.0008': '正の整数のみ入力してください',
  'common.form.0009': '{min}と{max}の間の数値を入力してください',
  'common.file.0001': 'ファイルサイズが大きすぎます',
  'common.file.0002': 'ファイル名が重複しています。',
  'common.file.0003': 'ファイルサイズが {0}MB を超えています。',
  'common.navigation.block': 'このページを離れますか？\n行った変更が保存されない可能性があります。',
  'common.runtimeError.0001': 'Application Error!',
  'common.runtimeError.0002': 'React の処理中にエラーが発生しました。',
  'common.runtimeError.0003': 'エラー詳細：',
  'common.runtimeError.1001': 'ホームに戻る',
  'common.system.timeout': 'タイムアウトしました。',
  'common.error.title': 'システムエラー',
  'common.error.caption': '問題が発生しました',
  'common.error.message.0001': '申し訳ございませんが、システムエラーが発生しました。',
  'common.error.message.0002': '数分お待ちいただき、もう一度お試しください。',
  'common.error.message.0003': '問題が解消しない場合は、下記へお問い合わせください。',
  'common.error.support.title': '【お問い合わせ先】DK-CONNECT BM サポート',
  'common.error.support.mail': 'メール',
  'common.date.range.check.error.date.start': '開始日の範囲指定が不正です。',
  'common.date.range.check.error.date.end': '終了日の範囲指定が不正です。',
  'common.date.range.check.error.inspection': '定期業務数の範囲指定が不正です。',
  'common.error.set.tag': '設定できるタグは個人タグ、共有タグそれぞれ10件までになります。',
  'common.error.page.authority': '権限がないためホーム画面に遷移します。',
};

const rscHome = {
  /* 共通 */
  'page.home.common.title': 'ホーム',
  'page.home.common.rest': '残り',
  'page.home.common.post': '件',
  'page.home.common.seeMore': '続きを見る',
  'page.home.common.noData': '必要な点検作業はありません。',
  /* 定期業務 */
  'page.home.task.task': '本日の定期業務',
  'page.home.task.checkingTask': '査収待ちの定期業務',
  'page.home.statusName': '点検状況',
  'page.home.startDateTime': '計画開始日時',
  'page.home.task.updateDateTime': '最終更新日時',
  'page.home.typeName': '点検分類',
  'page.home.contractTitle': '計画名',
  'page.home.latestComment': 'コメント',
  'page.home.workerNames': '作業者',
  'page.home.checkUserName': '実施者',
  'page.home.supervisorNames': '監督者',
  'page.home.approvalUserName': '承認者',
  'page.home.propertyName': '建物名',
  /* 工事 */
  'page.home.construction.incompleteConstruction': '未完了の工事',
  'page.home.construction.checkingConstruction': '査収待ちの工事',
  'page.home.construction.statusName': '対応状況',
  'page.home.startDate': '開始日',
  'page.home.construction.updateDatetime': '最新更新日時',
  'page.home.construction.typeName': '種類',
  'page.home.reporterName': '最新実施者',
  /* 案件 */
  'page.home.trouble.statusName': '対応状況',
  'page.home.priorityName': '優先度',
  'page.home.occurrenceDate': '開始日',
  'page.home.updateDatetime': '最新更新日時',
  'page.home.troubleTypeName': '作業分類',
  'page.home.title': '計画名',
  'page.home.picUserName': '最新実施者',
};

const rscLogin = {
  'page.login.0001': 'ログイン',
  'page.login.1001': 'お問い合わせ',
  'page.login.1002': 'メールでのお問い合わせ',
  'page.login.1004': 'メンテナンス情報はこちら ▶',
};

const rscLicense = {
  'page.license.0001': 'ライセンス',
};

const rscEquipment = {
  'page.equipment.list.0001': '設備一覧',
  'page.equipment.list.0002': '設備を検索',
  'page.equipment.list.0003': '新規登録',
  'page.equipment.list.1001': '機器種別',
  'page.equipment.list.1002': '設備名',
  'page.equipment.list.1003': '型番',
  'page.equipment.list.1004': '建物名',
  'page.equipment.list.1005': 'フロア',
  'page.equipment.list.1006': '部屋',
  'page.equipment.list.1007': '設置年月',
  'page.equipment.list.1008': '経過年数',
  'page.equipment.list.1009': '点検中',
  'page.equipment.list.1010': '案件対応中',
  'page.equipment.list.1011': '異常',
  'page.equipment.list.1012': '機器種別',
  'page.equipment.list.1013': '製造番号',
  'page.equipment.list.1014': '系統名',
  'page.equipment.list.1015': '耐用年数',
  'page.equipment.list.1016': '経過年数',
  'page.equipment.advanced.search.year': '年',
  'page.equipment.advanced.search.in_trouble': '設備異常あり',
  'page.equipment.advanced.search.in_construction': '工事中',
  'page.equipment.advanced.search.diagram.description1':
    '※建物選択中 かつ 対象の建物の設備に「系統」が',
  'page.equipment.advanced.search.diagram.description2':
    '1つ以上登録済みである場合に、選択オプションが表示されます',
  'page.equipment.list.2001': '未使用設備の非表示',
  'page.equipment.list.2002': '未使用設備の表示',
  'page.equipment.list.1999': '登録がありません',
  'page.equipment.list.download.selectedRow': '選択行のみダウンロード',
  'page.equipment.list.download.all': 'すべてダウンロード',
  'page.equipment.list.delete': '選択した設備を削除',
  'page.equipment.list.delete.confirmation': '選択した設備を削除しますか',
  'page.equipment.list.delete.cancel': 'キャンセル',
  'page.equipment.list.delete.execute': '削除',
  'page.equipment.list.excel.1001': 'キャンセル',
  'page.equipment.list.excel.1002': '登録',
  'page.equipment.list.hints.0001':
    '設備名、機器種別、型番、フロア、部屋、メーカー、系統で検索できます。',
  'page.equipment.list.hints.0002':
    '設備名、機器種別、型番、製造番号、フロア、部屋、メーカー、系統で検索できます。',
  'page.equipment.add.0001': '設備を登録',
  'page.equipment.dropdown.add.0001': '手入力で作成',
  'page.equipment.dropdown.add.0002': 'CSVでまとめて作成',
  'page.equipment.upload.modal.0001': '設備を一括登録/更新する',
  'page.equipment.upload.modal.0002': 'キャンセル',
  'page.equipment.upload.modal.0003': '登録',
  'page.equipment.upload.modal.0004': '設備を一括で登録/更新できます。',
  'page.equipment.upload.modal.0005':
    'ご注意：\n前機種や後継機種など、一部の項目（一括登録用テンプレートにない項目）は一括登録/更新できません。\n取り込みが完了した後に設定をお願いいたします。',
  'page.equipment.upload.modal.0006': '設備を一括で登録/更新しますか？',
  'page.equipment.upload.modal.0007': 'ファイルのアップロードに失敗しました',
  'page.equipment.detail.0001': '設備詳細',
  'page.equipment.detail.0002': '編集',
  'page.equipment.detail.0003': '削除',
  'page.equipment.detail.tab.0001': '基本情報',
  'page.equipment.detail.tab.0002': '型番情報',
  'page.equipment.detail.tab.0003': '運転情報',
  'page.equipment.detail.tab.0004': 'Kireiウォッチ',
  'page.equipment.detail.tab.0005': '作業履歴',
  'page.equipment.detail.general.0001': '設備名',
  'page.equipment.detail.general.0002': '型番',
  'page.equipment.detail.general.0003': '製造番号',
  'page.equipment.detail.general.0004': '機器種別',
  'page.equipment.detail.general.0005': 'メーカー',
  'page.equipment.detail.general.0006': '系統名',
  'page.equipment.detail.general.0007': '機器記号',
  'page.equipment.detail.general.0008': '建物名',
  'page.equipment.detail.general.0009': 'フロア名',
  'page.equipment.detail.general.0010': '部屋名',
  'page.equipment.detail.general.0011': '設置日',
  'page.equipment.detail.general.0012': '製造日',
  'page.equipment.detail.general.0013': '備考',
  'page.equipment.detail.general.usefulYear': '耐用年数',
  'page.equipment.detail.general.elapsedYear': '経過年数',
  'page.equipment.detail.general.year': '年',
  'page.equipment.detail.general.previousEquipmentName': '前機種',
  'page.equipment.detail.general.successorEquipmentName': '後継機種',
  'page.equipment.detail.general.document': 'リンク',
  'page.equipment.detail.general.isUnused': '未使用',
  'page.equipment.detail.model.0001': '型番',
  'page.equipment.detail.model.0002': 'モデル名',
  'page.equipment.detail.model.0003': 'メーカー',
  'page.equipment.detail.model.0004': '発売年月',
  'page.equipment.detail.model.0005': 'モデル仕様',
  'page.equipment.detail.sqses.0001': '優先度',
  'page.equipment.detail.sqses.0002': '対応状況',
  'page.equipment.detail.sqses.0003': '作業分類',
  'page.equipment.detail.sqses.0004': 'タイトル',
  'page.equipment.detail.sqses.0005': '発生日',
  'page.equipment.detail.sqses.0006': '最終更新',
  'page.equipment.detail.sqses.1001': '過去にこの設備に対して実施された作業がありません',
  'page.equipment.detail.sqses.1002': 'ダイキン登録の作業を確認する',
  'page.equipment.detail.sqses.1003': '戻る',
  'page.equipment.detail.sqses.2001': '管理番号',
  'page.equipment.detail.sqses.2002': '異常コード',
  'page.equipment.detail.sqses.2003': 'タイトル',
  'page.equipment.detail.sqses.2004': '作業内容',
  'page.equipment.detail.sqses.2005': '作業日',
  'page.equipment.detail.gpf.0001': '電源状態',
  'page.equipment.detail.gpf.0002': '運転モード',
  'page.equipment.detail.gpf.0003': '設定温度',
  'page.equipment.detail.gpf.0004': '風量',
  'page.equipment.detail.gpf.0005': '機器エラー情報',
  'page.equipment.detail.gpf.1001': 'ON',
  'page.equipment.detail.gpf.1002': 'OFF',
  'page.equipment.detail.gpf.2001': '℃',
  'page.equipment.detail.gpf.3001': 'なし',
  'page.equipment.detail.kirei.0001': '撮影日時',
  'page.equipment.detail.kirei.0002': '画像',
  'page.equipment.detail.kirei.0003': '汚れ度合い',
  'page.equipment.detail.kirei.1001': '%',
  'page.equipment.edit.0001': '設備編集',
  'page.equipment.edit.0002': '設備登録',
  'page.equipment.edit.0003': '保存',
  'page.equipment.edit.0004': '終了',
  'page.equipment.edit.1001': '設備名',
  'page.equipment.edit.1002': '型番',
  'page.equipment.edit.1003': '製造番号',
  'page.equipment.edit.1004': '機器種別',
  'page.equipment.edit.1005': 'メーカー',
  'page.equipment.edit.1006': '系統名',
  'page.equipment.edit.1007': '機器記号',
  'page.equipment.edit.1008': '建物名',
  'page.equipment.edit.1009': 'フロア名',
  'page.equipment.edit.1010': '部屋名',
  'page.equipment.edit.1011': '設置日',
  'page.equipment.edit.1012': '製造日',
  'page.equipment.edit.1013': '備考',
  'page.equipment.edit.1014': 'リンク',
  'page.equipment.edit.usefulYear': '耐用年数',
  'page.equipment.edit.previousEquipmentName': '前機種',
  'page.equipment.edit.successorEquipmentName': '後継機種',
  'page.equipment.edit.image.0001': '画像',
  'page.equipment.edit.image.0002': 'ファイル選択',
  'page.equipment.edit.image.0003': '削除',
  'page.equipment.edit.image.1001': '[選択可能ファイル]',
  'page.equipment.edit.image.1002': '画像：最大 10 件',
  'page.equipment.edit.image.1003': 'ファイルサイズの上限：1件あたり 100MB, \n合計 300 MBまで',
  'page.equipment.edit.diagram.0001': '系統の新規作成',
  'page.equipment.edit.diagram.0002': '（新規）',
  'page.equipment.edit.diagram.1001': '新規作成',
  'page.equipment.edit.diagram.1002': '系統名',
  'page.equipment.edit.diagram.1003': '説明',
  'page.equipment.edit.diagram.1004': '作成',
  'page.equipment.edit.diagram.1005': 'キャンセル',
  'page.equipment.edit.hints.0001':
    '空調、電気などの系統名を入力してください。\n系統を登録すると、同じ系統でまとめて参照できるようになります。',
  'page.equipment.edit.hints.0002': '機器に採番されている値がある場合、入力してください。',
};

const rscDiagram = {
  'page.diagram.detail.0001': '設備系統詳細',
  'page.diagram.detail.0002': '編集',
  'page.diagram.detail.0003': '削除',
  'page.diagram.detail.1001': '系統名',
  'page.diagram.detail.1002': '説明',
  'page.diagram.detail.2001': '機器記号',
  'page.diagram.detail.2002': '機器名',
  'page.diagram.detail.2003': '型番',
  'page.diagram.detail.2004': '機器種別',
  'page.diagram.detail.2005': 'メーカー',
  'page.diagram.detail.2006': 'フロア名',
  'page.diagram.detail.2999': '登録がありません',
  'page.diagram.edit.0001': '設備系統編集',
  'page.diagram.edit.0002': '編集',
  'page.diagram.edit.0003': '終了',
  'page.diagram.edit.0004': '設備を系統に追加',
  'page.diagram.edit.1001': '削除',
  'page.diagram.edit.editDiagramModal.0001': '系統編集',
  'page.diagram.edit.editDiagramModal.0002': '系統名',
  'page.diagram.edit.editDiagramModal.0003': '説明',
  'page.diagram.edit.editDiagramModal.1001': 'キャンセル',
  'page.diagram.edit.editDiagramModal.1002': '保存',
  'page.diagram.edit.addDiagramEquipmentsModal.0001': '系統追加',
  'page.diagram.edit.addDiagramEquipmentsModal.0002': '設備名',
  'page.diagram.edit.addDiagramEquipmentsModal.0003': '機器記号',
  'page.diagram.edit.addDiagramEquipmentsModal.1001': 'キャンセル',
  'page.diagram.edit.addDiagramEquipmentsModal.1002': '保存',
};

const rscFloor = {
  'page.floor.0001': '図面',
  'page.floor.0002': '図面登録',
  'page.floor.0003': '図面編集',
  'page.floor.0004': '保存',
  'page.floor.0005': '終了',
  'page.floor.0006': '図面変更',
  'page.floor.1001': '設備',
  'page.floor.1002': '部屋',
  'page.floor.1003': '設備の絞り込み',
  'page.floor.1004': '種別',
  'page.floor.1005': '-- 選択してください --',
  'page.floor.1006': 'もっと絞り込む',
  'page.floor.1007': 'クリア',
  'page.floor.1999': 'フロアが登録されていません。',
  'page.floor.1101': '詳細検索',
  'page.floor.1102': '検索',
  'page.floor.1103': '種別',
  'page.floor.1104': '系統',
  'page.floor.1105': '型番',
  'page.floor.1106': '製造番号',
  'page.floor.1107': '設置部屋',
  'page.floor.1108': 'キーワード',
  'page.floor.1109': 'キーワードを入力してください',
  'page.floor.1110': 'キャンセル',
  'page.floor.2001': '種別',
  'page.floor.2002': '設備',
  'page.floor.2003': '系統　　',
  'page.floor.2004': '型番　　',
  'page.floor.2005': '製造番号',
  'page.floor.2999': '設備がありません。',
  'page.floor.3001': '部屋名',
  'page.floor.3002': '説明',
  'page.floor.3999': '部屋がありません。',
  'page.floor.4001': '選択中の設備',
  'page.floor.4002': '選択中の部屋',
  'page.floor.4003': '設備名',
  'page.floor.4004': '系統',
  'page.floor.4005': '型番',
  'page.floor.4006': '製造番号',
  'page.floor.5101': '設備の位置をクリックで指定してください。',
  'page.floor.5102': 'この位置でよろしいですか？（クリックで再選択）',
  'page.floor.5103': '保存',
  'page.floor.5104': 'キャンセル',
  'page.floor.5201': '部屋の範囲を指定するため、部屋の角の位置を',
  'page.floor.5202': '3点',
  'page.floor.5203': '以上クリックで指定してください。',
  'page.floor.5204': 'この範囲でよろしいですか？（クリックで角の点を更に追加できます。）',
  'page.floor.5205': '保存',
  'page.floor.5206': 'キャンセル',
  'page.floor.6001': '機器種別',
  'page.floor.6002': '空気調和設備',
  'page.floor.6003': '給排水設備',
  'page.floor.6004': '昇降機設備',
  'page.floor.6005': '強電設備',
  'page.floor.6006': '弱電設備',
  'page.floor.6007': '防災設備',
  'page.floor.6008': 'その他',
  'page.floor.7001': 'アップロード可能なファイル形式は .jpg, .png のみです。',
  'page.floor.8101': '確認',
  'page.floor.8102': 'フロアマップの編集を終了してもよろしいですか？',
  'page.floor.8103': '※「保存」されていない変更は保存されません。',
  'page.floor.8104': 'いいえ',
  'page.floor.8105': 'はい',
  'page.floor.8201': '確認',
  'page.floor.8203': 'キャンセル',
  'page.floor.8204': '登録',
  'page.floor.8301': '削除対象としたフロアを参照している項目が存在しますが、削除を継続しますか？',
};

export const rcsChecking = {
  'page.checking.list.0001': '査収一覧',

  'page.checking.list.1001': '計画種別',
  'page.checking.list.1002': '開始日',
  'page.checking.list.1003': '最新更新日',
  'page.checking.list.1004': '作業分類',
  'page.checking.list.1005': '最新実施者',
  'page.checking.list.1006': '建物名',

  'page.checking.table.header.0001': '計画種別',
  'page.checking.table.header.0002': '開始日',
  'page.checking.table.header.0003': '最新更新日',
  'page.checking.table.header.0004': '作業分類',
  'page.checking.table.header.0005': '計画名',
  'page.checking.table.header.0006': '異常件数',
  'page.checking.table.header.0007': 'コメント',
  'page.checking.table.header.0008': '最新実施者',
  'page.checking.table.header.0009': '建物名',
  'page.checking.table.header.0010': '送信先がありません',

  'page.checking.dropdown.0001': '選択した計画の承認',
  'page.checking.dropdown.0002': '選択した計画の差し戻し',

  'page.checking.submit.modal.0001': '確認',
  'page.checking.submit.modal.0002': 'いいえ',
  'page.checking.submit.modal.0003': 'はい',
  'page.checking.submit.modal.0004': 'システムは選択した項目を一括承認します',
  'page.checking.submit.modal.0005': 'システムは選択した項目を一括差し戻しします',
  'page.checking.submit.modal.0006': 'よろしいですか',
};

export const rscInspection = {
  'page.inspection.list.0001': '定期業務一覧',
  'page.inspection.list.0002': '定期業務を検索',
  'page.inspection.list.0003': '点検開始',
  'page.inspection.list.0004': '点検表一覧',
  'page.inspection.list.0005': '承認一覧',
  'page.inspection.list.1001': '対応状況',
  'page.inspection.list.1002': '点検分類',
  'page.inspection.list.1003': '計画名',
  'page.inspection.list.1004': '計画開始日時',
  'page.inspection.list.1005': '最終更新日',
  'page.inspection.list.1006': 'コメント',
  'page.inspection.list.1007': '建物名',
  'page.inspection.list.1008': '監督者',
  'page.inspection.list.1009': '作業者',
  'page.inspection.list.1010': '実施者',
  'page.inspection.list.1011': '承認者',
  // tab - 基本情報
  'page.inspection.tab.base.info.0001': '対応状況',
  'page.inspection.tab.base.info.0002': '建物名',
  'page.inspection.tab.base.info.0003': '建物ID',
  'page.inspection.tab.base.info.0004': '計画名',
  'page.inspection.tab.base.info.0005': '契約種別',
  'page.inspection.tab.base.info.0006': '定期業務種別',
  'page.inspection.tab.base.info.0007': '定期業務区分',
  'page.inspection.tab.base.info.0008': '開始日',
  'page.inspection.tab.base.info.0009': '開始時間',
  'page.inspection.tab.base.info.0010': '終了日',
  'page.inspection.tab.base.info.0011': '終了時間',
  'page.inspection.tab.base.info.0012': '監督者',
  'page.inspection.tab.base.info.0013': '作業者',
  'page.inspection.tab.base.info.0014': '協力会社名(企業名)',
  'page.inspection.tab.base.info.0015': '協力会社ID(企業ID)',
  'page.inspection.tab.base.info.0016': '予定調整先',
  'page.inspection.tab.base.info.0017': '点検表',
  'page.inspection.tab.base.info.0018': '報告書フォーマット',
  'page.inspection.tab.base.info.0019': '報告書提出先URL',
  'page.inspection.tab.base.info.0020': '今回の作業の連絡事項',
  'page.inspection.tab.base.info.0021': '登録あり',
  'page.inspection.tab.base.info.0022': '登録なし',
  'page.inspection.tab.base.info.1001': 'コピーしました',
  'page.inspection.tab.base.info.1002': 'クリップボードにコピー',
  'page.inspection.tab.base.info.1003': '報告書提出先URLをコピー',
  'page.inspection.tab.base.info.1005': '報告書提出先URLを正常に取得できませんでした。',

  'page.inspection.delete.comment.modal.0001': 'いいえ',
  'page.inspection.delete.comment.modal.0002': 'はい',
  'page.inspection.delete.comment.modal.0003': '削除しますか？？',
  'page.inspection.edit.operation.menu.0001': 'コメントを入力してください',
  'page.inspection.edit.modal.0001': '伝達事項の編集',
  'page.inspection.edit.modal.0002': 'キャンセル',
  'page.inspection.edit.modal.0003': '保存',
  'page.inspection.edit.modal.0004': '伝達事項',
  'page.inspection.edit.operation.menu.0002': '入力必須項目です',

  'page.inspection.comment.modal.1001': 'ファイル名が重複しています。',
  'page.inspection.comment.modal.1002': 'ファイルサイズが {0}MB を超えています',
  'page.inspection.tab.0001': '定期業務詳細',
  'page.inspection.tab.0501': '指定されたURLは有効期限が切れているか、不正なURLです。',
  'page.inspection.tab.5001': '基本情報',
  'page.inspection.tab.5002': '連絡事項',
  'page.inspection.tab.5003': 'コメント',
  'page.inspection.tab.5004': '作業結果',
  'page.inspection.tab.5005': 'コメント',

  'page.inspection.tab.base.info.1004': '必ず添付ファイルを登録してください。',
  'page.inspection.tab.notification.0001': '添付ファイル',
  'page.inspection.tab.notification.0002': '伝達事項',
  'page.inspection.tab.notification.0003': '伝達事項の登録がありません',
  'page.inspection.tab.notification.0004': 'コピーしました',
  'page.inspection.tab.notification.0005': 'クリップボードにコピー',
  'page.inspection.tab.notification.0006': 'コメントをコピー',
  'page.inspection.tab.edit.operation.menu.0001': '名前を変更',
  'page.inspection.tab.edit.operation.menu.0002': 'ダウンロード',
  'page.inspection.tab.edit.operation.menu.0003': '削除',
  'page.inspection.tab.edit.operation.menu.0101': 'ファイル名',
  'page.inspection.tab.comment.0001': 'コメント',
  'page.inspection.tab.comment.0002': 'あなたが',
  'page.inspection.tab.comment.0003': 'さんが',
  'page.inspection.tab.comment.0004': 'にコメントしました。',
  'page.inspection.tab.comment.0005': '編集済',
  'page.inspection.tab.comment.0006': '投稿内容',
  'page.inspection.tab.comment.0007': '添付ファイル',
  'page.inspection.tab.comment.0008': '申請',
  'page.inspection.tab.comment.0009': '承認',
  'page.inspection.tab.comment.0010': '差し戻し',
  'page.inspection.tab.comment.0011': 'に申請しました。',
  'page.inspection.tab.comment.0012': 'に承認しました。',
  'page.inspection.tab.comment.0013': 'に差し戻ししました。',
  'page.inspection.tab.comment.0014': 'コメントの登録がありません。',
  'page.inspection.tab.comment.0015': 'あなたが',
  'page.inspection.tab.comment.0016': 'さんが',
  'page.inspection.tab.comment.0017': '編集済',
  'page.inspection.tab.comment.0018': '投稿内容',
  'page.inspection.tab.comment.0019': '対応状況',
  'page.inspection.tab.comment.0020': 'に点検開始しました。',
  'page.inspection.tab.comment.1001':
    'ご注意：連絡事項に記録した添付ファイルや内容は、作業者ならびに監督者や社外の協力会社に共有されます。登録内容に機密情報が無いか十分ご注意ください。',
  'page.inspection.tab.comment.2001': 'ファイルサイズの上限：100 MB',
  'page.inspection.tab.9001': '登録がありません',
  'page.inspection.list.menu.0001': '-- 選択してください --',
  'page.inspection.list.menu.0002': '報告書フォーマットもコピーする',
  'page.inspection.list.menu.0003': '登録',
  'page.inspection.list.menu.0004': 'キャンセル',
  'page.inspection.list.menu.0005': 'コピーを作成',
  'page.inspection.list.menu.0006': '変更する',
  'page.inspection.list.menu.0007': '削除する',
  'page.inspection.list.menu.0008': '発行済みにステータスを変更',
  'page.inspection.list.menu.0009': '選択した点検表を削除する',
  'page.inspection.list.menu.0010': 'CSVでまとめて作成',
  'page.inspection.list.menu.0011': '点検表を一括登録/更新する',
  'page.inspection.list.menu.0012': '点検表をコピー',
  'page.inspection.list.menu.0013': '選択した項目をダウンロード',
  'page.inspection.list.menu.0014': 'すべてをダウンロード',

  'page.inspection.list.1101': '完了の非表示',
  'page.inspection.list.1102': '完了の表示',
  'page.inspection.list.1999': '登録がありません',
  'page.inspection.list.advanced.search.modal.0001': '詳細検索',
  'page.inspection.list.advanced.search.modal.0002': '計画名',
  'page.inspection.list.advanced.search.modal.0003': '定期業務種別',
  'page.inspection.list.advanced.search.modal.0004': '定期業務区分',
  'page.inspection.list.advanced.search.modal.0005': '対応状況',
  'page.inspection.list.advanced.search.modal.0006': '監督者',
  'page.inspection.list.advanced.search.modal.0007': '作業者',
  'page.inspection.list.advanced.search.modal.0008': '協力会社',
  'page.inspection.list.advanced.search.modal.0009': '承認者',
  'page.inspection.list.advanced.search.modal.0010': '開始日',
  'page.inspection.list.advanced.search.modal.0011': '終了日',
  'page.inspection.list.advanced.search.modal.1001': 'クリア',
  'page.inspection.list.advanced.search.modal.1002': '検索',
  'page.inspection.list.modal.0001': '点検表名',
  'page.inspection.list.modal.0002': '点検表の説明',
  'page.inspection.list.modal.0003': '点検分類',
  'page.inspection.list.modal.0004': 'ステータス',
  'page.inspection.list.modal.0005': '作成者',
  'page.inspection.list.modal.0006': '最終更新日',
  'page.inspection.list.modal.0007': '報告書フォーマット',
  'page.inspection.list.modal.0008': '点検表一覧',
  'page.inspection.list.modal.0009': '登録あり',
  'page.inspection.list.modal.1000': '選択',
  'page.inspection.list.modal.1001': 'キャンセル',
  'page.inspection.list.advanced.modal.0001': '点検表名',
  'page.inspection.list.advanced.modal.0002': '点検表の説明',
  'page.inspection.list.advanced.modal.0003': '定期業務数',
  'page.inspection.list.advanced.modal.0004': 'ステータス',
  'page.inspection.list.advanced.modal.0005': '報告書フォーマット',
  'page.inspection.list.advanced.modal.0006': '有り',
  'page.inspection.list.advanced.modal.0007': '無し',
  'page.inspection.list.advanced.modal.0008': '設定済み',
  'page.inspection.list.advanced.modal.0009': '未設定',
  'page.inspection.list.advanced.modal.0010': '下書き',
  'page.inspection.list.advanced.modal.0011': '発行済み',
  'page.inspection.list.advanced.modal.0012': '詳細検索',
  'page.inspection.list.advanced.modal.0013': '点検表名を入力してください',
  'page.inspection.list.advanced.modal.0014': '説明内容を入力してください',
  'page.inspection.list.advanced.modal.1001': '検索',
  'page.inspection.list.advanced.modal.1002': 'クリア',
  'page.inspection.list.advanced.modal.2001': '～',
  'page.inspection.list.approve.0001': '承認一覧',
  'page.inspection.list.approve.0002': '点検一覧',
  'page.inspection.list.approve.1001': '点検状況',
  'page.inspection.list.approve.1002': '点検分類',
  'page.inspection.list.approve.1003': 'タイトル',
  'page.inspection.list.approve.1004': '異常件数',
  'page.inspection.list.approve.1005': '承認',
  'page.inspection.list.approve.1006': '点検実施者',
  'page.inspection.list.approve.1007': '点検実施日',
  'page.inspection.list.approve.1008': '建物名',
  'page.inspection.list.approve.1101': '件',
  'page.inspection.list.approve.1201': '承認する',
  'page.inspection.list.approve.1202': '差し戻す',
  'page.inspection.list.approve.1301': '承認完了',
  'page.inspection.list.approve.1302': '差し戻す',
  'page.inspection.list.approve.1999': '承認できる点検結果がありません',
  'page.inspection.detail.0001': '点検詳細',
  'page.inspection.detail.0101': '点検開始',
  'page.inspection.detail.0102': '点検再開',
  'page.inspection.detail.0103': '承認',
  'page.inspection.detail.0104': '中止/差し戻し',
  'page.inspection.detail.0105': '報告書出力',
  'page.inspection.detail.0106': '差し戻す',
  'page.inspection.detail.1000': '点検ID',
  'page.inspection.detail.1001': '点検表',
  'page.inspection.detail.1002': '点検分類',
  'page.inspection.detail.1003': '対応状況',
  'page.inspection.detail.1004': '建物名',
  'page.inspection.detail.1005': '予定日',
  'page.inspection.detail.1006': '実施日',
  'page.inspection.detail.1007': '実施者',
  'page.inspection.detail.1008': '承認日',
  'page.inspection.detail.1009': '承認者',
  'page.inspection.detail.1010': '最終更新',
  'page.inspection.detail.1011': '説明',
  'page.inspection.detail.1012': '更新者',
  'page.inspection.detail.1013': '添付ファイル',
  'page.inspection.detail.2001': '対象設備：',
  'page.inspection.detail.2002': '今回結果',
  'page.inspection.detail.2003': '前回結果',
  'page.inspection.detail.2004': '写真',
  'page.inspection.detail.2005': '作業登録',
  'page.inspection.detail.2006': '異常値',
  'page.inspection.detail.3001': '備考・コメント',
  'page.inspection.detail.3002': '内容',
  'page.inspection.detail.4001': '点検結果',
  'page.inspection.detail.4002': '全て展開',
  'page.inspection.detail.4003': '過去の点検結果を選択して比較',
  'page.inspection.detail.4004': '全て折りたたむ',
  'page.inspection.detail.4005': '過去の結果なし',
  'page.inspection.detail.5001': 'メモ',
  'page.inspection.detail.5002': '閉じる',
  'page.inspection.detail.6001': '確認',
  'page.inspection.detail.6002': '点検名',
  'page.inspection.detail.6003': '実施日',
  'page.inspection.detail.6101': 'を承認しますか？',
  'page.inspection.detail.6102': '承認する',
  'page.inspection.detail.6103': 'キャンセル',
  'page.inspection.detail.6201': 'を差し戻しますか？',
  'page.inspection.detail.6202': '差し戻す',
  'page.inspection.detail.6203': 'キャンセル',
  'page.inspection.detail.7001': '確認',
  'page.inspection.detail.7002': '報告書を出力しますか？',
  'page.inspection.detail.7003': '報告書を書庫に保存しますか？',
  'page.inspection.detail.7101': 'はい',
  'page.inspection.detail.7102': 'いいえ',
  'page.inspection.detail.7103': 'キャンセル',
  'page.inspection.detail.hints.0001': '不具合を作業として\n起票することができます。',
  'page.inspection.edit.0001': '点検実施',
  'page.inspection.edit.0101': '点検完了',
  'page.inspection.edit.0102': '下書き保存',
  'page.inspection.edit.0103': '終了',
  'page.inspection.edit.0201': '確認',
  'page.inspection.edit.0202': '入力内容を保存して提出します。\nよろしいですか？',
  'page.inspection.edit.0203': 'いいえ',
  'page.inspection.edit.0204': 'はい',
  'page.inspection.edit.0301': '確認',
  'page.inspection.edit.0302': '点検結果の編集を終了してもよろしいですか？',
  'page.inspection.edit.0303': '※「下書き保存」されていない変更は保存されません。',
  'page.inspection.edit.0304': 'いいえ',
  'page.inspection.edit.0305': 'はい',
  'page.inspection.edit.1001': '点検表を選択',
  'page.inspection.edit.1002': '点検表',
  'page.inspection.edit.1003': '-- 点検表を選択してください --',
  'page.inspection.edit.1004': '点検開始',
  'page.inspection.edit.1005': 'キャンセル',
  'page.inspection.edit.1006': '点検表を選択して、新たに点検を開始します。',
  'page.inspection.edit.2001': '写真＆メモ',
  'page.inspection.edit.3001': '写真を追加',
  'page.inspection.edit.3002': 'ファイル',
  'page.inspection.edit.3003': 'ファイルを選択',
  'page.inspection.edit.3004': 'ファイルを変更',
  'page.inspection.edit.3005': '[選択可能ファイル] .jpg, .png',
  'page.inspection.edit.3006': 'メモ',
  'page.inspection.edit.3007': '更新',
  'page.inspection.edit.3008': '追加',
  'page.inspection.edit.3009': 'キャンセル',
  'page.inspection.edit.3010': '写真の削除',
  'page.inspection.edit.4001': '値が基準値の範囲を超えています。',
  'page.inspection.edit.4002': '基準値： ',
  'page.inspection.edit.4003': '入力が正しくありません',
  'page.inspection.edit.5001': '異常があった場合にチェックをいれてください。 ',
  'page.inspection.edit.6001': '添付ファイル',
  'page.inspection.edit.6002': '[選択可能ファイル]',
  'page.inspection.edit.6003': '任意のファイル：最大 5 件',
  'page.inspection.edit.6004': 'ファイルサイズの上限：100 MB',
  'page.inspection.edit.6005': '削除',

  'page.inspection.edit.approve.modal.1001': 'コメント',
  'page.inspection.edit.approve.modal.1002': '承認コメントを入力してください',
  'page.inspection.edit.deny.modal.1001': 'コメント',
  'page.inspection.edit.deny.modal.1002': 'コメントを入力してください',
  'page.inspection.edit.inspection.complete.modal.1001': 'コメント',
  'page.inspection.edit.inspection.complete.modal.1002': 'コメントを入力してください',
};

const rscInspectionChecklist = {
  'page.inspection.checklist.list.0001': '点検表一覧',
  'page.inspection.checklist.list.0002': '点検表を検索',
  'page.inspection.checklist.list.0003': '新規作成',
  'page.inspection.checklist.list.0004': 'タイトル、説明で点検表を検索することができます。',
  'page.inspection.checklist.list.0005': '点検表出力',
  'page.inspection.checklist.list.0006': '手入力で作成',
  'page.inspection.checklist.list.1001': '建物名',
  'page.inspection.checklist.list.1002': '点検表名',
  'page.inspection.checklist.list.1003': '点検表の説明',
  'page.inspection.checklist.list.1004': 'ステータス',
  'page.inspection.checklist.list.1005': '建物名',
  'page.inspection.checklist.list.1006': '点検表名',
  'page.inspection.checklist.list.1007': '点検先の定期業務',
  'page.inspection.checklist.list.1008': '報告書フォーマット',
  'page.inspection.checklist.list.1009': '定期業務',
  'page.inspection.checklist.list.1010': '参照中の定期業務数',
  'page.inspection.checklist.list.1011': '設定済み',
  'page.inspection.checklist.list.1999': '登録がありません',
  'page.inspection.checklist.detail.0001': '点検表詳細',
  'page.inspection.checklist.detail.0101': '編集',
  'page.inspection.checklist.detail.0102': '削除',
  'page.inspection.checklist.detail.0103': '報告書フォーマット管理',
  'page.inspection.checklist.detail.1001': 'タイトル',
  'page.inspection.checklist.detail.1002': '説明',
  'page.inspection.checklist.detail.1003': '点検分類',
  'page.inspection.checklist.detail.1004': 'ステータス',
  'page.inspection.checklist.detail.1005': '作成者',
  'page.inspection.checklist.detail.1006': '建物名',
  'page.inspection.checklist.detail.1007': '最終更新',
  'page.inspection.checklist.detail.1008': '報告書フォーマット',
  'page.inspection.checklist.detail.1009': '登録あり',
  'page.inspection.checklist.detail.1010': '登録なし',
  'page.inspection.checklist.detail.2001': '点検表',
  'page.inspection.checklist.detail.2002': '全て展開',
  'page.inspection.checklist.detail.2003': '全て折りたたむ',
  'page.inspection.checklist.detail.3001': '有効な点検項目がありません',
  'page.inspection.checklist.detail.4001': '確認',
  'page.inspection.checklist.detail.4002': '注意：発行済みの点検表を編集しようとしています！',
  'page.inspection.checklist.detail.4004':
    '変更箇所が多い場合は新しい点検表として作成し直すこともご検討ください。',
  'page.inspection.checklist.detail.4003':
    '点検表の内容変更した場合、この点検表に紐づく\n過去の点検結果の表示内容も同様に変更されます。',
  'page.inspection.checklist.detail.4005':
    '発行済みの点検項目は削除できません。\n不要な点検項目は設定で無効化できます。',
  'page.inspection.checklist.detail.4006': '本当に発行済みの点検表を編集しますか？',
  'page.inspection.checklist.detail.4007': 'いいえ',
  'page.inspection.checklist.detail.4008': 'はい',
  'page.inspection.checklist.edit.0001': '点検表登録',
  'page.inspection.checklist.edit.0002': '点検表編集',
  'page.inspection.checklist.edit.0101': '下書き保存',
  'page.inspection.checklist.edit.0102': '発行',
  'page.inspection.checklist.edit.0103': '終了',
  'page.inspection.checklist.edit.0104': '再発行',
  'page.inspection.checklist.edit.0201': '確認',
  'page.inspection.checklist.edit.0202': 'いいえ',
  'page.inspection.checklist.edit.0203': 'はい',
  'page.inspection.checklist.edit.0204': '注意：点検表を発行しようとしています！',
  'page.inspection.checklist.edit.0205': '本当に点検表を発行しますか？',
  'page.inspection.checklist.edit.0206': '点検表を発行する前に以下の点をご確認ください。',
  'page.inspection.checklist.edit.0207':
    '点検表を発行すると、点検実施時にこの点検表を使うことができるようになります。',
  'page.inspection.checklist.edit.0208':
    '一度発行した点検表は自由に編集できなくなります。\n点検表を内容をもう一度よく確認し、設定ミスや項目漏れ等がないかをチェックしてください。',
  'page.inspection.checklist.edit.0301': '確認',
  'page.inspection.checklist.edit.0302': '点検表の編集を終了してもよろしいですか？',
  'page.inspection.checklist.edit.0303': '※「下書き保存」されていない変更は保存されません。',
  'page.inspection.checklist.edit.0304': 'いいえ',
  'page.inspection.checklist.edit.0305': 'はい',
  'page.inspection.checklist.edit.1001': '建物名',
  'page.inspection.checklist.edit.1002': '点検分類',
  'page.inspection.checklist.edit.1003': 'タイトル',
  'page.inspection.checklist.edit.1004': '説明',
  'page.inspection.checklist.edit.1101': '点検表のタイトル',
  'page.inspection.checklist.edit.1102': '点検表の説明（用途など）',
  'page.inspection.checklist.edit.1201': '定期',
  'page.inspection.checklist.edit.1202': '法定',
  'page.inspection.checklist.edit.1203': '日常',
  'page.inspection.checklist.edit.1204': 'その他',
  'page.inspection.checklist.edit.1301': '入力必須項目です',
  'page.inspection.checklist.edit.2001': '点検表 フォーマット',
  'page.inspection.checklist.edit.2002': 'グループを追加する',
  'page.inspection.checklist.edit.2101': 'グループ名を変更する',
  'page.inspection.checklist.edit.2102': 'グループにセクションを追加する',
  'page.inspection.checklist.edit.2103': 'グループを有効化する',
  'page.inspection.checklist.edit.2104': 'グループを無効化する',
  'page.inspection.checklist.edit.2105': 'グループを削除する',
  'page.inspection.checklist.edit.2106': 'グループを上に移動する',
  'page.inspection.checklist.edit.2107': 'グループを下に移動する',
  'page.inspection.checklist.edit.2108': 'グループをコピーする',
  'page.inspection.checklist.edit.2201': 'セクション名を変更する',
  'page.inspection.checklist.edit.2202': 'セクションに点検項目を追加する',
  'page.inspection.checklist.edit.2203': 'セクションを有効化する',
  'page.inspection.checklist.edit.2204': 'セクションを無効化する',
  'page.inspection.checklist.edit.2205': 'セクションを削除する',
  'page.inspection.checklist.edit.2206': 'セクションを上に移動する',
  'page.inspection.checklist.edit.2207': 'セクションを下に移動する',
  'page.inspection.checklist.edit.2208': 'セクションをコピーする',
  'page.inspection.checklist.edit.2301': '対象設備：',
  'page.inspection.checklist.edit.2302': '未選択',
  'page.inspection.checklist.edit.2401': '項目名',
  'page.inspection.checklist.edit.2402': '結果',
  'page.inspection.checklist.edit.2403': '種別',
  'page.inspection.checklist.edit.2501': '点検項目がありません',
  'page.inspection.checklist.edit.2601': '項目設定',
  'page.inspection.checklist.edit.2602': '項目を有効化する',
  'page.inspection.checklist.edit.2603': '項目を無効化する',
  'page.inspection.checklist.edit.2604': '項目を削除する',
  'page.inspection.checklist.edit.2605': '項目を上に移動する',
  'page.inspection.checklist.edit.2606': '項目を下に移動する',
  'page.inspection.checklist.edit.2607': '項目をコピーする',
  'page.inspection.checklist.edit.3001': 'グループ追加',
  'page.inspection.checklist.edit.3002': 'グループ名',
  'page.inspection.checklist.edit.3003': 'グループ名を入力してください',
  'page.inspection.checklist.edit.3004': '最初のセクション名',
  'page.inspection.checklist.edit.3005': 'セクション名を入力してください',
  'page.inspection.checklist.edit.3006': 'キャンセル',
  'page.inspection.checklist.edit.3007': '追加',
  'page.inspection.checklist.edit.3101': 'グループ名変更',
  'page.inspection.checklist.edit.3102': 'グループ名',
  'page.inspection.checklist.edit.3103': 'グループ名を入力してください',
  'page.inspection.checklist.edit.3104': 'キャンセル',
  'page.inspection.checklist.edit.3105': '変更',
  'page.inspection.checklist.edit.3201': 'グループ有効化',
  'page.inspection.checklist.edit.3202': 'グループ無効化',
  'page.inspection.checklist.edit.3203': 'グループ名',
  'page.inspection.checklist.edit.3204': 'を有効化しますか？',
  'page.inspection.checklist.edit.3205': 'を無効化しますか？',
  'page.inspection.checklist.edit.3206':
    '無効化されたグループは\n点検実施時に表示されなくなります。',
  'page.inspection.checklist.edit.3207': 'キャンセル',
  'page.inspection.checklist.edit.3208': '有効化',
  'page.inspection.checklist.edit.3209': '無効化',
  'page.inspection.checklist.edit.3301': 'グループ削除',
  'page.inspection.checklist.edit.3302': 'グループ名',
  'page.inspection.checklist.edit.3303': 'を削除しますか？',
  'page.inspection.checklist.edit.3304': 'キャンセル',
  'page.inspection.checklist.edit.3305': '削除',
  'page.inspection.checklist.edit.4001': 'セクション追加',
  'page.inspection.checklist.edit.4002': 'セクション名',
  'page.inspection.checklist.edit.4003': 'セクション名を入力してください',
  'page.inspection.checklist.edit.4004': 'キャンセル',
  'page.inspection.checklist.edit.4005': '追加',
  'page.inspection.checklist.edit.4101': 'セクション名変更',
  'page.inspection.checklist.edit.4102': 'セクション名',
  'page.inspection.checklist.edit.4103': 'セクション名を入力してください',
  'page.inspection.checklist.edit.4104': 'キャンセル',
  'page.inspection.checklist.edit.4105': '変更',
  'page.inspection.checklist.edit.4201': 'セクション有効化',
  'page.inspection.checklist.edit.4202': 'セクション無効化',
  'page.inspection.checklist.edit.4203': 'セクション名',
  'page.inspection.checklist.edit.4204': 'を有効化しますか？',
  'page.inspection.checklist.edit.4205': 'を無効化しますか？',
  'page.inspection.checklist.edit.4206':
    '無効化されたセクションは\n点検実施時に表示されなくなります。',
  'page.inspection.checklist.edit.4207': 'キャンセル',
  'page.inspection.checklist.edit.4208': '有効化',
  'page.inspection.checklist.edit.4209': '無効化',
  'page.inspection.checklist.edit.4301': 'セクション削除',
  'page.inspection.checklist.edit.4302': 'セクション名',
  'page.inspection.checklist.edit.4303': 'を削除しますか？',
  'page.inspection.checklist.edit.4304': 'キャンセル',
  'page.inspection.checklist.edit.4305': '削除',
  'page.inspection.checklist.edit.4401': '設備選択',
  'page.inspection.checklist.edit.4402': 'キャンセル',
  'page.inspection.checklist.edit.4403': '選択',
  'page.inspection.checklist.edit.4404': 'フロア',
  'page.inspection.checklist.edit.4405': '部屋',
  'page.inspection.checklist.edit.4406': '機器種別',
  'page.inspection.checklist.edit.4407': '設備名',
  'page.inspection.checklist.edit.4408': '型番',
  'page.inspection.checklist.edit.4409': '製造番号',
  'page.inspection.checklist.edit.4410': 'メーカー',
  'page.inspection.checklist.edit.4411': '登録がありません。',
  'page.inspection.checklist.edit.4412': '選択解除',
  'page.inspection.checklist.edit.5001': '項目追加',
  'page.inspection.checklist.edit.5002': '項目名',
  'page.inspection.checklist.edit.5003': '項目名を入力してください',
  'page.inspection.checklist.edit.5004': '種類',
  'page.inspection.checklist.edit.5005': '入力必須にする',
  'page.inspection.checklist.edit.5006': 'キャンセル',
  'page.inspection.checklist.edit.5007': '追加',
  'page.inspection.checklist.edit.5101': '項目設定',
  'page.inspection.checklist.edit.5102': '種類',
  'page.inspection.checklist.edit.5103': '項目名',
  'page.inspection.checklist.edit.5104': '項目名を入力してください',
  'page.inspection.checklist.edit.5105': '入力必須にする',
  'page.inspection.checklist.edit.5106': 'キャンセル',
  'page.inspection.checklist.edit.5107': '変更',
  'page.inspection.checklist.edit.5108': '表示単位',
  'page.inspection.checklist.edit.5109': '基準値範囲',
  'page.inspection.checklist.edit.5110': '最小値',
  'page.inspection.checklist.edit.5111': '最大値',
  'page.inspection.checklist.edit.5112': '～',
  'page.inspection.checklist.edit.5113': '選択肢',
  'page.inspection.checklist.edit.5114': '選択肢名を入力してください',
  'page.inspection.checklist.edit.5115': '選択肢を上に移動する',
  'page.inspection.checklist.edit.5116': '選択肢を下に移動する',
  'page.inspection.checklist.edit.5117': '選択肢を削除する',
  'page.inspection.checklist.edit.5118': '選択肢を追加する',
  'page.inspection.checklist.edit.5119': ' を異常値に設定する',
  'page.inspection.checklist.edit.5120': '基準値範囲が正しくありません。',
  'page.inspection.checklist.edit.5201': '項目有効化',
  'page.inspection.checklist.edit.5202': '項目無効化',
  'page.inspection.checklist.edit.5203': '項目名',
  'page.inspection.checklist.edit.5204': 'を有効化しますか？',
  'page.inspection.checklist.edit.5205': 'を無効化しますか？',
  'page.inspection.checklist.edit.5206': '無効化された項目は\n点検実施時に表示されなくなります。',
  'page.inspection.checklist.edit.5207': 'キャンセル',
  'page.inspection.checklist.edit.5208': '有効化',
  'page.inspection.checklist.edit.5209': '無効化',
  'page.inspection.checklist.edit.5301': '項目削除',
  'page.inspection.checklist.edit.5302': '項目名',
  'page.inspection.checklist.edit.5303': 'を削除しますか？',
  'page.inspection.checklist.edit.5304': 'キャンセル',
  'page.inspection.checklist.edit.5305': '削除',
  'page.inspection.checklist.edit.6001': 'テキスト入力（1行）',
  'page.inspection.checklist.edit.6002': 'テキスト入力（複数行）',
  'page.inspection.checklist.edit.6003': '数値入力',
  'page.inspection.checklist.edit.6004': '項目選択（ラジオボタン）',
  'page.inspection.checklist.edit.6005': '項目選択（コンボボックス）',
  'page.inspection.checklist.edit.6101': '新しいグループ',
  'page.inspection.checklist.edit.6102': '新しいセクション',
  'page.inspection.checklist.edit.6103': '新しい項目',
  'page.inspection.checklist.edit.6201': '選択肢1',
  'page.inspection.checklist.edit.6202': '選択肢2',
  'page.inspection.checklist.edit.6203': '選択肢3',
  'page.inspection.checklist.edit.7001':
    'グループ＞セクション＞点検項目の\n３階層で点検表を作成することができます。',
  'page.inspection.checklist.edit.7002': '入力する数値の単位を設定できます。',
  'page.inspection.checklist.edit.7003':
    '入力値の上限と下限を設定し\n範囲外の値は異常値と判定されるようになります。',
  'page.inspection.checklist.edit.7004':
    'この選択肢を選ぶと\n異常値として判定されるようになります。',
  'page.inspection.checklist.edit.9001': '入力必須項目です',
  'page.inspection.checklist.edit.9002': '入力が正しくありません',
  'page.inspection.checklist.format.0001': '点検報告書フォーマット管理',
  'page.inspection.checklist.format.0002': 'フォーマット登録',
  'page.inspection.checklist.format.0003': 'フォーマット取得',
  'page.inspection.checklist.format.0004': 'フォーマット更新',
  'page.inspection.checklist.format.0005': 'フォーマット削除',
  'page.inspection.checklist.format.1001': 'フォーマット登録手順',
  'page.inspection.checklist.format.1002':
    '点検報告書のフォーマットが定義されている Excel ファイル または Word ファイルを用意します。（.xlsx, .docx）',
  'page.inspection.checklist.format.1003':
    '点検報告書のフォーマットを既に登録している場合は、右上の「フォーマット取得」からファイルをダウンロードしてください。',
  'page.inspection.checklist.format.1004':
    '点検報告書のフォーマットには、以下の表の項目を埋め込むことができます。',
  'page.inspection.checklist.format.1005':
    '点検報告書生成時に、埋め込み文字列を埋め込んだ位置に値が自動的に挿入されます。',
  'page.inspection.checklist.format.1006':
    'Excel 形式で作成する場合、値を表示したいセルに該当の埋め込み文字列を挿入してください。',
  'page.inspection.checklist.format.1007':
    'Word 形式で作成する場合、文章の中の値を表示したい位置に該当の埋め込み文字列を挿入してください。',
  'page.inspection.checklist.format.1008': '項目名',
  'page.inspection.checklist.format.1009': '埋め込む文字列',
  'page.inspection.checklist.format.1010':
    '点検報告書のフォーマットができたら、右上の「フォーマット登録」または「フォーマット更新」からファイルをアップロードします。',
  'page.inspection.checklist.format.1011': '点検報告書フォーマット',
  'page.inspection.checklist.format.1012':
    'アップロード可能なファイル形式は .xlsx, .docx のみです。',
  'page.inspection.checklist.format.1013': 'ファイルサイズが 100MB を超えています。',

  'page.inspection.checklist.bulk_create.0001': '点検表を一括で登録/更新できます。',
  'page.inspection.checklist.bulk_create.0002':
    'ご注意：\n本作業で一括登録できる内容は点検表名、説明、点検分類です。\n点検表ごとの点検項目の作業は、点検表詳細で作成することができます。',
  'page.inspection.checklist.bulk_create.0003': '点検表を一括で登録/更新しますか？',
  'page.inspection.checklist.copy.0001': 'コピー先の建物',
  'page.inspection.checklist.copy.0002': '「下書き」ステータスでコピーが作成されます。',
  'page.inspection.checklist.status.0001':
    '点検表のステータスを下書きから発行済みに\n変更しますか？',
  'page.inspection.checklist.delete.0001': '以下のデータも削除されます。',
  'page.inspection.checklist.delete.0002': '・削除する点検表に登録済みの報告書フォーマット',
  'page.inspection.checklist.delete.0003': '選択した点検表を全て削除しますか？',
};

const rscWork = {
  'page.work.list.0001': '案件一覧',
  'page.work.list.0002': '案件を検索',
  'page.work.list.0003': '新規登録',
  'page.work.list.0101': '完了の非表示',
  'page.work.list.0102': '完了の表示',

  'page.work.list.1001': '対応状況',
  'page.work.list.1002': '優先度',
  'page.work.list.1003': '開始日',
  'page.work.list.1004': '最新更新日時',
  'page.work.list.1005': '期限',
  'page.work.list.1006': '作業分類',
  'page.work.list.1007': '計画名',
  'page.work.list.1008': 'コメント',
  'page.work.list.1009': '最新実施者',
  'page.work.list.1010': '建物名',

  'page.work.list.1999': '登録がありません',
  'page.work.list.2001': '詳細検索',
  'page.work.list.2002': '検索',
  'page.work.list.2003': 'クリア',
  'page.work.list.2101': '計画名',
  'page.work.list.2102': '案件分類',
  'page.work.list.2103': '優先度',
  'page.work.list.2104': '担当者',
  'page.work.list.2105': '対応状況',
  'page.work.list.2106': '承認者',
  'page.work.list.2109': '開始日',
  'page.work.list.2110': '終了日',
  'page.work.list.2111': '期限',

  'page.work.list.2201': 'キーワード（計画名）を入力してください',
  'page.work.list.2301': '終了日の範囲指定が不正です',

  'page.work.edit.operation.menu.0001': '名前を変更',
  'page.work.edit.operation.menu.0002': '削除',
  'page.work.edit.operation.menu.0003': '入力必須項目です',
  'page.work.edit.operation.menu.0004': '対象のファイルが存在しません',
  'page.work.edit.operation.menu.0005': 'ダウンロード',
  'page.work.edit.operation.menu.0101': 'ファイル',
  'page.work.edit.operation.menu.0201': 'コメントを入力してください',

  'page.work.detail.0001': '案件詳細',
  'page.work.detail.0002': '編集',
  'page.work.detail.0003': '削除',
  'page.work.detail.0004': '対応履歴',
  'page.work.detail.0005': 'コメント',
  'page.work.detail.0006': '作業完了',
  'page.work.detail.0007': '差し戻す',
  'page.work.detail.0008': '取り戻す',
  'page.work.detail.0009': '報告書出力',
  'page.work.detail.0101': '基本情報',
  'page.work.detail.0102': '連絡事項',
  'page.work.detail.0103': '対応履歴',
  'page.work.detail.1001': '計画名',
  'page.work.detail.1002': '案件分類',
  'page.work.detail.1003': '優先度',
  'page.work.detail.1004': '対応状況',
  'page.work.detail.1005': '建物名',
  'page.work.detail.1006': '系統名',
  'page.work.detail.1007': '設備名',
  'page.work.detail.1008': 'フロア',
  'page.work.detail.1009': '部屋',
  'page.work.detail.1010': '発生日時',
  'page.work.detail.1011': '発生時刻', //使わないかも
  'page.work.detail.1012': '担当者',
  'page.work.detail.1013': '最終更新',
  'page.work.detail.1014': '説明',
  'page.work.detail.1015': '添付ファイル',
  'page.work.detail.1016': '案件ID',
  'page.work.detail.1100': '未実施',
  'page.work.detail.1101': '対応中',
  'page.work.detail.1102': '査収待ち',
  'page.work.detail.1103': '査収完了',
  'page.work.detail.1104': '保留',

  'page.work.detail.1201': '名前',
  'page.work.detail.1202': '更新日時',
  'page.work.detail.1203': '更新者',
  'page.work.detail.1204': 'ファイルサイズ',

  'page.work.detail.1301': '添付ファイル',
  'page.work.detail.1302': '伝達事項',
  'page.work.detail.1303': 'コピーしました',
  'page.work.detail.1304': 'クリップボードにコピー',
  'page.work.detail.1305': 'コメントをコピー',
  'page.work.detail.1306': '伝達事項の登録がありません',

  'page.work.edit.modal.0001': '伝達事項の編集',
  'page.work.edit.modal.0002': 'キャンセル',
  'page.work.edit.modal.0003': '保存',
  'page.work.edit.modal.0004': '伝達事項',

  'page.work.detail.history.1001': 'コメント',
  'page.work.detail.history.1002': 'あなたが',
  'page.work.detail.history.1003': 'さんが',
  'page.work.detail.history.1004': 'コメントしました',
  'page.work.detail.history.1005': '編集済',
  'page.work.detail.history.1006': '添付ファイル',
  'page.work.detail.history.1007': '対応履歴',
  'page.work.detail.history.1008': '申請',
  'page.work.detail.history.1009': '承認',
  'page.work.detail.history.1010': '差し戻し',
  'page.work.detail.history.1011': '投稿しました',
  'page.work.detail.history.1012': '申請しました',
  'page.work.detail.history.1013': '承認しました',
  'page.work.detail.history.1014': '申請を差し戻ししました',
  'page.work.detail.history.1015': 'コメントの登録がありません',
  'page.work.detail.history.1016': 'に',
  'page.work.detail.history.1100': '実施日時',
  'page.work.detail.history.1101': '対応状況',
  'page.work.detail.history.1102': 'から',
  'page.work.detail.history.1103': 'に変更しました。',
  'page.work.detail.history.1104': '投稿内容',
  'page.work.detail.history.1105': '作業内容',

  'page.work.detail.1017': '期限',
  'page.work.detail.2001': '添付写真',
  'page.work.detail.2002': 'OK',
  'page.work.detail.3001': 'No. ',
  'page.work.detail.3002': '編集',
  'page.work.detail.3101': 'さんが',
  'page.work.detail.3102': 'に投稿',
  'page.work.detail.3103': 'あなたが',
  'page.work.detail.3201': '実施者',
  'page.work.detail.3202': '実施日時',
  'page.work.detail.3203': '作業内容',
  'page.work.detail.3204': 'コメント',
  'page.work.detail.3205': '添付ファイル',
  'page.work.detail.3301': '　～　',
  'page.work.detail.3401': '対応状況',
  'page.work.detail.3402': ' を ',
  'page.work.detail.3403': ' から ',
  'page.work.detail.3404': ' に変更しました。',
  'page.work.detail.3501': 'なし',
  'page.work.detail.5001': '対応履歴を追加',
  'page.work.detail.5002': '対応履歴を編集',
  'page.work.detail.5003': 'コメントを追加',
  'page.work.detail.5004': 'コメントを編集',
  'page.work.detail.5101': '実施者',
  'page.work.detail.5102': '実施日時',
  'page.work.detail.5103': '作業内容',
  'page.work.detail.5104': 'コメント',
  'page.work.detail.5105': '添付写真',
  'page.work.detail.5106': '添付ファイル',
  'page.work.detail.5107': '対応状況',
  'page.work.detail.5111': '作業開始 : ',
  'page.work.detail.5112': '作業終了 : ',
  // 'page.work.detail.5113': '（ 作業を完了し「対応状況」を「確認中」にします ）',
  'page.work.detail.5201': '投稿',
  'page.work.detail.5202': '更新',
  'page.work.detail.5203': 'キャンセル',
  'page.work.detail.5301': '[選択可能ファイル]',
  'page.work.detail.5302': '画像または動画：最大 10 件',
  'page.work.detail.5303': 'ファイルサイズの上限：1件あたり 100MB, \n合計 300 MBまで',
  'page.work.detail.5304': '[選択可能ファイル]',
  'page.work.detail.5305': '任意のファイル：最大 3 件',
  'page.work.detail.5306': 'ファイルサイズの上限：100 MB',
  'page.work.detail.5307': '削除',
  'page.work.detail.6001': '「確認中」の作業を\n「完了」にすることができます。',
  'page.work.detail.6002': '「確認中」の作業を\n「対応中」にすることができます。',
  'page.work.detail.6003': '「完了」の作業を\n「対応中」にすることができます。',
  'page.work.detail.7001': '確認',
  'page.work.detail.7002': '報告書を出力しますか？',
  'page.work.detail.7003': '報告書を書庫に保存しますか？',
  'page.work.detail.7101': 'はい',
  'page.work.detail.7102': 'いいえ',
  'page.work.detail.7103': 'キャンセル',
  'page.work.detail.8001': '作業開始日時より過去の日時が設定されています',
  'page.work.detail.approveModal.0001': 'を承認しますか？',
  'page.work.detail.approveModal.0002': '承認する',
  'page.work.detail.approveModal.0003': 'キャンセル',
  'page.work.detail.approveModal.0004': '計画名:',
  'page.work.detail.denyModal.0001': 'を差し戻しますか？',
  'page.work.detail.denyModal.0002': '差し戻す',
  'page.work.detail.denyModal.0003': 'キャンセル',
  'page.work.detail.denyModal.0004': '計画名:',
  'page.work.detail.denyModal.0005': '差し戻しコメントを入力してください',
  'page.work.detail.takeBackModal.0001': 'を取り戻しますか？',
  'page.work.detail.takeBackModal.0002': '取り戻す',
  'page.work.detail.takeBackModal.0003': 'キャンセル',
  'page.work.detail.takeBackModal.0004': '取り戻しコメントを入力してください',
  'page.work.edit.0001': '案件編集',
  'page.work.edit.0002': '案件登録',
  'page.work.edit.0003': '保存',
  'page.work.edit.0004': '終了',
  'page.work.edit.1001': '計画名',
  'page.work.edit.1002': '案件分類',
  'page.work.edit.1003': '優先度',
  'page.work.edit.1004': '対応状況',
  'page.work.edit.1005': '建物名',
  'page.work.edit.1006': '系統名',
  'page.work.edit.1007': '設備名',
  'page.work.edit.1008': '階数',
  'page.work.edit.1009': '部屋',
  'page.work.edit.1010': '発生日',
  'page.work.edit.1011': '発生時刻',
  'page.work.edit.1012': '担当者',
  'page.work.edit.1013': '説明',
  'page.work.edit.1014': '添付ファイル',
  'page.work.edit.1015': '期限',
  'page.work.edit.1016': '設備未選択',
  'page.work.edit.2001': '添付写真',
  'page.work.edit.2002': '[選択可能ファイル]',
  'page.work.edit.2003': '画像または動画：最大 10 件',
  'page.work.edit.2004': 'ファイルサイズの上限：1件あたり 100MB, 合計 300 MBまで',
  'page.work.edit.2005': '削除',
  'page.work.edit.2006': 'メモ',
  'page.work.edit.2007': 'メモの内容を記入してください。',
  'page.work.edit.2008': 'OK',
  'page.work.edit.2009': 'キャンセル',
  'page.work.edit.3001': '添付ファイル',
  'page.work.edit.3002': '[選択可能ファイル]',
  'page.work.edit.3003': '任意のファイル：最大 5 件',
  'page.work.edit.3004': 'ファイルサイズの上限：100 MB',
  'page.work.edit.3005': '削除',
  'page.work.edit.4001': '系統名を選択し、設備を絞り込むことができます。',
};

const rscConstruction = {
  'page.construction.0001': '工事詳細',
  'page.construction.0002': '報告書提出',
  'page.construction.0003': '報告書登録',
  'page.construction.0004': '報告書編集',
  'page.construction.0005': '承認',
  'page.construction.0006': '差し戻す',
  'page.construction.1001': '計画名',
  'page.construction.1002': '建物名',
  'page.construction.1003': '対応状況',
  'page.construction.1004': '監督者',
  'page.construction.1005': '作業者',
  'page.construction.1006': '特記事項',
  'page.construction.1007': '報告書提出先URL',
  'page.construction.1008': '報告書提出先URLをコピー',
  'page.construction.1009': 'コピーしました',
  'page.construction.1010': 'クリップボードにコピー',
  'page.construction.1011': '報告書提出先URLを正常に取得できませんでした。',
  'page.construction.2001': '登録者',
  'page.construction.2002': '登録日時',
  'page.construction.2003': '実施内容',
  'page.construction.2004': '報告書',
  'page.construction.2005': '報告書提出ボタンを押すと、自動で入力されます',
  'page.construction.2006': '報告書データをアップロードしないと報告書の提出ができません。',
  'page.construction.2007': '必ず添付ファイルを登録してください。',
  'page.construction.2008': '該当のデータがありません',
  'page.construction.3001': '報告書登録',
  'page.construction.3002': '[選択可能ファイル]',
  'page.construction.3003': 'ファイル：最大 5 件',
  'page.construction.3004': 'ファイルサイズの上限：1件あたり 100MB, 合計 300 MBまで',
  'page.construction.3005': '削除',
  'page.construction.3006': '添付ファイル',
  'page.construction.3101': 'キャンセル',
  'page.construction.3102': '登録',
  'page.construction.4001': '報告書提出コメントを追加',
  'page.construction.4002': '報告書提出のコメントを入力してください',
  'page.construction.4101': 'を承認しますか？',
  'page.construction.4102': '承認する',
  'page.construction.4103': 'キャンセル',
  'page.construction.4201': 'を差し戻しますか？',
  'page.construction.4202': '差し戻す',
  'page.construction.4203': 'キャンセル',
  'page.construction.4301': 'コメント',
  'page.construction.4302': '提出',
  'page.construction.4303': 'キャンセル',
  'page.construction.5001': '基本情報',
  'page.construction.5002': '連絡事項',
  'page.construction.5003': 'コメント',
  'page.construction.5201': '添付ファイル',
  'page.construction.5202': '伝達項目',
  'page.construction.5203': 'コピーしました',
  'page.construction.5204': 'クリップボードにコピー',
  'page.construction.5205': 'コメントをコピー',
  'page.construction.5206': '伝達事項の登録がありません',
  'page.construction.5301': 'コメント',
  'page.construction.5302': 'コメント',
  'page.construction.5303': 'コメント',
  'page.construction.5304': '申請',
  'page.construction.5305': '対応状況',
  'page.construction.5306': '承認',
  'page.construction.5307': '添付ファイル',
  'page.construction.5309': '差し戻し',
  'page.construction.5310': '対応状況',
  'page.construction.5311': '投稿内容',
  'page.construction.5312': 'コメントを登録',
  'page.construction.5313': 'キャンセル',
  'page.construction.5314': 'コメント',
  'page.construction.5315': 'コメント',
  'page.construction.5316': 'コメントの編集',
  'page.construction.5317': 'キャンセル',
  'page.construction.5318': '登録',
  'page.construction.5319': 'コメント',
  'page.construction.5320': '報告書',
  'page.construction.5321': '添付ファイル',
  'page.construction.5322': '[選択可能ファイル]',
  'page.construction.5323': 'ファイル：最大 5 件',
  'page.construction.5324': 'ファイルサイズの上限：1件あたり 100MB, 合計 300 MBまで',
  'page.construction.5325': '削除',
  'page.construction.5326': 'いいえ',
  'page.construction.5327': 'はい',
  'page.construction.5328': '削除しますか？？',
  'page.construction.5329': 'さんが',
  'page.construction.5330': 'にコメントしました。',
  'page.construction.5331': 'に申請しました。',
  'page.construction.5332': 'に承認しました。',
  'page.construction.5333': 'に差し戻ししました。',
  'page.construction.5334': 'コメントが登録されていません。',
  'page.construction.5335': 'コメントの登録がありません。',
  'page.construction.5336': 'あなたが',
  'page.construction.5337': '編集済',
  'page.construction.5338': '投稿内容',
  'page.construction.5339': '対応状況',
  'page.construction.5340': 'から',
  'page.construction.5341': 'に変更しました。',
  'page.construction.alert.0001': '対象の工事情報の登録がありません。',
  'page.construction.edit.modal.0001': '伝達事項の編集',
  'page.construction.edit.modal.0002': 'キャンセル',
  'page.construction.edit.modal.0003': '保存',
  'page.construction.edit.modal.0004': '伝達事項',
  'page.construction.edit.modal.1001': '承認コメントを追加',
  'page.construction.edit.modal.1002': 'キャンセル',
  'page.construction.edit.modal.1003': '承認',
  'page.construction.edit.modal.1004': 'コメント',
  'page.construction.edit.modal.1005': '承認コメントを入力してください',
  'page.construction.edit.modal.2001': '差し戻しコメントを追加',
  'page.construction.edit.modal.2002': 'キャンセル',
  'page.construction.edit.modal.2003': '差し戻す',
  'page.construction.edit.modal.2004': 'コメント',
  'page.construction.edit.modal.2005': 'コメントを入力してください',
  'page.construction.comment.modal.1001': 'コメントを入力してください',
  'page.construction.comment.modal.1002': 'ファイル名が重複しています。',
  'page.construction.comment.modal.1003': 'ファイルサイズが {0}MB を超えています',
  'page.construction.memo.modal.0001': 'メモ',
  'page.construction.memo.modal.0002': 'メモの内容を記入してください。',
  'page.construction.memo.modal.0003': 'OK',
  'page.construction.memo.modal.0004': 'キャンセル',
  'page.construction.edit.operation.menu.0001': '名前を変更',
  'page.construction.edit.operation.menu.0002': 'ダウンロード',
  'page.construction.edit.operation.menu.0003': '削除',
  'page.construction.edit.operation.menu.0004': '入力必須項目です',
  'page.construction.edit.operation.menu.0005': '対象のファイルが存在しません',
  'page.construction.edit.operation.menu.0101': 'ファイル名',
  'page.construction.edit.operation.menu.0201': 'コメントを入力してください',
  'page.construction.0501': '指定されたURLは有効期限が切れているか、不正なURLです。',
  'page.construction.list.0001': '工事一覧',
  'page.construction.list.0002': '作業を検索',
  'page.construction.list.1001': '完了の非表示',
  'page.construction.list.1002': '完了の表示',
  'page.construction.list.1003': '対応状況',
  'page.construction.list.1004': '開始日',
  'page.construction.list.1005': '最新更新実施者',
  'page.construction.list.1006': '最新更新日時',
  'page.construction.list.1007': '建物名',
  'page.construction.list.2001': '対応状況',
  'page.construction.list.2002': '開始日',
  'page.construction.list.2003': '計画名',
  'page.construction.list.2004': '最新実施者',
  'page.construction.list.2005': 'コメント',
  'page.construction.list.2006': '最新更新日時',
  'page.construction.list.2007': '建物名',
  'page.construction.list.3001': '詳細検索',
  'page.construction.list.3002': 'クリア',
  'page.construction.list.3003': '検索',
  'page.construction.list.3004': '計画名',
  'page.construction.list.3005': '対応状況',
  'page.construction.list.3006': '監督者',
  'page.construction.list.3007': '作業者',
  'page.construction.list.3008': '協力会社',
  'page.construction.list.3009': '承認者',
  'page.construction.list.3010': '開始日',
  'page.construction.list.3011': '終了日',
  'page.construction.list.3012': '終了日の範囲指定が不正です',
  'page.construction.list.4001': '登録がありません',
  'page.construction.list.5001': '未確定',
  'page.construction.list.5002': '確定',
  'page.construction.list.5003': '実施中',
  'page.construction.list.5004': '査収待ち',
  'page.construction.list.5005': '査収完了',
  'page.construction.list.5006': '案件保留',
};

const rscPlanCommon = {
  'page.plan.table.common.0001': ':',
  'page.plan.table.common.0002': '件',
  'page.plan.table.common.0003': '円',
  'page.plan.table.common.0004': '%',
  'page.plan.table.common.0005': '月',
  'page.plan.table.common.0006': '終日',
  'page.plan.common.search.title': '詳細検索',
  'page.plan.common.search.0001': '計画名',
  'page.plan.common.search.0002': '契約種別',
  'page.plan.common.search.0003': '定期業務種別',
  'page.plan.common.search.0004': '定期業務区分',
  'page.plan.common.search.0005': '対応状況',
  'page.plan.common.search.0006': '協力会社名',
  'page.plan.common.search.0007': '開始日',
  'page.plan.common.search.0008': '終了日',
  'page.plan.common.search.0009': '監督者',
  'page.plan.common.search.0010': '作業者',
  'page.plan.common.search.0011': '予定調整状況',
  'page.plan.common.search.0012': '確定連絡状況',
  'page.plan.common.search.0013': '再連絡状況',
  'page.plan.common.search.0014': '報告提出依頼',
  'page.plan.common.search.0015': '請求額登録状況',
  'page.plan.common.search.0016': '支払額登録状況',
  'page.plan.common.contract_type.0001': '定期業務',
  'page.plan.common.contract_type.0002': '工事',
  'page.plan.common.inspection_type.0001': '定期',
  'page.plan.common.inspection_type.0002': '法定',
  'page.plan.common.inspection_type.0003': '日常',
  'page.plan.common.inspection_type.0004': 'その他',
  'page.plan.common.inspection_division.0001': '設備',
  'page.plan.common.inspection_division.0002': '清掃',
  'page.plan.common.inspection_division.0003': '警備',
  'page.plan.common.inspection_division.0004': '検針',
  'page.plan.common.inspection_division.0005': '建築',
  'page.plan.common.inspection_division.0006': 'その他',
  'page.plan.common.status.0001': '未確定',
  'page.plan.common.status.0002': '確定',
  'page.plan.common.status.0003': '対応中',
  'page.plan.common.status.0004': '査収待ち',
  'page.plan.common.status.0005': '査収完了',
  'page.plan.common.status.0006': '保留',
  'page.plan.common.2001': 'クリア',
  'page.plan.common.2002': '検索',
  'page.plan.common.search.schedule.placeholder': '年次予定を検索',
  'page.plan.common.search.table.placeholder': '計画集計を検索',
  'page.plan.common.search.monthly_schedule.placeholder': '月次予定を検索',
  'page.plan.common.search.monthly_table.placeholder': '月次計画を検索',
};

const rscPlanAnnual = {
  'page.plan.annual.schedule.title': '年次計画',
  'page.plan.annual.table.title': '年次計画',
  'page.plan.annual.sales_summary.0001': '売上',
  'page.plan.annual.sales_summary.0002': '粗利',
  'page.plan.annual.sales_summary.0003': '粗利率',
  'page.plan.annual.sales_summary.1001': '前年実績',
  'page.plan.annual.sales_summary.1002': '販売結果',
  'page.plan.annual.sales_summary.1003': '販売実績の伸長率',
  'page.plan.annual.schedule.sort.0001': '建物名',
  'page.plan.annual.schedule.sort.0002': '契約種別',
  'page.plan.annual.schedule.sort.0003': '定期業務区分',
  'page.plan.annual.schedule.sort.0004': '定期業務種別',
  'page.plan.annual.schedule.sort.0005': '計画名',
  'page.plan.annual.table.sort.0001': '建物名',
  'page.plan.annual.table.sort.0002': '契約種別',
  'page.plan.annual.table.sort.0003': '定期業務区分',
  'page.plan.annual.table.sort.0004': '定期業務種別',
  'page.plan.annual.table.sort.0005': '計画名',
  'page.plan.annual.table.sort.0006': '月',
  'page.plan.annual.schedule.export': '年次計画出力',
  'page.plan.annual.table.export': '集計表出力',
  'page.plan.annual.schedule.property.0001': '建物名',
  'page.plan.annual.schedule.property.0002': '契約種別',
  'page.plan.annual.schedule.property.0003': '定義業務区分',
  'page.plan.annual.schedule.property.0004': '計画名',
  'page.plan.annual.schedule.property.0005': '定期業務種別',
  'page.plan.annual.schedule.property.0006': '周期',
  'page.plan.annual.schedule.property.0007': 'カスタム設定',
  'page.plan.annual.table.property.0001': '建物名',
  'page.plan.annual.table.property.0002': '計画完了月',
  'page.plan.annual.table.property.0003': '契約種別',
  'page.plan.annual.table.property.0004': '定期業務区分',
  'page.plan.annual.table.property.0005': '定期業務種別',
  'page.plan.annual.table.property.0006': '計画名',
  'page.plan.annual.table.property.0007': '販売結果',
  'page.plan.annual.table.property.0008': '請求額',
  'page.plan.annual.table.property.0009': '支払額',
  'page.plan.annual.table.property.0010': '粗利額',
  'page.plan.annual.table.property.0011': '粗利率',
  'page.plan.annual.property.nodata': '登録がありません',
  'page.plan.annual.month.0001': '1',
  'page.plan.annual.month.0002': '2',
  'page.plan.annual.month.0003': '3',
  'page.plan.annual.month.0004': '4',
  'page.plan.annual.month.0005': '5',
  'page.plan.annual.month.0006': '6',
  'page.plan.annual.month.0007': '7',
  'page.plan.annual.month.0008': '8',
  'page.plan.annual.month.0009': '9',
  'page.plan.annual.month.0010': '10',
  'page.plan.annual.month.0011': '11',
  'page.plan.annual.month.0012': '12',
};

const rscPlanMonthly = {
  'page.plan.monthly.schedule.title': '月次計画',
  'page.plan.monthly.table.title': '月次計画',
  'page.plan.monthly.tab.0001': '予定表',
  'page.plan.monthly.tab.0002': '計画管理',
  'page.plan.monthly.sales_summary.0001': '売上',
  'page.plan.monthly.sales_summary.0002': '粗利',
  'page.plan.monthly.sales_summary.0003': '粗利率',
  'page.plan.monthly.sales_summary.1001': '前年実績',
  'page.plan.monthly.sales_summary.1002': '販売結果',
  'page.plan.monthly.sales_summary.1003': '販売実績の伸長率',
  'page.plan.monthly.schedule.sort.0001': '建物名',
  'page.plan.monthly.schedule.sort.0002': '契約種別',
  'page.plan.monthly.schedule.sort.0003': '定期業務区分',
  'page.plan.monthly.schedule.sort.0004': '定期業務種別',
  'page.plan.monthly.schedule.sort.0005': '計画名',
  'page.plan.monthly.table.sort.0001': '建物名',
  'page.plan.monthly.table.sort.0002': '契約種別',
  'page.plan.monthly.table.sort.0003': '定期業務区分',
  'page.plan.monthly.table.sort.0004': '定期業務種別',
  'page.plan.monthly.table.sort.0005': '計画名',
  'page.plan.monthly.schedule.export': 'カレンダー出力',
  'page.plan.monthly.table.export': 'CSV出力',
  'page.plan.monthly.table.export.0001': '選択した項目をダウンロード',
  'page.plan.monthly.table.export.0002': 'すべてをダウンロード',
  'page.plan.monthly.table.add': '追加',
  'page.plan.monthly.table.cancel': 'キャンセル',
  'page.plan.monthly.table.edit': '編集',
  'page.plan.monthly.table.save': '保存',
  'page.plan.monthly.schedule.property.0001': '建物名',
  'page.plan.monthly.schedule.property.0002': '計画名',
  'page.plan.monthly.table.property.0001': '建物名',
  'page.plan.monthly.table.property.0002': '計画名',
  'page.plan.monthly.table.property.0003': '契約種別',
  'page.plan.monthly.table.property.0004': '定期業務区分',
  'page.plan.monthly.table.property.0005': '定期業務種別',
  'page.plan.monthly.table.property.0006': '対応状況',
  'page.plan.monthly.table.property.0007': '開始日',
  'page.plan.monthly.table.property.0008': '開始時間',
  'page.plan.monthly.table.property.0009': '終了日',
  'page.plan.monthly.table.property.0010': '終了時間',
  'page.plan.monthly.table.property.0011': '監督者',
  'page.plan.monthly.table.property.0012': '作業者',
  'page.plan.monthly.table.property.0013': '協力会社名',
  'page.plan.monthly.table.property.0014': '予定調整先',
  'page.plan.monthly.table.property.0015': '作業メモ',
  'page.plan.monthly.table.property.0016': '自動メール送信状況',
  'page.plan.monthly.table.property.0017': '予定調整',
  'page.plan.monthly.table.property.0018': '確定連絡',
  'page.plan.monthly.table.property.0019': '事前連絡',
  'page.plan.monthly.table.property.0020': '報告依頼',
  'page.plan.monthly.table.property.0021': '契約詳細で設定された予定日時',
  'page.plan.monthly.table.property.0022': '開始日',
  'page.plan.monthly.table.property.0023': '開始時間',
  'page.plan.monthly.table.property.0024': '終了日',
  'page.plan.monthly.table.property.0025': '終了時間',
  'page.plan.monthly.table.property.0026': '販売結果',
  'page.plan.monthly.table.property.0027': '請求額',
  'page.plan.monthly.table.property.0028': '支払額',
  'page.plan.monthly.table.property.0029': '粗利額',
  'page.plan.monthly.table.property.0030': '粗利率',
  'page.plan.monthly.property.nodata': '登録がありません',
  'page.plan.monthly.table.add.title': '計画追加',
  'page.plan.monthly.table.add.0001': '建物',
  'page.plan.monthly.table.add.0002': '契約',
  'page.plan.monthly.table.add.0003': '契約を選択する',
  'page.plan.monthly.table.add.0004': '対応状況',
  'page.plan.monthly.table.add.0005': '開始日',
  'page.plan.monthly.table.add.0006': '開始時刻',
  'page.plan.monthly.table.add.0007': '終了日',
  'page.plan.monthly.table.add.0008': '終了時刻',
  'page.plan.monthly.table.menu.0001': '対応状況を確定にする',
  'page.plan.monthly.table.menu.0002': '対応状況を未確定にする',
  'page.plan.monthly.table.menu.1001': '監督者を更新する',
  'page.plan.monthly.table.menu.1002': '作業者を更新する',
  'page.plan.monthly.table.menu.1003': '協力会社を更新する',
  'page.plan.monthly.table.menu.2001': '販売結果に請求額を登録する',
  'page.plan.monthly.table.menu.2002': '販売結果に支払額を登録する',
  'page.plan.monthly.table.menu.3001': '予定調整メールを送信する',
  'page.plan.monthly.table.menu.3002': '予定確定メールを送信する',
  'page.plan.monthly.table.menu.3003': '予定直前メールを設定する',
  'page.plan.monthly.table.menu.3004': '報告書提出依頼メールを設定する',
  'page.plan.monthly.table.menu.4001': '予定調整先を登録する',
  'page.plan.monthly.table.menu.4002': '予定調整先を削除する',
  'page.plan.monthly.table.menu.5001': '選択項目を削除する',
  'page.plan.monthly.table.delete.0001': '確認',
  'page.plan.monthly.table.delete.0002': '選択した予定を削除しますか？',
  'page.plan.monthly.table.delete.0003': '注意:削除後は元に戻せません。',
  'page.plan.monthly.table.delete.0004': 'いいえ',
  'page.plan.monthly.table.delete.0005': 'はい',
  'page.plan.monthly.table.status.0001': '確認',
  'page.plan.monthly.table.status.0002': '確定に更新しますか?',
  'page.plan.monthly.table.status.0003': '未確定に更新しますか?',
  'page.plan.monthly.table.status.0004': 'キャンセル',
  'page.plan.monthly.table.status.0005': '更新する',
  'page.plan.monthly.table.edit.confirm':
    '建物担当者に登録されていないユーザを建物スタッフとして登録しますか?',
  'page.plan.monthly.table.delete.adjustments': '選択した計画の予定調整先を削除しますか？',
  'page.plan.monthly.sales_result.invoice_amount': '請求額',
  'page.plan.monthly.sales_result.payment_amount': '支払額',
  'page.plan.monthly.sales_result.title_suffix': 'を登録する',
  'page.plan.monthly.sales_result.placeholder_suffix': 'を入力してください',
  'page.plan.monthly.sales_result.cancel': 'キャンセル',
  'page.plan.monthly.sales_result.save': '登録',
  'page.plan.monthly.table.mail.0001': '予定調整メール送信',
  'page.plan.monthly.table.mail.0002': '予定確定メール送信',
  'page.plan.monthly.table.mail.0003': '予定直前メール設定',
  'page.plan.monthly.table.mail.0004': '報告書提出依頼メール設定',
  'page.plan.monthly.table.mail.0005': '予定調整先の確認 1/3',
  'page.plan.monthly.table.mail.0006': '伝達事項の記入 2/3',
  'page.plan.monthly.table.mail.0007': '送信内容の確認 3/3',
  'page.plan.monthly.table.mail.0008':
    '未確定の予定にシステムが予定調整先に予定調整メールを送信する機能です。',
  'page.plan.monthly.table.mail.0009':
    '予定調整メールを送信する計画、連絡先、日時、連絡事項などを確認してください。',
  'page.plan.monthly.table.mail.0010': '＜戻る',
  'page.plan.monthly.table.mail.0011': '次へ＞',
  'page.plan.monthly.table.mail.0012':
    '予定調整メールの本文で送信先に伝えたい伝達事項を記入してください。',
  'page.plan.monthly.table.mail.0013': '作成した文書は次の工程でプレビューとして確認できます。',
  'page.plan.monthly.table.mail.0014': '送信する予定調整メールの内容を確認してください。',
  'page.plan.monthly.table.mail.0015':
    '送信先が複数の場合は送信先を切り替えて内容をご確認ください。',
  'page.plan.monthly.table.mail.0016': '送信',
  'page.plan.monthly.table.mail.0017': '本文のリセット',
  'page.plan.monthly.table.mail.0018': '次回以降も同じメールで本文を利用する',
  'page.plan.monthly.table.mail.0019': '伝達事項を入力してください',
  'page.plan.monthly.table.mail.0020': '送信先がありませんが、操作を継続しますか？',
  'page.plan.monthly.table.mail.0021': '送信先',
  'page.plan.monthly.table.mail.0022': '<',
  'page.plan.monthly.table.mail.0023': '>',
  'page.plan.monthly.table.mail.0024': '送信内容の取得時に問題が起きました',
  'page.plan.monthly.table.mail.table.0001': '送信先情報',
  'page.plan.monthly.table.mail.table.0002': '定期業務情報',
  'page.plan.monthly.table.mail.table.0003': '候補日時',
  'page.plan.monthly.table.mail.table.0004': '区分',
  'page.plan.monthly.table.mail.table.0005': '企業名',
  'page.plan.monthly.table.mail.table.0006': '拠点',
  'page.plan.monthly.table.mail.table.0007': '所属',
  'page.plan.monthly.table.mail.table.0008': '役職',
  'page.plan.monthly.table.mail.table.0009': '氏名',
  'page.plan.monthly.table.mail.table.0010': 'メールアドレス',
  'page.plan.monthly.table.mail.table.0011': '建物名',
  'page.plan.monthly.table.mail.table.0012': '計画名',
  'page.plan.monthly.table.mail.table.0013': '開始日',
  'page.plan.monthly.table.mail.table.0014': '開始時刻',
  'page.plan.monthly.table.mail.table.0015': '終了日',
  'page.plan.monthly.table.mail.table.0016': '終了時刻',
  'page.plan.monthly.table.mail.table.0017': '連絡事項',
  'page.plan.monthly.table.mail.table.0101': '協力会社',
  'page.plan.monthly.table.mail.table.0102': 'ユーザー',
  'page.plan.monthly.table.mail.preview.table.0001': '送信元メールアドレス',
  'page.plan.monthly.table.mail.preview.table.0002': '件名',
  'page.plan.monthly.table.mail.preview.table.0003': '本文',
  'page.plan.monthly.table.mail.preview.table.0004': '送信先',
  'page.plan.monthly.table.mail.preview.table.0005': '送信元',
  'page.plan.monthly.table.mail.preview.table.0006': '予定調整先：',
  'page.plan.monthly.table.mail.preview.table.0007': '様',
  'page.plan.monthly.table.mail.preview.table.0008': '予定調整元：',
  'page.plan.monthly.table.mail.preview.table.0009': '伝達事項',
  'page.plan.monthly.table.mail.preview.table.0010': '予定調整URL',
  'page.plan.monthly.table.mail.preview.table.0011': '備考',
  'page.plan.monthly.table.mail.preview.table.0012': '該当ページへのURLリンクが付与されます。',
  'page.plan.monthly.table.mail.preview.table.0201': '注記',
  'page.plan.monthly.table.fixed.mail.status.0001': '連絡先の確認 1/3',
  'page.plan.monthly.table.fixed.mail.status.0002': '伝達事項の編集 2/3',
  'page.plan.monthly.table.fixed.mail.status.0003': '送信内容の確認 3/3',
  'page.plan.monthly.table.fixed.mail.table.0001':
    '未確定または確定の予定に予定日時と計画の詳細をシステムがメールで通知する機能です。',
  'page.plan.monthly.table.fixed.mail.table.0002':
    '予定確定メールを送信する計画、連絡先、日時、連絡事項などを確認してください。',
  'page.plan.monthly.table.fixed.mail.message.0001':
    '予定確定メールの本文で送信先に伝えたい伝達事項を記入してください。',
  'page.plan.monthly.table.fixed.mail.message.0002':
    '作成頂いたメール本文は次のページで確認いただけます。',
  'page.plan.monthly.table.fixed.mail.message.0003': '送信する予定確認メールを確認してください。',
  'page.plan.monthly.table.fixed.mail.message.0004':
    '送信先が複数の場合は送信先を切り替えて内容をご確認ください。',
  'page.plan.monthly.table.fixed.mail.message.0005': '送信できませんでした',
  'page.plan.monthly.table.fixed.mail.confirmation.0001': '送信内容の取得時に問題が起きました。',
  'page.plan.monthly.table.fixed.mail.confirmation.0002': '計画',
  'page.plan.monthly.table.fixed.mail.confirmation.0003': '開始日時',
  'page.plan.monthly.table.fixed.mail.confirmation.0004': '終了日時',
  'page.plan.monthly.table.fixed.mail.confirmation.0005': '建物名',
  'page.plan.monthly.table.fixed.mail.confirmation.0006': '郵便番号',
  'page.plan.monthly.table.fixed.mail.confirmation.0007': '住所',
  'page.plan.monthly.table.fixed.mail.confirmation.0008': '計画名',
  'page.plan.monthly.table.fixed.mail.confirmation.0009': '連絡事項',
  'page.plan.monthly.table.fixed.mail.confirmation.0010': '施設の伝達事項',
  'page.plan.monthly.table.fixed.mail.confirmation.0011': '点検の伝達事項',
  'page.plan.monthly.table.fixed.mail.confirmation.0012': '作業メモ',
  'page.plan.monthly.table.fixed.mail.confirmation.0013': '報告書',
  'page.plan.monthly.table.fixed.mail.confirmation.0014': '報告書の提出先',
  'page.plan.monthly.table.remind.mail.status.0001': '連絡先の確認 1/3',
  'page.plan.monthly.table.remind.mail.status.0002': '通知設定 2/3',
  'page.plan.monthly.table.remind.mail.status.0003': '送信内容の確認 3/3',
  'page.plan.monthly.table.remind.mail.comment.0001':
    '確定した予定に任意の日付で、システムが予定の事前連絡をメールで再通知する機能です。',
  'page.plan.monthly.table.remind.mail.comment.0002':
    '予定直前メールを設定する計画を確認してください。',
  'page.plan.monthly.table.remind.mail.notification.0001': '予定直前メールの設定を行います。',
  'page.plan.monthly.table.remind.mail.notification.0002': '以下に沿って設定してください。',
  'page.plan.monthly.table.remind.mail.notification.0003': '事前通知設定',
  'page.plan.monthly.table.remind.mail.notification.0004': '通知を設定する',
  'page.plan.monthly.table.remind.mail.notification.0005': '通知を設定しない/解除する',
  'page.plan.monthly.table.remind.mail.notification.0006': '事前通知予定日',
  'page.plan.monthly.table.remind.mail.notification.0007': '計画開始日の',
  'page.plan.monthly.table.remind.mail.notification.0008': '日前の計画',
  'page.plan.monthly.table.remind.mail.notification.0009': '通知は予定日の午前7時に行われます。',
  'page.plan.monthly.table.remind.mail.notification.0010': '正常にデータを取得できませんでした',
  'page.plan.monthly.table.remind.mail.notification.0011': '計画開始日から',
  'page.plan.monthly.table.remind.mail.table.0001': '建物名',
  'page.plan.monthly.table.remind.mail.table.0002': '計画名',
  'page.plan.monthly.table.remind.mail.table.0003': '契約種別',
  'page.plan.monthly.table.remind.mail.table.0004': '定期業務区分',
  'page.plan.monthly.table.remind.mail.table.0005': '定期業務種別',
  'page.plan.monthly.table.remind.mail.table.0006': '対応状況',
  'page.plan.monthly.table.remind.mail.table.0007': '開始日',
  'page.plan.monthly.table.remind.mail.table.0008': '開始時間',
  'page.plan.monthly.table.remind.mail.table.0009': '終了日',
  'page.plan.monthly.table.remind.mail.table.0010': '終了時間',
  'page.plan.monthly.table.remind.mail.table.0011': '協力会社',
  'page.plan.monthly.table.remind.mail.message.0001': '設定した予定直前メールを確認してください。',
  'page.plan.monthly.table.remind.mail.message.0002':
    '[]で表記されている項目は、計画や送信先ごとに自動で入力されます。',
  'page.plan.monthly.table.remind.mail.message.0003':
    'メール送信時、計画項目は増減する可能性があります',
  'page.plan.monthly.table.report.request.mail.comment.0001':
    '確定した予定に任意の日付で、システムが報告書提出依頼メールを送信する機能です。',
  'page.plan.monthly.table.report.request.mail.comment.0002':
    '報告書提出依頼メールの設定を行います。',
  'page.plan.monthly.table.report.request.mail.comment.0003': '以下に沿って設定してください。',
  'page.plan.monthly.table.report.request.mail.comment.0004': '通知は予定日の午前7時に行われます。',
  'page.plan.monthly.table.report.request.mail.comment.0005':
    '設定した報告書提出依頼メールを確認してください。',
  'page.plan.monthly.table.report.request.mail.notification.0002': '以下に沿って設定してください。',
  'page.plan.monthly.table.report.request.mail.notification.0003': '事前通知設定',
  'page.plan.monthly.table.report.request.mail.notification.0004': '通知を設定する',
  'page.plan.monthly.table.report.request.mail.notification.0005': '通知を設定しない/解除する',
  'page.plan.monthly.table.report.request.mail.notification.0006': '事前通知予定日',
  'page.plan.monthly.table.report.request.mail.notification.0007': '計画開始日の',
  'page.plan.monthly.table.report.request.mail.notification.0008': '日後の計画',
  'page.plan.monthly.table.report.request.mail.notification.0009': '通知時間',
  'page.plan.monthly.table.report.request.mail.notification.0010': '日以上経過したすべての計画',
  'page.plan.monthly.table.report.request.mail.notification.0011':
    '指定した日付から対応状況が査収待ちになるまで毎日メールを送信します',
  'page.plan.monthly.table.report.request.mail.notification.0012':
    '指定した日付に一度だけメール送信します',
  'page.plan.monthly.table.report.request.mail.notification.0013':
    '正常にデータを取得できませんでした',
};

const rscPlanDaily = {
  'page.plan.daily.schedule.title': '日次計画',
  'page.plan.daily.schedule.sort.0001': '建物名',
  'page.plan.daily.schedule.sort.0002': '計画名',
  'page.plan.daily.schedule.sort.0003': '開始時刻',
  'page.plan.daily.schedule.sort.0004': '終了時刻',
  'page.plan.daily.schedule.export': 'カレンダー出力',
  'page.plan.daily.schedule.property.0001': '建物名',
  'page.plan.daily.schedule.property.0002': '計画名',
  'page.plan.daily.property.nodata': '登録がありません',
};

const rscPlan = {
  'page.plan.tab.0001': '予定表',
  'page.plan.tab.0002': '計画集計',
  'page.plan.common.0001': '全',
  'page.plan.common.0002': '件中',
  'page.plan.common.0003': '件',
  'page.plan.common.0004': '要確定',
  'page.plan.common.0005': '計画済み',
  'page.plan.common.0006': '遅延',
  'page.plan.common.0101': '年',
  'page.plan.common.0102': '月',
  'page.plan.common.0103': '日',
  'page.plan.common.0104': '年次',
  'page.plan.common.0105': '月次',
  'page.plan.common.0106': '日次',
  'page.plan.common.0107': '毎日',
  'page.plan.common.0108': '今年',
  'page.plan.common.0109': '今月',
  'page.plan.common.0110': '今日',
  'page.plan.common.0201': '建物',
  'page.plan.common.0202': '種類',
  'page.plan.common.0203': '計画名',
  'page.plan.common.0204': '担当者',
  'page.plan.common.0205': '関係者',
  'page.plan.common.0206': '作業者',
  'page.plan.common.0207': '遅延',
  'page.plan.common.0301': '点検',
  'page.plan.common.0302': '工事/修繕',
  'page.plan.common.0401': '全体',
  'page.plan.common.0402': '専有部',
  'page.plan.common.0403': '共有部',
  'page.plan.common.0404': '専有部/共有部',
  'page.plan.common.0405': '影響なし',
  'page.plan.common.0501': '繰り返さない',
  'page.plan.common.0502': '○年ごと',
  'page.plan.common.0503': '○ヶ月ごと',
  'page.plan.common.0504': '毎月○回',
  'page.plan.common.0505': '毎月○日',
  'page.plan.common.0506': '毎週○回',
  'page.plan.common.0601': 'この計画に遅延が発生しています。',
  'page.plan.common.0602': '終了月は開始月の翌月以降で指定してください',
  'page.plan.common.0603': '開始月はYYYY年MM月以降を指定してください。',
  'page.plan.common.0604': '終了月はYYYY年MM月以前を指定してください。',
  'page.plan.common.0605': '0~9999の範囲で入力してください。',
  'page.plan.common.0606': '点検表を選択してください。',
  'page.plan.annual.0001': '年次計画',
  'page.plan.annual.0101': '年次計画出力',
  'page.plan.annual.0102': '新規登録',
  'page.plan.annual.0103': '一括登録',
  'page.plan.annual.0201': '未調整',
  'page.plan.annual.0202': '調整中',
  'page.plan.annual.0203': '確定',
  'page.plan.annual.0204': '実施中',
  'page.plan.annual.0205': '完了',
  'page.plan.annual.0206': '遅延',
  'page.plan.annual.0301': '種類',
  'page.plan.annual.0302': '計画名',
  'page.plan.annual.0303': '法定',
  'page.plan.annual.0304': '影響範囲',
  'page.plan.annual.0305': '担当者',
  'page.plan.annual.0306': '関係者',
  'page.plan.annual.0307': '頻度',
  'page.plan.annual.0308': '特記事項',
  'page.plan.annual.0309': '建物',
  'page.plan.annual.0399': '計画がありません。',
  'page.plan.annual.0401': 'メール通知設定',
  'page.plan.annual.0402': '繰り返し再設定',
  'page.plan.annual.0403': '行の編集',
  'page.plan.annual.0404': '行の削除',
  'page.plan.annual.0501': '予定の作成',
  'page.plan.annual.0502': '予定の変更',
  'page.plan.annual.0503': '予定の削除',
  'page.plan.annual.0601': '計画作業項目の作成',
  'page.plan.annual.0602': '建物名',
  'page.plan.annual.0603': '計画名',
  'page.plan.annual.0604': '種類',
  'page.plan.annual.0605': '点検表',
  'page.plan.annual.0606': '点検表を選択',
  'page.plan.annual.0607': '新規作成',
  'page.plan.annual.0608': '・点検分類を選択してください。',
  'page.plan.annual.0609': '・計画名が点検表のタイトルになります。',
  'page.plan.annual.0610': '影響範囲',
  'page.plan.annual.0611': '担当者',
  'page.plan.annual.0612': '担当者を選択してください。',
  'page.plan.annual.0613': '・5 名まで選択できます',
  'page.plan.annual.0614': '・1 名は代表者になります',
  'page.plan.annual.0615': '関係者',
  'page.plan.annual.0616': '関係者を選択してください。',
  'page.plan.annual.0617': '特記事項',
  'page.plan.annual.0618': '開始月',
  'page.plan.annual.0619': '終了月',
  'page.plan.annual.0620': '作業予定時間',
  'page.plan.annual.0621': '時間',
  'page.plan.annual.0622': '頻度',
  'page.plan.annual.0623': '○ = ',
  'page.plan.annual.0624': '名前で検索',
  'page.plan.annual.0625': '会社名で検索',
  'page.plan.annual.0626': '自社',
  'page.plan.annual.0627': '関係会社',
  'page.plan.annual.0628': '名前',
  'page.plan.annual.0629': '会社名',
  'page.plan.annual.0630': '備考',
  'page.plan.annual.0631': 'キャンセル',
  'page.plan.annual.0632': '登録',
  'page.plan.annual.0633': '計画作業項目',
  'page.plan.annual.0634': '・担当者は全て同じ会社に属する必要があります。',
  'page.plan.annual.0635': '担当者は全て同じ会社に属する必要があります。',
  'page.plan.annual.0636': '開始月を元々の開始月より後に変更することはできません。',
  'page.plan.annual.0637': '終了月を元々の終了月より前に変更することはできません。',
  'page.plan.annual.0701': 'メール通知設定',
  'page.plan.annual.0702': '事前連絡通知',
  'page.plan.annual.0703': '作業日の',
  'page.plan.annual.0704': '日前に事前連絡の通知をする。',
  'page.plan.annual.0705': '作業案内送付通知',
  'page.plan.annual.0706': '作業日の',
  'page.plan.annual.0707': '日前に作業案内送付の通知をする。',
  'page.plan.annual.0708': '安全書類提出依頼通知',
  'page.plan.annual.0709': '作業日の',
  'page.plan.annual.0710': '日前に安全書類提出依頼の通知をする。',
  'page.plan.annual.0711': '報告書提出依頼通知',
  'page.plan.annual.0712': '作業日の',
  'page.plan.annual.0713': '日後に報告書提出依頼の通知をする。',
  'page.plan.annual.0714': '日程調整開始日通知',
  'page.plan.annual.0715': '開始月の',
  'page.plan.annual.0716': '日前に日程調整開始日の通知をする。',
  'page.plan.annual.0717': '通知する',
  'page.plan.annual.0718': '通知しない',
  'page.plan.annual.0719': 'キャンセル',
  'page.plan.annual.0720': '設定',
  'page.plan.annual.0801': '計画作業項目 一括登録',
  'page.plan.annual.0802': '①　一括登録用の Excel ファイルを以下よりダウンロードしてください。',
  'page.plan.annual.0803': 'ダウンロード',
  'page.plan.annual.0804': '②　ダウンロードした Excel ファイルに必要な情報を記入してください。',
  'page.plan.annual.0805':
    '③　Excel ファイルをアップロードすることで計画作業項目を一括登録できます。',
  'page.plan.annual.0807': '登録',
  'page.plan.annual.0808': 'キャンセル',
  'page.plan.annual.0809': 'ファイルの形式が違うか、ファイルサイズが大きすぎます。',
  'page.plan.annual.0901': '計画名',
  'page.plan.annual.0902': '対象月',
  'page.plan.annual.1001': '繰り返し再設定',
  'page.plan.annual.1002': '頻度の設定に従って計画を再生成します。',
  'page.plan.annual.1003': 'キャンセル',
  'page.plan.annual.1004': '再設定',
  'page.plan.monthly.0001': '月次計画',
  'page.plan.monthly.0101': '月次計画出力',
  'page.plan.monthly.0102': '予定自動設定',
  'page.plan.monthly.0201': '調整中',
  'page.plan.monthly.0202': '予定確定',
  'page.plan.monthly.0203': '実施中',
  'page.plan.monthly.0204': '確認中',
  'page.plan.monthly.0205': '完了',
  'page.plan.monthly.0206': '遅延',
  'page.plan.monthly.0211': '未調整',
  'page.plan.monthly.0212': '調整中',
  'page.plan.monthly.0213': '確定',
  'page.plan.monthly.0214': '再調整',
  'page.plan.monthly.0215': '遅延',
  'page.plan.monthly.0221': '実施モード',
  'page.plan.monthly.0222': '調整モード',
  'page.plan.monthly.0301': '種類',
  'page.plan.monthly.0302': '計画名',
  'page.plan.monthly.0304': '影響範囲',
  'page.plan.monthly.0305': '担当者',
  'page.plan.monthly.0306': '関係者',
  'page.plan.monthly.0307': '頻度',
  'page.plan.monthly.0309': '建物',
  'page.plan.monthly.0310': '調整',
  'page.plan.monthly.0399': 'この月の計画はありません。',
  'page.plan.monthly.0401': '日程調整メール送信',
  'page.plan.monthly.0402': 'メール通知設定',
  'page.plan.monthly.0403': '行の編集',
  'page.plan.monthly.0501': '予定の作成',
  'page.plan.monthly.0502': '予定の変更',
  'page.plan.monthly.0503': '予定の削除',
  'page.plan.monthly.0504': '詳細',
  'page.plan.monthly.0601': '日程調整',
  'page.plan.monthly.0602': '日程',
  'page.plan.monthly.0603': '年',
  'page.plan.monthly.0604': '日',
  'page.plan.monthly.0605': '作業予定時間',
  'page.plan.monthly.0606': '作業者',
  'page.plan.monthly.0607': '開始時刻',
  'page.plan.monthly.0608': '終了時刻',
  'page.plan.monthly.0609': '調整',
  'page.plan.monthly.0610': '日程調整回答',
  'page.plan.monthly.0611': '実施日',
  'page.plan.monthly.0612': '開始時刻',
  'page.plan.monthly.0613': '終了時刻',
  'page.plan.monthly.0614': '伝達事項',
  'page.plan.monthly.0615': 'キャンセル',
  'page.plan.monthly.0616': '登録',
  'page.plan.monthly.0617': '月',
  'page.plan.monthly.0618': '時間',
  'page.plan.monthly.0619': '作業者を選択してください。',
  'page.plan.monthly.0620': '・5 名まで選択できます',
  'page.plan.monthly.0621': '・1 名は代表者になります',
  'page.plan.monthly.0701': '日程調整メール送信確認',
  'page.plan.monthly.0702':
    '日程調整メールの送信先を確認して、問題がなければ送信ボタンをクリックしてください。',
  'page.plan.monthly.0703': '会社名',
  'page.plan.monthly.0704': '名前',
  'page.plan.monthly.0705': 'メールアドレス',
  'page.plan.monthly.0706': '前回の送信状況',
  'page.plan.monthly.0707': '担当者に伝達事項があれば以下に記入してください。',
  'page.plan.monthly.0708': 'キャンセル',
  'page.plan.monthly.0709': '送信',
  'page.plan.monthly.0801': '計画名',
  'page.plan.monthly.0802': '対象日',
  'page.plan.monthly.0901': '[状態] ',
  'page.plan.monthly.0902': '[時刻] ',
  'page.plan.monthly.0903': '[作業者] ',
  'page.plan.monthly.0904': '※日程調整の回答があります',
  'page.plan.monthly.1001': '確認',
  'page.plan.monthly.1002': '予定を自動で設定してもよろしいですか？',
  'page.plan.monthly.1003': 'キャンセル',
  'page.plan.monthly.1004': 'はい',
  'page.plan.monthly.1005':
    '※ 頻度が「毎月〇日」または「毎週〇回」で設定されている行で自動で予定が登録されます。',
  'page.plan.monthly.1006': '※ 予定が登録されている行については新しく予定は作成されません。',
  'page.plan.daily.0001': '日次計画',
  'page.plan.daily.0201': '調整中',
  'page.plan.daily.0202': '予定確定',
  'page.plan.daily.0203': '実施中',
  'page.plan.daily.0204': '確認中',
  'page.plan.daily.0205': '完了',
  'page.plan.daily.0206': '遅延',
  'page.plan.daily.0211': '未調整',
  'page.plan.daily.0212': '調整中',
  'page.plan.daily.0213': '確定',
  'page.plan.daily.0214': '再調整',
  'page.plan.daily.0215': '遅延',
  'page.plan.daily.0221': '実施モード',
  'page.plan.daily.0222': '調整モード',
  'page.plan.daily.0301': '種類',
  'page.plan.daily.0302': '計画名',
  'page.plan.daily.0304': '影響範囲',
  'page.plan.daily.0305': '担当者',
  'page.plan.daily.0306': '作業者',
  'page.plan.daily.0307': '予定時間',
  'page.plan.daily.0308': '時間',
  'page.plan.daily.0309': '建物',
  'page.plan.daily.0310': '時間を設定\nしてください',
  'page.plan.daily.0399': 'この日の計画はありません。',
  'page.plan.daily.0501': '予定の作成',
  'page.plan.daily.0502': '予定の変更',
  'page.plan.daily.0503': '予定の削除',
  'page.plan.daily.0504': '詳細',
  'page.plan.daily.0601': '計画名',
  'page.plan.daily.0602': '対象日',
  'page.plan.adjustment.0001': '予定調整',
  'page.plan.adjustment.0101': '提出',
  'page.plan.adjustment.0201': '建物',
  'page.plan.adjustment.0202': '種類',
  'page.plan.adjustment.0203': '計画名',
  'page.plan.adjustment.0204': '作業開始日',
  'page.plan.adjustment.0205': '開始時刻',
  'page.plan.adjustment.0206': '作業終了日',
  'page.plan.adjustment.0207': '終了時刻',
  'page.plan.adjustment.0208': '回答',
  'page.plan.adjustment.0209': '希望日時指定欄',
  'page.plan.adjustment.0210': '作業開始日',
  'page.plan.adjustment.0211': '開始時刻',
  'page.plan.adjustment.0212': '作業終了日',
  'page.plan.adjustment.0213': '終了時刻',
  'page.plan.adjustment.0214': '伝達事項',
  'page.plan.adjustment.0215': '状態',
  'page.plan.adjustment.0301': '提案する',
  'page.plan.adjustment.0302': '確定する',
  'page.plan.adjustment.0303': '保留',
  'page.plan.adjustment.0401': '本画面では日程調整依頼に回答することができます。',
  'page.plan.adjustment.0402': '【回答方法】',
  'page.plan.adjustment.0403':
    '各日程に対して「確定する」「提案する」「保留」のいずれかのボタンをクリックして回答してください。',
  'page.plan.adjustment.0404':
    '・確定する：指定された日程で問題がない場合は「確定する」選択してください。',
  'page.plan.adjustment.0405':
    '・提案する：指定された日次で問題がある場合は「提案する」を選択して、希望日時および伝達事項を右枠の希望日時指定欄にご記入ください。',
  'page.plan.adjustment.0406':
    '・保留：まだ予定調整の回答が出来ない場合は「保留」を選択してください。（デフォルトで選択されています。）',
  'page.plan.adjustment.0407':
    '全ての回答を終えたら画面右上の「提出」ボタンをクリックして回答を提出してください。',
  'page.plan.adjustment.0408':
    '既に確定された予定についてはグレーで表示され、回答内容は変更できません。',
  'page.plan.adjustment.0409': '【依頼者からの伝達事項】',
  'page.plan.adjustment.0501': '指定されたURLは有効期限が切れているか、不正なURLです。',
};

const rscArchive = {
  'page.archive.0001': 'ドキュメント',
  'page.archive.0002': 'ファイルを検索',
  'page.archive.0003': '新規フォルダ',
  'page.archive.0004': 'アップロード',
  'page.archive.0005': '場所: ',
  'page.archive.1001': '名前',
  'page.archive.1002': '更新日時',
  'page.archive.1003': '更新者',
  'page.archive.1004': 'ファイルサイズ',
  'page.archive.1101': 'ファイル名',
  'page.archive.1102': 'フォルダ名',
  'page.archive.1999': '登録がありません',
  'page.archive.2001': '権限がないためホーム画面に遷移します。',
  'page.archive.menu.0001': '名前を変更',
  'page.archive.menu.0002': 'ダウンロード',
  'page.archive.menu.0003': '移動',
  'page.archive.menu.0004': '削除',
  'page.archive.addNewFolder.0001': '新規作成',
  'page.archive.addNewFolder.0002': 'フォルダ名',
  'page.archive.addNewFolder.0003': 'フォルダ名を入力してください',
  'page.archive.addNewFolder.0004': 'キャンセル',
  'page.archive.addNewFolder.0005': '作成',
  'page.archive.addNewFolder.1001': 'アップロード',
  'page.archive.addNewFolder.1003': '選択したファイル',
  'page.archive.addNewFolder.1004': 'キャンセル',
  'page.archive.addNewFolder.1005': '登録',
  'page.archive.addNewFolder.1006':
    '同じ名前のファイルが既に存在しています。\nこれらのファイルを上書きしますか？',
  'page.archive.addNewFolder.1007': 'はい',
  'page.archive.rename.0001': 'ファイル/フォルダ名を変更',
  'page.archive.rename.0002': '名前',
  'page.archive.rename.0003': '新しい名前を入力してください',
  'page.archive.rename.0004': 'キャンセル',
  'page.archive.rename.0005': '更新',
};

const rscUser = {
  'page.user.list.0001': 'ユーザー管理',
  'page.user.list.0002': 'ユーザーを検索',
  'page.user.list.0003': '新規登録',
  'page.user.list.1001': 'ユーザー名',
  'page.user.list.1002': 'メールアドレス',
  'page.user.list.1003': '拠点',
  'page.user.list.1004': '所属',
  'page.user.list.1005': '役職',
  'page.user.list.1006': '管理者',
  'page.user.list.1007': '状態',
  'page.user.list.1101': '使用中',
  'page.user.list.1102': '停止中',
  'page.user.list.1999': '登録がありません',
  'page.user.detail.0001': 'ユーザー詳細',
  'page.user.detail.0002': 'プロフィール詳細',
  'page.user.detail.0003': '編集',
  'page.user.detail.0004': '削除',
  'page.user.detail.1001': 'ユーザー名',
  'page.user.detail.1002': 'メールアドレス',
  'page.user.detail.1003': '生年月日',
  'page.user.detail.1004': '拠点',
  'page.user.detail.1005': '所属',
  'page.user.detail.1006': '役職',
  'page.user.detail.1007': '電話番号',
  'page.user.detail.1008': '携帯番号',
  'page.user.detail.1009': '備考・コメント',
  'page.user.detail.1010': 'システム管理者',
  'page.user.detail.1011': '状態',
  'page.user.detail.1101': '使用中',
  'page.user.detail.1102': '停止中',
  'page.user.detail.1201': '○',
  'page.user.detail.1202': '×',
  'page.user.edit.0001': 'ユーザー編集',
  'page.user.edit.0002': 'ユーザー登録',
  'page.user.edit.0003': 'プロフィール編集',
  'page.user.edit.0004': '保存',
  'page.user.edit.0005': '終了',
  'page.user.edit.1001': '姓',
  'page.user.edit.1002': '名',
  'page.user.edit.1003': 'セイ',
  'page.user.edit.1004': 'メイ',
  'page.user.edit.1005': 'メールアドレス',
  'page.user.edit.1006': '生年月日',
  'page.user.edit.1007': '拠点',
  'page.user.edit.1008': '所属',
  'page.user.edit.1009': '役職',
  'page.user.edit.1010': '電話番号',
  'page.user.edit.1011': '携帯番号',
  'page.user.edit.1012': '備考・コメント',
  'page.user.edit.1013': 'システム管理者',
  'page.user.edit.1014': '状態',
  'page.user.edit.1101': '使用中',
  'page.user.edit.1102': '停止中',
  'page.user.edit.2001': 'ファイル選択',
  'page.user.edit.2002': 'イメージを変更',
  'page.user.edit.2003': 'イメージを削除',
  'page.user.edit.2004': '[選択可能ファイル] .jpg, .png (サイズ上限：1 MB)',
  'page.user.edit.hints.0001': '所属している支社・支店等を入力してください',
  'page.user.edit.hints.0002': '所属している部署を入力してください。',
  'page.user.edit.cropImage.caption': '画像のトリミング',
  'page.user.edit.cropImage.save': '保存',
  'page.user.edit.cropImage.cancel': 'キャンセル',
  'page.user.delete.confirm':
    '削除後、以下項目の設定から解除されます。\n・建物担当者\n・予定調整先',
};

const rscGroup = {
  'page.group.list.0001': 'グループ管理',
  'page.group.list.0002': 'グループを検索',
  'page.group.list.0003': '新規登録',
  'page.group.list.1001': 'グループ名',
  'page.group.list.1002': 'メンバー数',
  'page.group.list.1003': '説明',
  'page.group.list.1999': '登録がありません',
  'page.group.list.2001':
    'ユーザーをまとめてグループとして管理できます。\n部署、グループでまとめる際に使用することができます。',
  'page.group.detail.0001': 'グループ詳細',
  'page.group.detail.0002': '編集',
  'page.group.detail.0003': '削除',
  'page.group.detail.1001': 'グループ名',
  'page.group.detail.1002': 'メンバー数',
  'page.group.detail.1003': '説明',
  'page.group.detail.1004': '人',
  'page.group.detail.2001': 'ユーザー名',
  'page.group.detail.2002': 'メールアドレス',
  'page.group.detail.2003': '拠点',
  'page.group.detail.2004': '所属',
  'page.group.detail.2005': '役職',
  'page.group.detail.2999': '登録がありません',
  'page.group.detail.3001': 'ユーザーを追加',
  'page.group.detail.3002': 'をグループから削除しますか？',
  'page.group.detail.addGroupUserModal.0001': 'ユーザーを追加',
  'page.group.detail.addGroupUserModal.0002': 'キャンセル',
  'page.group.detail.addGroupUserModal.0003': '追加',
  'page.group.detail.addGroupUserModal.1001': 'ユーザー名またはグループ名で検索',
  'page.group.detail.addGroupUserModal.1002': '検索',
  'page.group.detail.addGroupUserModal.2001':
    '※ 表示されるユーザーの上限は 100 件です。\n　 キーワード検索でリストを絞り込むことができます。\n※ 同時に 50 ユーザーまで選択できます。',
  'page.group.detail.addGroupUserModal.2002': '（検索結果：全 {0} 件 ― 表示 {1} 件）',
  'page.group.detail.addGroupUserModal.3111': '（ n ユーザーを選択中）',
  'page.group.edit.0001': 'グループ編集',
  'page.group.edit.0002': 'グループ登録',
  'page.group.edit.0003': '保存',
  'page.group.edit.0004': '終了',
  'page.group.edit.1001': 'グループ名',
  'page.group.edit.1002': '説明',
};

const rscProperty = {
  'page.property.list.0001': '建物管理',
  'page.property.list.0002': '建物を検索',
  'page.property.list.0003': '建物名、住所、所有者で検索できます。',
  'page.property.list.1001': '建物名',
  'page.property.list.1002': '郵便番号',
  'page.property.list.1003': '住所',
  'page.property.list.1004': '所有者',
  'page.property.list.1005': '電話番号',
  'page.property.list.1006': '契約数',
  'page.property.list.1007': '異常のある設備数',
  'page.property.list.1008': '未完了の工事数',
  'page.property.list.1009': '未完了の案件数',
  'page.property.list.1010': 'URL',
  'page.property.list.1011': '階数',
  'page.property.list.1012': '駐車場',
  'page.property.list.1013': '更新日',
  'page.property.list.1014': '更新者',
  'page.property.list.1999': '登録がありません',
  'page.property.advanced.search.0001': '詳細検索',
  'page.property.advanced.search.0002': 'クリア',
  'page.property.advanced.search.0003': '検索',
  'page.property.advanced.search.0004': '建物名',
  'page.property.advanced.search.0005': '所有者',
  'page.property.advanced.search.0006': '都道府県',
  'page.property.advanced.search.0007': '施工日',
  'page.property.advanced.search.0008': '敷地面積',
  'page.property.advanced.search.0009': 'm²',
  'page.property.advanced.search.0010': '延床面積',
  'page.property.advanced.search.0011': '設備に異常がある',
  'page.property.advanced.search.0012': '未完了の工事がある',
  'page.property.advanced.search.0013': '未完了の案件がある',
  'page.property.advanced.search.0014': '[施工日] 終了日を開始日の前に設定することはできません。',
  'page.property.advanced.search.0015': '{0}の項目が不正です。',
  'page.property.advanced.search.0016': '敷地面積の下限',
  'page.property.advanced.search.0017': '敷地面積の上限',
  'page.property.advanced.search.0018': '延床面積の下限',
  'page.property.advanced.search.0019': '延床面積の上限',
  'page.property.add.0001': '建物を登録',
  'page.property.dropdown.add.0001': '手入力で作成',
  'page.property.dropdown.add.0002': 'CSVでまとめて作成',
  'page.property.upload.modal.0001': '建物を一括登録/更新する',
  'page.property.upload.modal.0002': 'キャンセル',
  'page.property.upload.modal.0003': '登録',
  'page.property.upload.modal.0004': '建物を一括で登録/更新できます。',
  'page.property.upload.modal.0005':
    'ご注意：\n関連ドキュメントなど、一部の項目（一括登録用テンプレートにない項目）は一括登録/更新できません。\n取り込みが完了した後に設定をお願いいたします。',
  'page.property.upload.modal.0006': '建物を一括で登録/更新しますか？',
  'page.property.upload.modal.0007': 'ファイルのアップロードに失敗しました',
  'page.property.icons.0001': 'タグの検索',
  'page.property.icons.0002': 'タグ選択中',
  'page.property.dropdown.tag.0001': 'タグを一括設定',
  'page.property.dropdown.tag.0002': 'タグを一括削除',
  'page.property.dropdown.tag.0003': 'タグの設定',
  'page.property.tag.reset.0001': 'タグを一括削除',
  'page.property.tag.reset.0002': 'キャンセル',
  'page.property.tag.reset.0003': '解除',
  'page.property.tag.reset.0004': '選択した建物に設定されているすべてのタグを解除しますか？',
  'page.property.tag.set.0001': 'タグを一括設定',
  'page.property.tag.set.0002': 'キャンセル',
  'page.property.tag.set.0003': '追加',
  'page.property.tag.set.0004': 'タグを選択してください',
  'page.property.dropdown.search.tag.0001': 'タグで検索',
  'page.property.dropdown.search.tag.0002': '選択したタグ一括削除',
  'page.property.dropdown.remove.0001': '選択した建物を削除',
  'page.property.remove.properties.0001': '選択した建物を削除する',
  'page.property.remove.properties.0002': 'いいえ',
  'page.property.remove.properties.0003': 'はい',
  'page.property.remove.properties.0004': '選択した建物を削除しますか？',
  'page.property.dropdown.download.0001': '選択した項目をダウンロード',
  'page.property.dropdown.download.0002': 'すべてをダウンロード',
  'page.property.download.properties.0001': '選択した項目をダウンロードする',
  'page.property.download.properties.0002': 'キャンセル',
  'page.property.download.properties.0003': 'ダウンロード',
  'page.property.download.properties.0004': '選択した項目をダウンロードしますか？',
  'page.property.download.properties.all.0001': 'すべてをダウンロードする',
  'page.property.download.properties.all.0002': 'キャンセル',
  'page.property.download.properties.all.0003': 'ダウンロード',
  'page.property.download.properties.all.0004': 'すべてをダウンロードしますか？',
  'page.property.detail.0001': '建物詳細',
  'page.property.detail.0002': '編集',
  'page.property.detail.0003': '削除',
  'page.property.detail.common.0101': 'ファイルサイズの上限：100 MB',
  'page.property.detail.common.0102':
    'ご注意：添付写真と関連ドキュメント(ローカルファイル)の合計サイズ上限は300MBです。',
  'page.property.detail.tab.0001': '基本情報',
  'page.property.detail.tab.0002': 'フロア',
  'page.property.detail.tab.0003': '担当者',
  'page.property.detail.tab.0004': 'テナント',
  'page.property.detail.tab.0005': '地図',
  'page.property.detail.tab.0006': '連絡事項',
  'page.property.detail.tab.0007': '契約情報',
  'page.property.detail.general.0001': '建物名',
  'page.property.detail.general.0002': '建物コード',
  'page.property.detail.general.0003': '郵便番号',
  'page.property.detail.general.0004': '住所',
  'page.property.detail.general.0005': '所有者',
  'page.property.detail.general.0006': '連絡先',
  'page.property.detail.general.0007': '竣工日',
  'page.property.detail.general.0008': '建築規模',
  'page.property.detail.general.0009': '敷地面積',
  'page.property.detail.general.0010': '延床面積',
  'page.property.detail.general.0011': '備考',
  'page.property.detail.general.0012': '都道府県',
  'page.property.detail.general.0013': '市区町村',
  'page.property.detail.general.0014': '町域・番地',
  'page.property.detail.general.0015': 'ビル名',
  'page.property.detail.general.0016': 'URL',
  'page.property.detail.general.0017': '電話番号',
  'page.property.detail.general.0018': '竣工日',
  'page.property.detail.general.0019': '階数',
  'page.property.detail.general.0020': '駐車場',
  'page.property.detail.general.0021': '設計',
  'page.property.detail.general.0022': '施工',
  'page.property.detail.general.0023': '契約数',
  'page.property.detail.general.0024': '異常のある設備数',
  'page.property.detail.general.0025': '未完了の工事数',
  'page.property.detail.general.0026': '未完了の案件数',
  'page.property.detail.general.0027': 'タグ情報',
  'page.property.detail.general.0028': '関連ドキュメント',
  'page.property.detail.general.0029': '更新日',
  'page.property.detail.general.0030': '更新者',
  'page.property.detail.general.0101': '㎡',
  'page.property.detail.information.0001': '添付ファイル',
  'page.property.detail.information.0002': 'コメント',
  'page.property.detail.information.0003': 'コピーしました',
  'page.property.detail.information.0004': 'クリップボードにコピー',
  'page.property.detail.information.0005': 'コメントをコピー',
  'page.property.detail.information.0006': '名前を変更',
  'page.property.detail.information.0007': 'ダウンロード',
  'page.property.detail.information.0008': '削除',
  'page.property.detail.information.0009': 'ファイル名',
  'page.property.detail.information.0010':
    'ご注意：添付ファイルは作業者ならびに担当者や社外の協力会社に共有されます。登録内容に機密情報が無いか十分ご注意ください。',
  'page.property.detail.information.0011':
    'ご注意：コメントは作業者ならびに担当者や社外の協力会社に共有されます。登録内容に機密情報が無いか十分ご注意ください。',
  'page.property.detail.information.modal.0001': 'ファイル/フォルダ名を変更',
  'page.property.detail.information.modal.0002': 'キャンセル',
  'page.property.detail.information.modal.0003': '更新',
  'page.property.detail.information.modal.0004': '名前',
  'page.property.detail.information.modal.0005': '新しい名前を入力してください',
  'page.property.detail.information.modal.0006': 'コメントの編集',
  'page.property.detail.information.modal.0007': 'キャンセル',
  'page.property.detail.information.modal.0008': '保存',
  'page.property.detail.information.modal.0009': 'コメント',
  'page.property.detail.information.modal.0010': '入力必須項目です',
  'page.property.detail.information.modal.0011': 'ファイル名の更新に失敗しました',
  'page.property.detail.information.modal.0012': 'ファイルの削除に失敗しました',
  'page.property.detail.information.modal.0013': 'コメントの更新に失敗しました',
  'page.property.detail.information.modal.0014': 'ファイルのアップロードに失敗しました',
  'page.property.detail.information.modal.0015': 'コメントを入力してください',
  'page.property.detail.floor.0001': 'フロア名',
  'page.property.detail.floor.0002': '部屋数',
  'page.property.detail.floor.0003': '部屋名',
  'page.property.detail.floor.0004': '説明',
  'page.property.detail.floor.0005': '並び替え',
  'page.property.detail.floor.1001': '部屋名',
  'page.property.detail.floor.1002': '説明',
  'page.property.detail.floor.2001': 'フロアを追加',
  'page.property.detail.floor.2002': 'フロアの編集',
  'page.property.detail.floor.2003': '部屋を追加',
  'page.property.detail.floor.2004': '部屋の編集',
  'page.property.detail.floor.3001': 'キャンセル',
  'page.property.detail.floor.3002': '保存',
  'page.property.detail.floor.4001': '編集',
  'page.property.detail.floor.4002': '削除',
  'page.property.detail.floor.9999': '登録がありません',
  'page.property.detail.floor.warning.0001':
    '注意：このフロアに登録されている部屋・\n　　　フロアマップの情報も同時に削除されます。',
  'page.property.detail.member.0001': 'ユーザー／グループ名',
  'page.property.detail.member.0002': '種類',
  'page.property.detail.member.0003': 'ロール',
  'page.property.detail.member.0004': 'ユーザー',
  'page.property.detail.member.0005': 'グループ',
  'page.property.detail.member.1001': '管理者',
  'page.property.detail.member.1002': 'スタッフ',
  'page.property.detail.member.delete.0001': '担当者から外す',
  'page.property.detail.member.delete.0002': 'を担当者から外しますか？',
  'page.property.detail.member.delete.0003':
    '注意：担当者でなくなったメンバーは\n　　　この建物にアクセスできなくなります。',
  'page.property.detail.member.9999': '登録がありません',
  'page.property.detail.member.addMember.0001': '担当者を追加',
  'page.property.detail.member.addMember.0002': 'キャンセル',
  'page.property.detail.member.addMember.0003': '追加',
  'page.property.detail.member.addMember.0004': 'ユーザー名またはグループ名で検索',
  'page.property.detail.member.addMember.0005': '検索',
  'page.property.detail.member.addMember.0006': '（検索結果：全',
  'page.property.detail.member.addMember.0007': '件 ― 表示',
  'page.property.detail.member.addMember.0008': '件）',
  'page.property.detail.member.addMember.0009':
    '※ 表示されるユーザーおよびグループの上限は 100 件です。\n　 キーワード検索でリストを絞り込むことができます。\n※ 同時に 50 ユーザー/グループまで選択できます。',
  'page.property.detail.member.addMember.0010': '付与する権限：',
  'page.property.detail.member.addMember.0011': '（ n ユーザー/グループを選択中）',
  'page.property.detail.tenant.0001': 'テナント名',
  'page.property.detail.tenant.0002': '電話番号',
  'page.property.detail.tenant.0003': 'メールアドレス',
  'page.property.detail.tenant.0004': '備考・コメント',
  'page.property.detail.tenant.1001': 'テナントを追加',
  'page.property.detail.tenant.1002': 'テナントを編集',
  'page.property.detail.tenant.2001': 'キャンセル',
  'page.property.detail.tenant.2002': '保存',
  'page.property.detail.tenant.3001': '編集',
  'page.property.detail.tenant.3002': '削除',
  'page.property.detail.tenant.9999': '登録がありません',
  'page.property.detail.warn.0001': '指定した建物はデータが存在しないか、すでに削除されています。',
  'page.property.detail.contract.options.0001': '設備',
  'page.property.detail.contract.options.0002': '清掃',
  'page.property.detail.contract.options.0003': '警備',
  'page.property.detail.contract.options.0004': '検針',
  'page.property.detail.contract.options.0005': '建築',
  'page.property.detail.contract.options.0006': 'その他',
  'page.property.detail.contract.options.0101': '定期',
  'page.property.detail.contract.options.0102': '法定',
  'page.property.detail.contract.options.0103': '日常',
  'page.property.detail.contract.options.0104': 'その他',
  'page.property.detail.contract.options.0201': '定期業務',
  'page.property.detail.contract.options.0202': '工事',
  'page.property.detail.contract.options.0301': '定期業務',
  'page.property.detail.contract.options.0302': '工事',
  'page.property.detail.contract.options.0401': '契約中',
  'page.property.detail.contract.options.0402': '契約終了',
  'page.property.detail.contract.options.0501': '繰り返す',
  'page.property.detail.contract.options.0502': '繰り返さない',
  'page.property.detail.contract.options.0601': '毎日',
  'page.property.detail.contract.options.0602': '毎週 平日(月～金)',
  'page.property.detail.contract.options.0603': '毎週 週末(土～日)',
  'page.property.detail.contract.options.0604': '毎月 1日',
  'page.property.detail.contract.options.0605': '毎月 最終日',
  'page.property.detail.contract.options.0606': 'カスタムの繰り返し',
  'page.property.detail.contract.options.0701': '日ごと',
  'page.property.detail.contract.options.0702': '週ごと',
  'page.property.detail.contract.options.0703': '月ごと',
  'page.property.detail.contract.options.0704': '年ごと',
  'page.property.detail.contract.options.0801': '毎月 {0}日',
  'page.property.detail.contract.list.0001': '新規登録',
  'page.property.detail.contract.list.0002': '建物名',
  'page.property.detail.contract.list.0003': '計画名',
  'page.property.detail.contract.list.0004': '契約種別',
  'page.property.detail.contract.list.0005': '定期業務種別',
  'page.property.detail.contract.list.0006': '定期業務区分',
  'page.property.detail.contract.list.0007': '周期',
  'page.property.detail.contract.list.0008': '開始日',
  'page.property.detail.contract.list.0009': '終了日',
  'page.property.detail.contract.list.0010': '協力会社名',
  'page.property.detail.contract.list.0011': '請求単価',
  'page.property.detail.contract.list.0012': '支払単価',
  'page.property.detail.contract.list.0013': '契約状況',
  'page.property.detail.contract.list.0014': '点検表',
  'page.property.detail.contract.list.0015': '報告書フォーマット',
  'page.property.detail.contract.list.0016': '表示対象はありません',
  'page.property.detail.contract.list.0017': '円',
  'page.property.detail.contract.list.0101': '契約期間を登録する',
  'page.property.detail.contract.list.0102': 'キャンセル',
  'page.property.detail.contract.list.0103': '登録',
  'page.property.detail.contract.list.0104': '開始日',
  'page.property.detail.contract.list.0105': '終了日',
  'page.property.detail.contract.list.0201': '繰り返し設定を登録する',
  'page.property.detail.contract.list.0202': 'キャンセル',
  'page.property.detail.contract.list.0203': '登録',
  'page.property.detail.contract.list.0204': '繰り返し設定',
  'page.property.detail.contract.list.0205': '繰り返しパターン',
  'page.property.detail.contract.list.0206': '繰り返す間隔',
  'page.property.detail.contract.list.0207': '日ごと',
  'page.property.detail.contract.list.0208': '週ごと',
  'page.property.detail.contract.list.0209': 'か月ごと',
  'page.property.detail.contract.list.0210': '年ごと',
  'page.property.detail.contract.list.0211': '曜日',
  'page.property.detail.contract.list.0301': '契約期間を登録する',
  'page.property.detail.contract.list.0302': '繰り返し設定を登録する',
  'page.property.detail.contract.list.0401': '選択した契約を削除',
  'page.property.detail.contract.list.0501': '選択した契約を削除する',
  'page.property.detail.contract.list.0502': 'キャンセル',
  'page.property.detail.contract.list.0503': '削除',
  'page.property.detail.contract.list.0504': '選択した契約を削除しますか？',
  'page.property.detail.contract.list.0601': '契約状態を契約終了にする',
  'page.property.detail.contract.list.0602': 'キャンセル',
  'page.property.detail.contract.list.0603': '登録',
  'page.property.detail.contract.list.0604': '選択してください',
  'page.property.detail.contract.list.0701': '請求単価を登録する',
  'page.property.detail.contract.list.0702': '仕入単価を登録する',
  'page.property.detail.contract.list.0703': '契約状態を契約終了にする',
  'page.property.detail.contract.list.0801': '請求単価を登録する',
  'page.property.detail.contract.list.0802': 'キャンセル',
  'page.property.detail.contract.list.0803': '登録',
  'page.property.detail.contract.list.0804': '請求単価を入力してください',
  'page.property.detail.contract.list.0805': '円',
  'page.property.detail.contract.list.0901': '仕入単価を登録する',
  'page.property.detail.contract.list.0902': 'キャンセル',
  'page.property.detail.contract.list.0903': '登録',
  'page.property.detail.contract.list.0904': '仕入単価を入力してください',
  'page.property.detail.contract.list.0905': '円',
  'page.property.detail.contract.list.1001': '選択した項目をダウンロードする',
  'page.property.detail.contract.list.1002': 'キャンセル',
  'page.property.detail.contract.list.1003': 'ダウンロード',
  'page.property.detail.contract.list.1004': '選択した項目をダウンロードしますか？',
  'page.property.detail.contract.list.1101': 'すべてをダウンロードする',
  'page.property.detail.contract.list.1102': 'キャンセル',
  'page.property.detail.contract.list.1103': 'ダウンロード',
  'page.property.detail.contract.list.1104': 'すべてをダウンロードしますか？',
  'page.property.detail.contract.list.1201': '選択した項目をダウンロード',
  'page.property.detail.contract.list.1202': 'すべてをダウンロード',
  'page.property.detail.contract.list.1301': '契約を一括登録/更新する',
  'page.property.detail.contract.list.1302': 'キャンセル',
  'page.property.detail.contract.list.1303': '登録',
  'page.property.detail.contract.list.1304': '契約を一括で登録/更新できます。',
  'page.property.detail.contract.list.1305':
    'ご注意：\n「契約終了」にすると契約更新が出来なくなります。',
  'page.property.detail.contract.list.1306': '契約を一括で登録/更新しますか？',
  'page.property.detail.contract.list.1307': 'ファイルのアップロードに失敗しました',
  'page.property.detail.contract.list.1401': '定期業務区分を登録する',
  'page.property.detail.contract.list.1402': 'キャンセル',
  'page.property.detail.contract.list.1403': '登録',
  'page.property.detail.contract.list.1404': '選択してください',
  'page.property.detail.contract.list.1501': '定期業務種別を登録する',
  'page.property.detail.contract.list.1502': 'キャンセル',
  'page.property.detail.contract.list.1503': '登録',
  'page.property.detail.contract.list.1504': '選択してください',
  'page.property.detail.contract.list.1601': '定期業務区分を登録する',
  'page.property.detail.contract.list.1602': '定期業務種別を登録する',
  'page.property.detail.contract.list.1701': '協力会社を登録する',
  'page.property.detail.contract.list.1801': '契約検索',
  'page.property.detail.contract.list.1802': '計画名',
  'page.property.detail.contract.list.1803': '契約種別',
  'page.property.detail.contract.list.1804': '定期業務種別',
  'page.property.detail.contract.list.1805': '定期業務区分',
  'page.property.detail.contract.list.1806': '開始日',
  'page.property.detail.contract.list.1807': '終了日',
  'page.property.detail.contract.list.1808': '協力会社名',
  'page.property.detail.contract.list.1809': '契約状況',
  'page.property.detail.contract.list.1901': '手入力で作成',
  'page.property.detail.contract.list.1902': 'CSVでまとめて作成',
  'page.property.detail.contract.list.2001': '確認メッセージ',
  'page.property.detail.contract.list.2002': 'いいえ',
  'page.property.detail.contract.list.2003': 'はい',
  'page.property.detail.contract.list.3001': '契約を検索',
  'page.property.detail.contract.status.change.confirm':
    '「契約終了」にすると契約更新が出来なくなります。\nよろしいでしょうか？',
  'page.property.detail.contract.warn.0001':
    '[開始日] 終了日を開始日の前に設定することはできません。',
  'page.property.detail.contract.warn.0002':
    '[終了日] 終了日を開始日の前に設定することはできません。',
  'page.property.detail.contract.warn.0003': '終了日を開始日の前に設定することはできません。',
  'page.property.detail.contract.warn.0004':
    '契約の期間／繰り返しパターンが更新される場合、削除済みの予定が再表示されます。\nまた、対応状況が「未確定」の作業項目は一度削除され、更新日を基準に新しい期間／繰り返しパターンに合わせ作業計画を再構築いたします。\n実行してもよろしいでしょうか？',
  'page.property.detail.contract.warn.0005': '正の整数のみ入力してください',
  'page.property.detail.contract.warn.0006': '{min}と{max}の間の数値を入力してください',
  'page.property.detail.contract.warn.0007': '繰り返す間隔は {min} から {max} 内で設定してください',
  'page.property.detail.contract.warn.0008':
    '指定した契約はデータが存在しないか、すでに削除されています。',
  'page.property.detail.contract.detail.0001': '一覧へ',
  'page.property.detail.contract.detail.0002': '建物名',
  'page.property.detail.contract.detail.0003': '建物ID',
  'page.property.detail.contract.detail.0004': '計画名',
  'page.property.detail.contract.detail.0005': '契約更新月',
  'page.property.detail.contract.detail.0006': '更新月通知設定',
  'page.property.detail.contract.detail.0007': '契約更新月の{0}か月前に通知する',
  'page.property.detail.contract.detail.0008': '契約種別',
  'page.property.detail.contract.detail.0009': '定期業務種別',
  'page.property.detail.contract.detail.0010': '定期業務区分',
  'page.property.detail.contract.detail.0011': '開始日',
  'page.property.detail.contract.detail.0012': '終了日',
  'page.property.detail.contract.detail.0013': '設定なし',
  'page.property.detail.contract.detail.0014': '{0}回',
  'page.property.detail.contract.detail.0015': '繰り返し設定',
  'page.property.detail.contract.detail.0016': '繰り返す',
  'page.property.detail.contract.detail.0017': '繰り返さない',
  'page.property.detail.contract.detail.0018': '周期',
  'page.property.detail.contract.detail.0019': 'カスタム設定',
  'page.property.detail.contract.detail.0020': '協力会社名（企業名）',
  'page.property.detail.contract.detail.0021': '協力会社名（企業ID）',
  'page.property.detail.contract.detail.0022': '請求単価',
  'page.property.detail.contract.detail.0023': '支払単価',
  'page.property.detail.contract.detail.0024': '点検表',
  'page.property.detail.contract.detail.0025': '報告書フォーマット',
  'page.property.detail.contract.detail.0026': 'なし',
  'page.property.detail.contract.detail.0027': '契約状況',
  'page.property.detail.contract.detail.0028': '備考',
  'page.property.detail.contract.detail.0029': '円',
  'page.property.detail.contract.delete.0001': '確認',
  'page.property.detail.contract.delete.0002': 'いいえ',
  'page.property.detail.contract.delete.0003': 'はい',
  'page.property.detail.contract.delete.0004': '建物名',
  'page.property.detail.contract.delete.0005': '計画名',
  'page.property.detail.contract.delete.0006': 'を削除しますか？',
  'page.property.detail.contract.delete.0007': '注意：削除後は元に戻せません。',
  'page.property.detail.contract.edit.0001': '新規契約登録',
  'page.property.detail.contract.edit.0002': '契約登録編集',
  'page.property.detail.contract.edit.0003': 'キャンセル',
  'page.property.detail.contract.edit.0004': '登録',
  'page.property.detail.contract.edit.0005': '建物名',
  'page.property.detail.contract.edit.0006': '建物ID',
  'page.property.detail.contract.edit.0007': '計画名',
  'page.property.detail.contract.edit.0008': '契約更新月',
  'page.property.detail.contract.edit.0009': '更新月通知設定',
  'page.property.detail.contract.edit.0010': '契約更新月の',
  'page.property.detail.contract.edit.0011': 'か月前に通知する',
  'page.property.detail.contract.edit.0012': '契約種別',
  'page.property.detail.contract.edit.0013': '定期業務種別',
  'page.property.detail.contract.edit.0014': '定期業務区分',
  'page.property.detail.contract.edit.0015': '開始日',
  'page.property.detail.contract.edit.0016': '繰り返し設定',
  'page.property.detail.contract.edit.0017': '繰り返しパターン',
  'page.property.detail.contract.edit.0018': '繰り返す間隔',
  'page.property.detail.contract.edit.0019': '曜日',
  'page.property.detail.contract.edit.0020': '終了日',
  'page.property.detail.contract.edit.0021': '協力会社',
  'page.property.detail.contract.edit.0022': '連絡先選択',
  'page.property.detail.contract.edit.0023': '協力会社名（企業名）',
  'page.property.detail.contract.edit.0024': '協力会社ID（企業ID）',
  'page.property.detail.contract.edit.0025': '請求単価',
  'page.property.detail.contract.edit.0026': '支払単価',
  'page.property.detail.contract.edit.0027': '点検表',
  'page.property.detail.contract.edit.0028': '新規作成',
  'page.property.detail.contract.edit.0029': '点検表を選択',
  'page.property.detail.contract.edit.0030': '点検表選択',
  'page.property.detail.contract.edit.0031': '点検表フォーマット',
  'page.property.detail.contract.edit.0032': '報告書フォーマット',
  'page.property.detail.contract.edit.0033': '契約状況',
  'page.property.detail.contract.edit.0034': '備考',
  'page.property.detail.contract.edit.confirm': '「契約終了」にすると契約更新が出来なくなります。',
  'page.property.detail.contract.edit.warn.0001': '1~12の整数を入力してください',
  'page.property.detail.contract.edit.warn.0002': '繰り返しパターンを選択してください',
  'page.property.detail.contract.edit.warn.0003': '繰り返す間隔を選択してください',
  'page.property.detail.contract.edit.warn.0004': '曜日を選択してください',
  'page.property.detail.contract.edit.warn.0005': '日を選択してください',
  'page.property.detail.contract.edit.warn.0006': '点検表の定期業務種別を選択してください',
  'page.property.detail.contract.edit.confirm.0001':
    '契約の期間／繰り返しパターンが更新される場合、削除済みの予定が再表示されます。\nまた、対応状況が「未確定」の作業項目は一度削除され、更新日を基準に新しい期間／繰り返しパターンに合わせ作業計画を再構築いたします。\n実行してもよろしいでしょうか？',
  'page.property.detail.contract.detail.cycles.0001': '日ごと',
  'page.property.detail.contract.detail.cycles.0002': '週ごと',
  'page.property.detail.contract.detail.cycles.0003': 'か月ごと',
  'page.property.detail.contract.detail.cycles.0004': '年ごと',
  'page.property.detail.contract.detail.cycles.0005': '毎月{0}日',
  'page.property.detail.contract.detail.cycles.0006': '繰り返す間隔：',
  'page.property.edit.0001': '建物編集',
  'page.property.edit.0002': '建物登録',
  'page.property.edit.0003': '保存',
  'page.property.edit.0004': '終了',
  'page.property.edit.1001': '建物名',
  'page.property.edit.1002': '建物コード',
  'page.property.edit.1003': '郵便番号',
  'page.property.edit.1004': '都道府県',
  'page.property.edit.1005': '市区町村番地',
  'page.property.edit.1006': '所有者',
  'page.property.edit.1007': '連絡先',
  'page.property.edit.1008': '竣工日',
  'page.property.edit.1009': '建築規模',
  'page.property.edit.1010': '敷地面積',
  'page.property.edit.1011': '延床面積',
  'page.property.edit.1012': '備考',
  'page.property.edit.1013': '㎡',
  'page.property.edit.1014': '検索',
  'page.property.edit.1015': '市区町村',
  'page.property.edit.1016': '町域・番地',
  'page.property.edit.1017': 'ビル名',
  'page.property.edit.1018': 'URL',
  'page.property.edit.1019': '選択',
  'page.property.edit.1020': '電話番号',
  'page.property.edit.1021': '階数',
  'page.property.edit.1022': '駐車場',
  'page.property.edit.1023': '設計',
  'page.property.edit.1024': '施工',
  'page.property.edit.1025': 'タグ情報',
  'page.property.edit.1026': '関連ドキュメント',
  'page.property.edit.2001': '画像',
  'page.property.edit.2002': 'ファイル選択',
  'page.property.edit.2003': '[選択可能ファイル]',
  'page.property.edit.2004': '画像のみ',
  'page.property.edit.2005': 'ファイルサイズの上限：10 MB',
  'page.property.edit.2006': '削除',
  'page.property.edit.modal.1001': 'メモ',
  'page.property.edit.modal.1002': 'キャンセル',
  'page.property.edit.modal.1003': 'OK',
  'page.property.edit.modal.1004': 'メモの内容を記入してください。',
  'page.property.edit.modal.1005': 'URL参照設定',
  'page.property.edit.modal.1006': 'キャンセル',
  'page.property.edit.modal.1007': '設定',
  'page.property.edit.modal.1008': 'タイトル',
  'page.property.edit.modal.1009': 'URL',
  'page.property.edit.modal.1010': '画像',
  'page.property.edit.modal.1011': '添付写真',
  'page.property.edit.modal.1012': '[選択可能ファイル]',
  'page.property.edit.modal.1013': '画像または動画：最大 10 件',
  'page.property.edit.modal.1014': 'ファイルサイズの上限：1件あたり 100MB, \n合計 300 MBまで',
  'page.property.edit.modal.1015': '削除',
  'page.property.edit.modal.1016': 'メモ',
  'page.property.edit.modal.1019': '画像：最大 10 件',
  'page.property.edit.dropdown.0001': 'ローカルファイルを選択',
  'page.property.edit.dropdown.0002': '書庫ファイルを選択',
  'page.property.edit.dropdown.0003': 'URL設定',
  'page.property.edit.dropdown.0004': '削除',
  'page.property.edit.dropdown.0005': '編集',
  'page.property.edit.hints.0001': '建物を識別する文字列を入力してください。',
  'page.property.edit.hints.0002': '半角数字で 〇〇〇-〇〇〇〇 の形式で入力してください。',
  'page.property.edit.hints.0003': '建物の所有者を入力してください。',
  'page.property.edit.hints.0004': '半角英数字で入力してください。',
  'page.property.edit.hints.0005': '半角数字で入力してください。',
};

const rscAffiliate = {
  'page.affiliate.title.USER': '連絡先一覧',
  'page.affiliate.title.COMPANY': '企業一覧',
  'page.affiliate.tab.0001': '連絡先',
  'page.affiliate.tab.0002': '企業',
  'page.affiliate.sort.0001': '並び替え',
  'page.affiliate.sort.0002': '登録日順',
  'page.affiliate.sort.0003': '氏名',
  'page.affiliate.sort.0004': '会社名',
  'page.affiliate.sort.0005': '登録者',
  'page.affiliate.sort.0006': '連絡先数',
  'page.affiliate.add.USER': '連絡先作成',
  'page.affiliate.add.COMPANY': '会社を作成',
  'page.affiliate.add.0001': '手入力で作成',
  'page.affiliate.add.0002': 'CSVでまとめて作成',
  'page.affiliate.search.placeholder.USER': '連絡先を検索',
  'page.affiliate.search.placeholder.COMPANY': '企業を検索',
  'page.affiliate.search.0001': '詳細検索',
  'page.affiliate.search.1001': '会社名',
  'page.affiliate.search.1002': '氏名',
  'page.affiliate.search.1003': '部署',
  'page.affiliate.search.1004': '役職',
  'page.affiliate.search.1005': '住所',
  'page.affiliate.search.1006': '業種',
  'page.affiliate.search.2001': 'クリア',
  'page.affiliate.search.2002': '検索',
  'page.affiliate.util.tag_setting.0001': 'タグを一括設定',
  'page.affiliate.util.tag_setting.0002': 'タグを一括解除',
  'page.affiliate.util.tag_setting.0003': 'タグの設定',
  'page.affiliate.util.tag_setting.1001': 'タグを選択してください',
  'page.affiliate.util.tag_setting.USER.1002':
    '選択した連絡先に設定されているすべてのタグを解除しますか？',
  'page.affiliate.util.tag_setting.COMPANY.1002':
    '選択した企業に設定されているすべてのタグを解除しますか？',
  'page.affiliate.util.tag_setting.2001': 'キャンセル',
  'page.affiliate.util.tag_setting.2002': '追加',
  'page.affiliate.util.tag_setting.2003': '解除',
  'page.affiliate.util.download.USER.0001': '選択した連絡先をダウンロード',
  'page.affiliate.util.download.COMPANY.0001': '選択した企業をダウンロード',
  'page.affiliate.util.download.0001': 'すべてダウンロード',
  'page.affiliate.util.delete.USER.0001': '選択した連絡先を削除する',
  'page.affiliate.util.delete.COMPANY.0001': '選択した企業を削除する',
  'page.affiliate.util.delete.USER.0002': '選択した連絡先を削除しますか？',
  'page.affiliate.util.delete.COMPANY.0002': '選択した企業を削除しますか？',
  'page.affiliate.util.delete.USER.0003': '削除後、以下項目の設定から解除されます。\n・予定調整先',
  'page.affiliate.util.delete.COMPANY.0003':
    '削除後、以下項目の設定から解除されます。\n・連絡先の会社',
  'page.affiliate.util.delete.1001': 'キャンセル',
  'page.affiliate.util.delete.1002': '削除',
  'page.affiliate.util.0009': 'タグの検索',
  'page.affiliate.util.0010': 'タグ選択中',
  'page.affiliate.user_list.0001': '会社名',
  'page.affiliate.user_list.0002': '氏名',
  'page.affiliate.user_list.0003': '部署名',
  'page.affiliate.user_list.0004': '役職',
  'page.affiliate.user_list.0005': '電話番号',
  'page.affiliate.user_list.0006': '携帯電話',
  'page.affiliate.user_list.0007': 'FAX',
  'page.affiliate.user_list.0008': 'メールアドレス',
  'page.affiliate.user_list.0009': 'URL',
  'page.affiliate.user_list.0010': '住所',
  'page.affiliate.user_list.0011': '過去の作業実施回数',
  'page.affiliate.user_list.0012': '今後の作業予定件数',
  'page.affiliate.user_list.0013': '今後の予定委調整先への登録件数',
  'page.affiliate.user_list.0014': '登録日',
  'page.affiliate.user_list.0015': '登録者',
  'page.affiliate.user_list.0016': '更新日',
  'page.affiliate.user_list.0017': '更新者',
  'page.affiliate.user_list.0018': 'TEL1',
  'page.affiliate.user_list.0019': 'TEL2',
  'page.affiliate.user_list.1001': '登録がありません',
  'page.affiliate.company_list.0001': '会社名',
  'page.affiliate.company_list.0002': '連絡先数',
  'page.affiliate.company_list.0003': '過去の\n作業件数',
  'page.affiliate.company_list.0004': '契約件数',
  'page.affiliate.company_list.0005': 'URL',
  'page.affiliate.company_list.0006': '住所',
  'page.affiliate.company_list.0007': '電話番号',
  'page.affiliate.company_list.0008': '代表者氏名',
  'page.affiliate.company_list.0009': '業種',
  'page.affiliate.company_list.0010': '更新者',
  'page.affiliate.company_list.0011': '更新日',
  'page.affiliate.company_list.1001': '登録がありません',
  'page.affiliate.file_upload.USER.0001': '連絡先を一括登録/更新する',
  'page.affiliate.file_upload.USER.0002': '連絡先を一括で登録/更新できます。',
  'page.affiliate.file_upload.USER.0003': '連絡先を一括で登録/更新しますか？',
  'page.affiliate.file_upload.COMPANY.0001': '企業を一括登録/更新する',
  'page.affiliate.file_upload.COMPANY.0002': '企業を一括で登録/更新できます。',
  'page.affiliate.file_upload.COMPANY.0003': '企業を一括で登録/更新しますか？',
  'page.affiliate.file_upload.COMMON.0001':
    'ご注意：\nタグなど一部の項目（一括登録用テンプレートで編集できない項目）は一括登録/更新できません。\n 取り込みが完了した後に設定をお願いいたします。',
  'page.affiliate.file_upload.COMMON.1001': 'キャンセル',
  'page.affiliate.file_upload.COMMON.1002': '登録',
  'page.affiliate.detail.0001': '編集',
  'page.affiliate.detail.0002': '削除',
  'page.affiliate.detail.delete.0001': '確認',
  'page.affiliate.detail.delete.0002': '連絡先を削除しますか？',
  'page.affiliate.detail.delete.0003': '企業を削除しますか？',
  'page.affiliate.detail.user.0001': '連絡先詳細',
  'page.affiliate.user.property.0001': '会社ID',
  'page.affiliate.user.property.0002': '会社名',
  'page.affiliate.user.property.0003': '部署名',
  'page.affiliate.user.property.0004': '役職',
  'page.affiliate.user.property.0005': '氏名',
  'page.affiliate.user.property.0006': '氏名カナ',
  'page.affiliate.user.property.0007': '姓',
  'page.affiliate.user.property.0008': '名',
  'page.affiliate.user.property.0009': 'セイ',
  'page.affiliate.user.property.0010': 'メイ',
  'page.affiliate.user.property.0011': '電話番号',
  'page.affiliate.user.property.0012': '携帯電話',
  'page.affiliate.user.property.0013': 'FAX',
  'page.affiliate.user.property.0014': 'メールアドレス',
  'page.affiliate.user.property.0015': 'URL',
  'page.affiliate.user.property.0016': '郵便番号',
  'page.affiliate.user.property.0017': '都道府県',
  'page.affiliate.user.property.0018': '市区町村',
  'page.affiliate.user.property.0019': '町域・番地',
  'page.affiliate.user.property.0020': 'ビル名',
  'page.affiliate.user.property.0021': 'タグ情報',
  'page.affiliate.user.property.0022': '備考',
  'page.affiliate.user.property.0023': 'TEL1',
  'page.affiliate.user.property.0024': 'TEL2',
  'page.affiliate.NEW.user.0001': '連絡先登録',
  'page.affiliate.NEW.user.0002': '登録',
  'page.affiliate.NEW.user.0003': 'キャンセル',
  'page.affiliate.EDIT.user.0001': '連絡先編集',
  'page.affiliate.EDIT.user.0002': '保存',
  'page.affiliate.EDIT.user.0003': '終了',
  'page.affiliate.detail.company.0001': '企業詳細',
  'page.affiliate.company.property.0001': '会社ID',
  'page.affiliate.company.property.0002': '会社名',
  'page.affiliate.company.property.0003': '連絡先数',
  'page.affiliate.company.property.0004': '過去の作業件数',
  'page.affiliate.company.property.0005': '契約件数',
  'page.affiliate.company.property.0006': 'URL',
  'page.affiliate.company.property.0007': '郵便番号',
  'page.affiliate.company.property.0008': '都道府県',
  'page.affiliate.company.property.0009': '市区町村',
  'page.affiliate.company.property.0010': '町域・番地',
  'page.affiliate.company.property.0011': 'ビル名',
  'page.affiliate.company.property.0012': '電話番号',
  'page.affiliate.company.property.0013': '代表者氏名',
  'page.affiliate.company.property.0014': '代表者氏名カナ',
  'page.affiliate.company.property.0015': '代表者の氏名(姓)',
  'page.affiliate.company.property.0016': '代表者の氏名(名)',
  'page.affiliate.company.property.0017': '代表者の氏名カナ(姓)',
  'page.affiliate.company.property.0018': '代表者の氏名カナ(名)',
  'page.affiliate.company.property.0019': '業種',
  'page.affiliate.company.property.0020': '更新日',
  'page.affiliate.company.property.0021': '更新者',
  'page.affiliate.company.property.0022': 'タグ情報',
  'page.affiliate.company.property.0023': '備考',
  'page.affiliate.NEW.company.0001': '企業登録',
  'page.affiliate.NEW.company.0002': '登録',
  'page.affiliate.NEW.company.0003': 'キャンセル',
  'page.affiliate.EDIT.company.0001': '企業編集',
  'page.affiliate.EDIT.company.0002': '保存',
  'page.affiliate.EDIT.company.0003': '終了',
  'page.affiliate.edit.0001': '会社を選択',
  'page.affiliate.edit.0002': '検索',
};

const rscTag = {
  'page.tag.0001': 'タグを検索',
  'page.tag.0002': '対象タグなし',
  'page.tagType.0001': '個人タグ',
  'page.tagType.0002': '共有タグ',
  'page.tagName.0001': '個人',
  'page.tagName.0002': '共有',
  'page.tagModal.common.0001': '確認',
  'page.tagOperationModal.0001': 'タグの設定',
  'page.tagOperationModal.0002': '新規タグの追加',
  'page.tagOperationModal.0003': '作成者',
  'page.tagOperationModal.0004': '登録件数',
  'page.tagOperationModal.0005': '追加',
  'page.tagOperationModal.0006': 'クリア',
  'page.tagOperationModal.0007': '編集',
  'page.tagOperationModal.0008': '削除',
  'page.selectModal.0001': 'タグ選択',
  'page.selectModal.0002': '選択',
  'page.addNewTagModal.0001': 'タグ名',
  'page.addNewTagModal.0002': 'タイプ',
  'page.addNewTagModal.0003': 'を追加しますか？',
  'page.addNewTagModal.warning.0004': '注意：タグ追加後のタイプの変更はできません。',
  'page.addNewTagModal.0005': 'いいえ',
  'page.addNewTagModal.0006': 'はい',
  'page.editTagModal.0001': 'タグの編集',
  'page.editTagModal.0002': 'キャンセル',
  'page.editTagModal.0003': '保存',
  'page.deleteTagModal.0001': 'を削除しますか？',
  'page.deleteTagModal.warning.0001': '注意：削除後は元に戻せません。',
};

const rscAdminLicense = {
  'page.userLicense.0001': 'ライセンス管理',
  'page.userLicense.0002': 'ライセンス利用状況',
  'page.userLicense.0003': 'ライセンス数',
  'page.userLicense.0004': '利用ユーザー数',
  'page.userLicense.1001': '契約しているライセンスの数を表示しています。',
  'page.userLicense.1002':
    'システムに登録しているユーザー数を表示しています。\n但し、削除されたユーザーは除きます。',
};

const rscPjHeader = {
  'projects.header.0001': 'プロフィール',
  'projects.header.0002': '通知設定',
  'projects.header.0003': 'マニュアル',
  'projects.header.0004': 'ライセンス',
  'projects.header.0005': 'ログアウト',
};

const rscNotifications = {
  'page.notification.0001': '通知設定',
  'page.notification.header.0001': '分類',
  'page.notification.header.0002': '通知項目',
  'page.notification.header.0003': '通知条件',
  'page.notification.switchButton.0001': 'オン',
  'page.notification.switchButton.0002': 'オフ',
  'page.notification.0002': '該当のデータがありません',
  // 建物
  'page.notification.property': '建物の通知',
  'page.notification.property.0001': '建物詳細の更新時に通知',
  'page.notification.property.0001.condition': '建物の担当者であること',
  'page.notification.property.0002': '契約詳細の更新時に通知',
  'page.notification.property.0002.condition': '建物の担当者であり かつ 管理者権限であること',
  'page.notification.property.0003': '連絡事項の更新時に通知',
  'page.notification.property.0003.condition': '建物の担当者であること',
  'page.notification.property.0004': '担当者の更新時に通知',
  'page.notification.property.0004.condition': '建物の担当者であること',
  // 定期業務
  'page.notification.inspection': '定期業務の通知',
  'page.notification.inspection.0001': '対応状況が「査収待ち」になると通知',
  'page.notification.inspection.0001.condition': '建物の担当者であり かつ 管理者権限であること',
  'page.notification.inspection.0002': '対応状況が「差し戻し」になると通知',
  'page.notification.inspection.0002.condition':
    '建物の担当者であり かつ 管理者権限であること （※申請者である場合、必ず通知されます）',
  'page.notification.inspection.0003': '対応状況が「査収完了」になると通知',
  'page.notification.inspection.0003.condition':
    '建物の担当者であり かつ 管理者権限であること （※申請者である場合、必ず通知されます）',
  'page.notification.inspection.0004': 'コメントが登録されると通知',
  'page.notification.inspection.0004.condition':
    '建物の担当者であり かつ 管理者権限であること （※対象の定期業務の作業者である場合、必ず通知されます）',
  // 工事
  'page.notification.construction': '工事の通知',
  'page.notification.construction.0001': '対応状況が「査収待ち」になると通知',
  'page.notification.construction.0001.condition': '建物の担当者であり かつ 管理者権限であること',
  'page.notification.construction.0002': '対応状況が「差し戻し」になると通知',
  'page.notification.construction.0002.condition':
    '建物の担当者であり かつ 管理者権限であること （※申請者である場合、必ず通知されます）',
  'page.notification.construction.0003': '対応状況が「査収完了」になると通知',
  'page.notification.construction.0003.condition':
    '建物の担当者であり かつ 管理者権限であること （※申請者である場合、必ず通知されます）',
  'page.notification.construction.0004': 'コメントが登録されると通知',
  'page.notification.construction.0004.condition':
    '建物の担当者であり かつ 管理者権限であること （※対象の工事の作業者である場合、必ず通知されます）',
  // 案件
  'page.notification.work': '案件の設定',
  'page.notification.work.0001': '新規登録されると通知',
  'page.notification.work.0001.condition': '建物の担当者であること',
  'page.notification.work.0002': '対応状況が「対応中」になると通知',
  'page.notification.work.0002.condition': '建物の担当者であること',
  'page.notification.work.0003': '対応状況が「査収待ち」になると通知',
  'page.notification.work.0003.condition': '建物の担当者であり かつ 管理者権限であること',
  'page.notification.work.0004': '対応状況が「差し戻し」になると通知',
  'page.notification.work.0004.condition':
    '建物の担当者であり かつ 管理者権限であること （※申請者である場合、必ず通知されます）',
  'page.notification.work.0005': '対応状況が「査収完了」になると通知',
  'page.notification.work.0005.condition':
    '建物の担当者であり かつ 管理者権限であること （※申請者である場合、必ず通知されます）',
  'page.notification.work.0006': '対応状況が「保留」になると通知',
  'page.notification.work.0006.condition': '建物の担当者であること',
  'page.notification.work.0007': '対応履歴が登録されると通知',
  'page.notification.work.0007.condition': '建物の担当者であること',
  'page.notification.work.0008': 'コメントが登録されると通知',
  'page.notification.work.0008.condition': '建物の担当者であること',
};

const rscPjModal = {
  'project.modal.0001': '確認',
};

const rscPjDeleteModal = {
  'project.deleteModal.0001': '確認',
  'project.deleteModal.1001': 'を削除しますか？',
  'project.deleteModal.1002': '注意：削除後は元に戻せません。',
  'project.deleteModal.2001': '確認：削除するには「',
  'project.deleteModal.2002': '」と入力してください。',
  'project.deleteModal.2003': 'を入力',
  'project.deleteModal.3001': 'いいえ',
  'project.deleteModal.3002': 'はい',
};

const rscPjSelectArchiveModal = {
  'project.selectArchiveModal.001': '書庫ファイル選択ダイアログ',
  'project.selectArchiveModal.002': '書庫フォルダ選択ダイアログ',
  'project.selectArchiveModal.003': 'ドキュメント',
  'project.selectArchiveModal.004': '名前',
  'project.selectArchiveModal.005': 'サイズ',
  'project.selectArchiveModal.006': 'キャンセル',
  'project.selectArchiveModal.007': '選択',
};

const rscPjSelectAffiliateModal = {
  'project.selectAffiliateModal.0001': '連絡先',
  'project.selectAffiliateModal.0002': '企業',
  'project.selectAffiliateModal.0003': '建物担当者',
  'project.selectAffiliateModal.0004': '氏名',
  'project.selectAffiliateModal.0005': '所属',
  'project.selectAffiliateModal.0006': '会社名',
  'project.selectAffiliateModal.0007': '住所',
  'project.selectAffiliateModal.0008': '・',
  'project.selectAffiliateModal.1001': '氏名を検索',
  'project.selectAffiliateModal.1002': '会社名を検索',
  'project.selectAffiliateModal.1003': '建物担当者名を検索',
  'project.selectAffiliateModal.1004': '検索',
  'project.selectAffiliateModal.2001': 'キャンセル',
  'project.selectAffiliateModal.2002': '選択',
  'project.selectAffiliateModal.2003': '新規登録',
};

const rscPjSelectMultipleAffiliateModal = {
  'project.selectMultipleAffiliateModal.0001': '連絡先',
  'project.selectMultipleAffiliateModal.0002': '企業',
  'project.selectMultipleAffiliateModal.0003': 'システムユーザー',
  'project.selectMultipleAffiliateModal.0004': '氏名',
  'project.selectMultipleAffiliateModal.0005': '所属',
  'project.selectMultipleAffiliateModal.0006': 'メールアドレス',
  'project.selectMultipleAffiliateModal.0007': '備考',
  'project.selectMultipleAffiliateModal.0008': '会社名',
  'project.selectMultipleAffiliateModal.0009': '住所',
  'project.selectMultipleAffiliateModal.0010': '・',
  'project.selectMultipleAffiliateModal.1001': '氏名を検索',
  'project.selectMultipleAffiliateModal.1002': '会社名を検索',
  'project.selectMultipleAffiliateModal.1003': 'システムユーザーを検索',
  'project.selectMultipleAffiliateModal.1004': '検索',
  'project.selectMultipleAffiliateModal.2001': 'キャンセル',
  'project.selectMultipleAffiliateModal.2002': '設定',
  'project.selectMultipleAffiliateModal.3001': 'を選択してください。',
  'project.selectMultipleAffiliateModal.3002': '・5件まで選択できます',
};

const rscUiBirthInput = {
  'ui.birthInput.0001': '年',
  'ui.birthInput.0002': '月',
  'ui.birthInput.0003': '日',
};

const rscEditCommentModal = {
  'ui.edit.comment.modal.2001': '[選択可能ファイル]',
  'ui.edit.comment.modal.2002': '画像または動画：最大 10 件',
  'ui.edit.comment.modal.2003': '画像のファイルサイズ上限：100MB',
  'ui.edit.comment.modal.2004': '動画のファイルサイズ上限：300MB',
  'ui.edit.comment.modal.2005': 'ご注意：添付写真と添付ファイルの合計サイズ上限は300MBです。',
  'ui.edit.comment.modal.9001': '全体の添付サイズ合計が300MBを超えるため、添付できません。',
};

const rscUiSearchForm = {
  'ui.searchForm.0001': '検索する',
  'ui.searchForm.0002': '詳細検索',
};

const rscUiSortControl = {
  'ui.sortControl.0001': '並び替え',
};

const rscUiFileUploadArea = {
  'ui.fileUploadArea.0001': 'クリックしてファイルを選択\nまたはここにファイルをドロップ',
};

const rscUiFilterControl = {
  'ui.filterControl.0001': '絞り込み',
};

const rscUiPager = {
  'ui.pager.0001': '前のページ',
  'ui.pager.0002': '次のページ',
  'ui.pager.0003': '件の登録',
};

const rscUiWeekdayPicker = {
  'ui.weekdayPicker.0001': '月',
  'ui.weekdayPicker.0002': '火',
  'ui.weekdayPicker.0003': '水',
  'ui.weekdayPicker.0004': '木',
  'ui.weekdayPicker.0005': '金',
  'ui.weekdayPicker.0006': '土',
  'ui.weekdayPicker.0007': '日',
};

const rscUiCopyButton = {
  'ui.copyButton.0001': 'クリップボードにコピー',
  'ui.copyButton.0002': 'コピーしました',
  'ui.copyButton.0003': 'URLをコピーしますか？',
  'ui.copyButton.0004': 'コピー',
  'ui.copyButton.0005': '閉じる',
};

/**
 * @summary 画像
 */
const rscUiNoImage = {
  'ui.imagesOrVideosAreDisplayedHere': '画像や動画がここに表示されます。',
  'ui.imagesAreDisplayedHere': '画像がここに表示されます。',
  'ui.noMediaData': 'メディアがありません',
};

/**
 * @summary  定期業務種別(Type)のリストの表示値
 */
const rscCommonFixedTermTaskTypes = {
  'common.fixedTermTaskType.0001': '定期',
  'common.fixedTermTaskType.0002': '法定',
  'common.fixedTermTaskType.0003': '日常',
  'common.fixedTermTaskType.0004': 'その他',
};

/**
 * @summary  定期業務区分(Division)のリストの表示値
 */
const rscCommonFixedTermTaskDivisions = {
  'common.fixedTermTaskDivision.0001': '設備',
  'common.fixedTermTaskDivision.0002': '清掃',
  'common.fixedTermTaskDivision.0003': '警備',
  'common.fixedTermTaskDivision.0004': '検針',
  'common.fixedTermTaskDivision.0005': '建築',
  'common.fixedTermTaskDivision.0006': 'その他',
};

/**
 * @summary  対応状況(Status)のリストの表示値
 */
const rscCommonFixedTermTaskStatuses = {
  'common.fixedTermTaskStatus.0001': '未確定',
  'common.fixedTermTaskStatus.0002': '確定',
  'common.fixedTermTaskStatus.0003': '対応中',
  'common.fixedTermTaskStatus.0004': '査収待ち',
  'common.fixedTermTaskStatus.0005': '査収完了',
  'common.fixedTermTaskStatus.0006': '保留',
};

/**
 * @summary  時刻リストの表示値
 */
const rscCommonTime = {
  'common.time.0000': '00:00',
  'common.time.0001': '01:00',
  'common.time.0002': '02:00',
  'common.time.0003': '03:00',
  'common.time.0004': '04:00',
  'common.time.0005': '05:00',
  'common.time.0006': '06:00',
  'common.time.0007': '07:00',
  'common.time.0008': '08:00',
  'common.time.0009': '09:00',
  'common.time.0010': '10:00',
  'common.time.0011': '11:00',
  'common.time.0012': '12:00',
  'common.time.0013': '13:00',
  'common.time.0014': '14:00',
  'common.time.0015': '15:00',
  'common.time.0016': '16:00',
  'common.time.0017': '17:00',
  'common.time.0018': '18:00',
  'common.time.0019': '19:00',
  'common.time.0020': '20:00',
  'common.time.0021': '21:00',
  'common.time.0022': '22:00',
  'common.time.0023': '23:00',
};

/**
 * @summary 案件分類
 */
const rscWorkTypeOptions = {
  'common.workTypeOptions.0001': '不具合',
  'common.workTypeOptions.0002': '問い合わせ',
  'common.workTypeOptions.0003': 'その他',
  'common.workTypeOptions.0004': '工事',
};

/**
 * @summary 優先度
 */
const rscWorkPriorityOptions = {
  'common.workPriorityOption.0001': '高',
  'common.workPriorityOption.0002': '中',
  'common.workPriorityOption.0003': '低',
};

/**
 * @summary 対応状況
 */
const rscWorkStatusOptions = {
  'common.workStatusOptions0001': '未実施',
  'common.workStatusOptions0002': '対応中',
  'common.workStatusOptions0003': '査収待ち',
  'common.workStatusOptions0004': '査収完了',
  'common.workStatusOptions0005': '保留',
};

/**
 * @summary 自動メール送信状況
 */
const rscSendStatus = {
  'common.SendStatus.1': '未送信',
  'common.SendStatus.2': '設定済み',
  'common.SendStatus.3': '送信済み',
  'common.SendStatus.noSet': '未設定',
};

/**
 * @summary CSV一括登録共通文言
 */
const rscCsvUpload = {
  'common.CsvUpload.0001': '1.一括登録用テンプレートを以下よりダウンロードしてください。',
  'common.CsvUpload.0002': '一括登録用テンプレートをダウンロード',
  'common.CsvUpload.0003':
    '2.ダウンロードした一括登録用テンプレートに必要な情報を記入してください。',
  'common.CsvUpload.0004': '登録されるのは4行目以降に記載されている内容になります。',
  'common.CsvUpload.0005': '3.一括登録用テンプレートをアップロードしてください。',
  'common.CsvUpload.0006': '選択したファイル',
  'common.CsvUpload.0007':
    'クリックしてファイルを選択（CSVファイルのみ）\nまたはここにファイルをドロップ',
};

const Resource = {
  ...rscInfo,

  // ---- pages
  ...rscCommon,
  ...rscHome,
  ...rscLogin,
  ...rscLicense,
  ...rscNotifications,
  ...rscEquipment,
  ...rscDiagram,
  ...rscFloor,
  ...rcsChecking,
  ...rscInspection,
  ...rscInspectionChecklist,
  ...rscWork,
  ...rscConstruction,
  ...rscPlanCommon,
  ...rscPlanAnnual,
  ...rscPlanMonthly,
  ...rscPlanDaily,
  ...rscPlan,
  ...rscArchive,
  ...rscUser,
  ...rscGroup,
  ...rscProperty,
  ...rscAffiliate,
  ...rscAdminLicense,
  ...rscTag,

  // ---- projects
  ...rscPjHeader,
  ...rscPjModal,
  ...rscPjDeleteModal,
  ...rscPjSelectArchiveModal,
  ...rscPjSelectAffiliateModal,
  ...rscPjSelectMultipleAffiliateModal,

  // ---- uiParts
  ...rscUiBirthInput,
  ...rscUiSearchForm,
  ...rscUiSortControl,
  ...rscUiFileUploadArea,
  ...rscUiFilterControl,
  ...rscUiPager,
  ...rscUiWeekdayPicker,
  ...rscUiCopyButton,
  ...rscEditCommentModal,
  ...rscUiNoImage,

  // ---- common
  ...rscCommonFixedTermTaskTypes,
  ...rscCommonFixedTermTaskDivisions,
  ...rscCommonFixedTermTaskStatuses,
  ...rscCommonTime,
  ...rscWorkTypeOptions,
  ...rscWorkPriorityOptions,
  ...rscWorkStatusOptions,
  ...rscSendStatus,
  ...rscCsvUpload,
};

export default Resource;
